/* eslint-disable */
// this is an auto generated file. This will be overwritten
export const listBuildingMikrotikClientsStormCustom = /* GraphQL */ `
  query listBuildingMikrotikClientsStormCustom(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildingMikrotikClientsStorm(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Phone
        Name
        MikrotikClients {
          DstAddress
          RemoteAddress
          Username
          Password
        }
        Address {
          Street1
          Province
          PostalCode
          Country
          City
        }
      }
    }
  }
`;
export const getBuildingsBFGCustom = /* GraphQL */ `
  query GetBuilding($id: ID!) {
    getBuildings(id: $id) {
      Name
      Phone
      RefreshRateInSec
      IsFetchingWeather
      Contractors {
        Name
        Email
      }
      Devices {
        items {
          id
          GroupID
          Name
          PhysicalAddress
          StreamIpAddress
          StreamPort
          ThemeFolderName
          DoorNumber
          RingGroup {
            Name
            Time
            Callees {
              items {
                LinkEmail
                LinkNumber
                Number
                Priority
                calleesSIPAccountId
                SIPAccount {
                  Name
                }
              }
            }
          }
          SIPAccount {
            items {
              Name
              PbxExtension
              PbxPassword
              PbxUsername
              Port
              PrimaryAddress
              SIPOwner
              SecondaryAddress
              Type
              id
            }
          }
          Models {
            DeviceType
          }
        }
      }
      Owners {
        Email
        BusinessName
        PhoneNumber
      }
    }
  }
`;
export const getSIPAccountStormByDeviceIDCustom = /* GraphQL */ `
  query GetSIPAccountStormByDeviceID($device_id: ID!) {
    getSIPAccountStormByDeviceID(device_id: $device_id) {
      statusCode
      body
    }
  }
`;
export const listContractorFirstRespondersByContractorIDCustom = /* GraphQL */ `
  query ListContractorFirstRespondersByContractorID(
    $filter: ModelContractorFirstResponderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractorFirstRespondersByContractorID(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      statusCode
      body
    }
  }
`;
export const listModelsName = /* GraphQL */ `
  query ListModels {
    listModels {
      items {
        id
        Name
        Description
        Resolution
      }
    }
  }
`;
export const listDevicesPageByBuildingsID = `
query DevicesByBuildingsID($buildingsID: ID!) {
  devicesByBuildingsID(buildingsID: $buildingsID) {
    items {
      id
      Name
      PhysicalAddress
      StreamIpAddress
      StreamPort
      GroupID
      modelsID
      ThemeFolderName
      DoorNumber
      devicesRingGroupId
      Models {
        Name
        Description
        IsSipPhone
        IsStreaming
        Resolution
      }
      DeviceInfoRuntime {
        ErrorsCode
        PowerCycles
        Status
        FirmwareVersion
        IpAddress
        MacAddress
      }
    }
  }
}
`;
export const listPageBuildingsByUser = `
query ListBuildingsByUser {
listBuildingsByUser {
items {
id
Name
Phone
RefreshRateInSec
IsFetchingWeather
Address {
Street1
City
Country
PostalCode
Province
}
}
}
}
`;
export const listContractorsStormCustom = /* GraphQL */ `
  query ListContractorsStorm {
    listContractorsStorm {
      items {
        id
        Name
        Email
      }
    }
  }
`;
export const listOwnersStormCustom = /* GraphQL */ `
  query ListOwnersStorm {
    listOwnersStorm {
      items {
        id
        Email
        BusinessName
      }
    }
  }
`;
export const pingSupervisor = /* GraphQL */ `
  query PingSupervisor($input: [DeviceInput!]!) {
    pingSupervisor(input: $input) {
      ForceUpdateBFG
      __typename
    }
  }
`;
export const listContractorsStorm = /* GraphQL */ `
  query ListContractorsStorm(
    $filter: ModelContractorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractorsStorm(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const listOwnersStorm = /* GraphQL */ `
  query ListOwnersStorm(
    $filter: ModelOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOwnersStorm(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeleteRessourcesStorm = /* GraphQL */ `
  query GetDeleteRessourcesStorm(
    $input: S3ManagementRessourcesInput!
    $condition: S3ManagementRessourcesConditionInput
  ) {
    getDeleteRessourcesStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const listBuildingMikrotikClientsStorm = /* GraphQL */ `
  query ListBuildingMikrotikClientsStorm(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildingMikrotikClientsStorm(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Phone
        SubAccountId
        RefreshRateInSec
        IsFetchingWeather
        ownersID
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BFGVersion {
          id
          Name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StripeManagement {
          id
          SubscriptionProductsQuantity
          UsedProductsQuantity
          TotalPricePerMonth
          PaymentStatus
          SubscriptionEnd
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        MikrotikClients {
          id
          DstAddress
          RemoteAddress
          Username
          Password
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        contractorsID
        Contractors {
          id
          Name
          Email
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          ContractorFirstResponder {
            items {
              id
              Number
              Priority
              contractorsID
              Name
              Availability
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contractorsAddressId
          __typename
        }
        Owners {
          id
          BusinessName
          Email
          BillingEmail
          PhoneNumber
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          ownersAddressId
          __typename
        }
        PingBuilding {
          id
          IpAddress
          Status
          MacAddress
          FirmwareVersion
          ErrorsCode
          PowerCycles
          ForceUpdateBFG
          ForceUpdateFirmware
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        buildingsAddressId
        buildingsBFGVersionId
        buildingsStripeManagementId
        buildingsMikrotikClientsId
        buildingsPingBuildingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getEmergencyInfoStorm = /* GraphQL */ `
  query GetEmergencyInfoStorm($streamName: String!) {
    getEmergencyInfoStorm(streamName: $streamName) {
      statusCode
      body
      __typename
    }
  }
`;
export const getPeerUsernameVoicemeup = /* GraphQL */ `
  query GetPeerUsernameVoicemeup($StreamName: String!) {
    getPeerUsernameVoicemeup(StreamName: $StreamName) {
      statusCode
      peer_username
      __typename
    }
  }
`;
export const listRessourcesEmbeddedStorm = /* GraphQL */ `
  query ListRessourcesEmbeddedStorm(
    $input: ListRessourcesEmbeddedStormInput!
    $condition: ListRessourcesEmbeddedStormConditionInput
  ) {
    listRessourcesEmbeddedStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const downloadRessourceEmbeddedStorm = /* GraphQL */ `
  query DownloadRessourceEmbeddedStorm(
    $input: DownloadRessourceStormInput!
    $condition: DownloadRessourceStormConditionInput
  ) {
    downloadRessourceEmbeddedStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const listAndDownloadRessourceEmbeddedStorm = /* GraphQL */ `
  query ListAndDownloadRessourceEmbeddedStorm(
    $input: ListRessourcesEmbeddedStormInput!
    $condition: ListRessourcesEmbeddedStormConditionInput
  ) {
    listAndDownloadRessourceEmbeddedStorm(
      input: $input
      condition: $condition
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const downloadRessourceStorm = /* GraphQL */ `
  query DownloadRessourceStorm(
    $input: DownloadRessourceStormInput!
    $condition: DownloadRessourceStormConditionInput
  ) {
    downloadRessourceStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const listImagesAndPathsStorm = /* GraphQL */ `
  query ListImagesAndPathsStorm($buildingID: ID!) {
    listImagesAndPathsStorm(buildingID: $buildingID) {
      statusCode
      body
      __typename
    }
  }
`;
export const listVisualsStorm = /* GraphQL */ `
  query ListVisualsStorm($buildingID: ID!) {
    listVisualsStorm(buildingID: $buildingID) {
      statusCode
      body
      __typename
    }
  }
`;
export const listVisualsHistoricalStorm = /* GraphQL */ `
  query ListVisualsHistoricalStorm(
    $input: S3ManagementFolderInput!
    $condition: S3ManagementFolderConditionInput
  ) {
    listVisualsHistoricalStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const getVisualStorm = /* GraphQL */ `
  query GetVisualStorm(
    $input: S3ManagementFolderInput!
    $condition: S3ManagementFolderConditionInput
  ) {
    getVisualStorm(input: $input, condition: $condition) {
      statusCode
      body
      __typename
    }
  }
`;
export const getHLSStreamSessionURLStorm = /* GraphQL */ `
  query GetHLSStreamSessionURLStorm($StreamName: String!) {
    getHLSStreamSessionURLStorm(StreamName: $StreamName) {
      statusCode
      body
      __typename
    }
  }
`;
export const getSIPAccountStormByDeviceID = /* GraphQL */ `
  query GetSIPAccountStormByDeviceID($device_id: ID!) {
    getSIPAccountStormByDeviceID(device_id: $device_id) {
      statusCode
      body
      __typename
    }
  }
`;
export const listContractorFirstRespondersByContractorID = /* GraphQL */ `
  query ListContractorFirstRespondersByContractorID(
    $filter: ModelContractorFirstResponderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractorFirstRespondersByContractorID(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      statusCode
      body
      __typename
    }
  }
`;
export const listBuildingsByUser = /* GraphQL */ `
  query ListBuildingsByUser(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildingsByUser(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Phone
        SubAccountId
        RefreshRateInSec
        IsFetchingWeather
        ownersID
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BFGVersion {
          id
          Name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StripeManagement {
          id
          SubscriptionProductsQuantity
          UsedProductsQuantity
          TotalPricePerMonth
          PaymentStatus
          SubscriptionEnd
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        MikrotikClients {
          id
          DstAddress
          RemoteAddress
          Username
          Password
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        contractorsID
        Contractors {
          id
          Name
          Email
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          ContractorFirstResponder {
            items {
              id
              Number
              Priority
              contractorsID
              Name
              Availability
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contractorsAddressId
          __typename
        }
        Owners {
          id
          BusinessName
          Email
          BillingEmail
          PhoneNumber
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          ownersAddressId
          __typename
        }
        PingBuilding {
          id
          IpAddress
          Status
          MacAddress
          FirmwareVersion
          ErrorsCode
          PowerCycles
          ForceUpdateBFG
          ForceUpdateFirmware
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        buildingsAddressId
        buildingsBFGVersionId
        buildingsStripeManagementId
        buildingsMikrotikClientsId
        buildingsPingBuildingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getUsersCognitoWithCustomAtt = /* GraphQL */ `
  query GetUsersCognitoWithCustomAtt {
    getUsersCognitoWithCustomAtt {
      statusCode
      body
      __typename
    }
  }
`;
export const exportBIDFile = /* GraphQL */ `
  query ExportBIDFile($id: ID!) {
    exportBIDFile(id: $id) {
      statusCode
      body
      __typename
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      Name
      Prefix
      CustomerProjects {
        items {
          id
          Name
          ChannelID
          SaleOrderID
          CustomerID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Prefix
        CustomerProjects {
          items {
            id
            Name
            ChannelID
            SaleOrderID
            CustomerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomers = /* GraphQL */ `
  query SyncCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Prefix
        CustomerProjects {
          items {
            id
            Name
            ChannelID
            SaleOrderID
            CustomerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerByName = /* GraphQL */ `
  query GetCustomerByName(
    $Name: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerByName(
      Name: $Name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Prefix
        CustomerProjects {
          items {
            id
            Name
            ChannelID
            SaleOrderID
            CustomerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerByPrefix = /* GraphQL */ `
  query GetCustomerByPrefix(
    $Prefix: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerByPrefix(
      Prefix: $Prefix
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Prefix
        CustomerProjects {
          items {
            id
            Name
            ChannelID
            SaleOrderID
            CustomerID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerProjects = /* GraphQL */ `
  query GetCustomerProjects($id: ID!) {
    getCustomerProjects(id: $id) {
      id
      Name
      ChannelID
      SaleOrderID
      CustomerID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listCustomerProjects = /* GraphQL */ `
  query ListCustomerProjects(
    $filter: ModelCustomerProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        ChannelID
        SaleOrderID
        CustomerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCustomerProjects = /* GraphQL */ `
  query SyncCustomerProjects(
    $filter: ModelCustomerProjectsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCustomerProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        ChannelID
        SaleOrderID
        CustomerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCustomerBySaleOrderID = /* GraphQL */ `
  query GetCustomerBySaleOrderID(
    $SaleOrderID: String!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getCustomerBySaleOrderID(
      SaleOrderID: $SaleOrderID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        ChannelID
        SaleOrderID
        CustomerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const customerProjectsByCustomerID = /* GraphQL */ `
  query CustomerProjectsByCustomerID(
    $CustomerID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCustomerProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    customerProjectsByCustomerID(
      CustomerID: $CustomerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        ChannelID
        SaleOrderID
        CustomerID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getGstreamer = /* GraphQL */ `
  query GetGstreamer($TaskID: ID!) {
    getGstreamer(TaskID: $TaskID) {
      TaskID
      InUse
      Status
      StreamName
      Device {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          Reboots
          LastRebootReason
          LastPrivateRegister
          LastPublicRegister
          LastPrivateRegisterReason
          LastPublicRegisterReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                Reboots
                LastRebootReason
                LastPrivateRegister
                LastPublicRegister
                LastPrivateRegisterReason
                LastPublicRegisterReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      Start
      End
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      gstreamerDeviceId
      __typename
    }
  }
`;
export const listGstreamers = /* GraphQL */ `
  query ListGstreamers(
    $TaskID: ID
    $filter: ModelGstreamerFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGstreamers(
      TaskID: $TaskID
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        TaskID
        InUse
        Status
        StreamName
        Device {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            Reboots
            LastRebootReason
            LastPrivateRegister
            LastPublicRegister
            LastPrivateRegisterReason
            LastPublicRegisterReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        Start
        End
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        gstreamerDeviceId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncGstreamers = /* GraphQL */ `
  query SyncGstreamers(
    $filter: ModelGstreamerFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncGstreamers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        TaskID
        InUse
        Status
        StreamName
        Device {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            Reboots
            LastRebootReason
            LastPrivateRegister
            LastPublicRegister
            LastPrivateRegisterReason
            LastPublicRegisterReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        Start
        End
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        gstreamerDeviceId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getMikrotikClients = /* GraphQL */ `
  query GetMikrotikClients($id: ID!) {
    getMikrotikClients(id: $id) {
      id
      DstAddress
      RemoteAddress
      Username
      Password
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listMikrotikClients = /* GraphQL */ `
  query ListMikrotikClients(
    $filter: ModelMikrotikClientsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMikrotikClients(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        DstAddress
        RemoteAddress
        Username
        Password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncMikrotikClients = /* GraphQL */ `
  query SyncMikrotikClients(
    $filter: ModelMikrotikClientsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncMikrotikClients(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        DstAddress
        RemoteAddress
        Username
        Password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getStripeManagement = /* GraphQL */ `
  query GetStripeManagement($id: ID!) {
    getStripeManagement(id: $id) {
      id
      SubscriptionProductsQuantity
      UsedProductsQuantity
      TotalPricePerMonth
      PaymentStatus
      SubscriptionEnd
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listStripeManagements = /* GraphQL */ `
  query ListStripeManagements(
    $filter: ModelStripeManagementFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStripeManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        SubscriptionProductsQuantity
        UsedProductsQuantity
        TotalPricePerMonth
        PaymentStatus
        SubscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncStripeManagements = /* GraphQL */ `
  query SyncStripeManagements(
    $filter: ModelStripeManagementFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncStripeManagements(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        SubscriptionProductsQuantity
        UsedProductsQuantity
        TotalPricePerMonth
        PaymentStatus
        SubscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBFGVersion = /* GraphQL */ `
  query GetBFGVersion($id: ID!) {
    getBFGVersion(id: $id) {
      id
      Name
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listBFGVersions = /* GraphQL */ `
  query ListBFGVersions(
    $filter: ModelBFGVersionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBFGVersions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBFGVersions = /* GraphQL */ `
  query SyncBFGVersions(
    $filter: ModelBFGVersionFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBFGVersions(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getPingBuilding = /* GraphQL */ `
  query GetPingBuilding($id: ID!) {
    getPingBuilding(id: $id) {
      id
      IpAddress
      Status
      MacAddress
      FirmwareVersion
      ErrorsCode
      PowerCycles
      ForceUpdateBFG
      ForceUpdateFirmware
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listPingBuildings = /* GraphQL */ `
  query ListPingBuildings(
    $filter: ModelPingBuildingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPingBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        IpAddress
        Status
        MacAddress
        FirmwareVersion
        ErrorsCode
        PowerCycles
        ForceUpdateBFG
        ForceUpdateFirmware
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncPingBuildings = /* GraphQL */ `
  query SyncPingBuildings(
    $filter: ModelPingBuildingFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncPingBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        IpAddress
        Status
        MacAddress
        FirmwareVersion
        ErrorsCode
        PowerCycles
        ForceUpdateBFG
        ForceUpdateFirmware
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getCallees = /* GraphQL */ `
  query GetCallees($id: ID!) {
    getCallees(id: $id) {
      id
      Number
      LinkNumber
      LinkEmail
      Priority
      SIPAccount {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      RingGroupID
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      calleesSIPAccountId
      __typename
    }
  }
`;
export const listCallees = /* GraphQL */ `
  query ListCallees(
    $filter: ModelCalleesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCallees(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Number
        LinkNumber
        LinkEmail
        Priority
        SIPAccount {
          id
          Name
          Type
          SIPOwner
          PrimaryAddress
          SecondaryAddress
          PbxUsername
          PbxPassword
          PbxExtension
          ClientPeerId
          StreamName
          PhoneNumber
          DeviceID
          Port
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        RingGroupID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calleesSIPAccountId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncCallees = /* GraphQL */ `
  query SyncCallees(
    $filter: ModelCalleesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncCallees(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Number
        LinkNumber
        LinkEmail
        Priority
        SIPAccount {
          id
          Name
          Type
          SIPOwner
          PrimaryAddress
          SecondaryAddress
          PbxUsername
          PbxPassword
          PbxExtension
          ClientPeerId
          StreamName
          PhoneNumber
          DeviceID
          Port
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        RingGroupID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calleesSIPAccountId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const calleesByRingGroupID = /* GraphQL */ `
  query CalleesByRingGroupID(
    $RingGroupID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCalleesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    calleesByRingGroupID(
      RingGroupID: $RingGroupID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Number
        LinkNumber
        LinkEmail
        Priority
        SIPAccount {
          id
          Name
          Type
          SIPOwner
          PrimaryAddress
          SecondaryAddress
          PbxUsername
          PbxPassword
          PbxExtension
          ClientPeerId
          StreamName
          PhoneNumber
          DeviceID
          Port
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        RingGroupID
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        calleesSIPAccountId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getRingGroup = /* GraphQL */ `
  query GetRingGroup($id: ID!) {
    getRingGroup(id: $id) {
      id
      Name
      Extension
      Callees {
        items {
          id
          Number
          LinkNumber
          LinkEmail
          Priority
          SIPAccount {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          RingGroupID
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          calleesSIPAccountId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Time
      CountryCode
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listRingGroups = /* GraphQL */ `
  query ListRingGroups(
    $filter: ModelRingGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRingGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Extension
        Callees {
          items {
            id
            Number
            LinkNumber
            LinkEmail
            Priority
            SIPAccount {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            RingGroupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calleesSIPAccountId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Time
        CountryCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncRingGroups = /* GraphQL */ `
  query SyncRingGroups(
    $filter: ModelRingGroupFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncRingGroups(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Extension
        Callees {
          items {
            id
            Number
            LinkNumber
            LinkEmail
            Priority
            SIPAccount {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            RingGroupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calleesSIPAccountId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Time
        CountryCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getAddress = /* GraphQL */ `
  query GetAddress($id: ID!) {
    getAddress(id: $id) {
      id
      Street1
      Street2
      City
      Country
      Province
      PostalCode
      Phone
      Fax
      ContactName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listAddresses = /* GraphQL */ `
  query ListAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAddresses(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncAddresses = /* GraphQL */ `
  query SyncAddresses(
    $filter: ModelAddressFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncAddresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContractorFirstResponder = /* GraphQL */ `
  query GetContractorFirstResponder($id: ID!) {
    getContractorFirstResponder(id: $id) {
      id
      Number
      Priority
      contractorsID
      Name
      Availability
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listContractorFirstResponders = /* GraphQL */ `
  query ListContractorFirstResponders(
    $filter: ModelContractorFirstResponderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractorFirstResponders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Number
        Priority
        contractorsID
        Name
        Availability
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContractorFirstResponders = /* GraphQL */ `
  query SyncContractorFirstResponders(
    $filter: ModelContractorFirstResponderFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContractorFirstResponders(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Number
        Priority
        contractorsID
        Name
        Availability
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contractorFirstRespondersByContractorsID = /* GraphQL */ `
  query ContractorFirstRespondersByContractorsID(
    $contractorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelContractorFirstResponderFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractorFirstRespondersByContractorsID(
      contractorsID: $contractorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Number
        Priority
        contractorsID
        Name
        Availability
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getBuildings = /* GraphQL */ `
  query GetBuildings($id: ID!) {
    getBuildings(id: $id) {
      id
      Name
      Phone
      SubAccountId
      RefreshRateInSec
      IsFetchingWeather
      ownersID
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      BFGVersion {
        id
        Name
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StripeManagement {
        id
        SubscriptionProductsQuantity
        UsedProductsQuantity
        TotalPricePerMonth
        PaymentStatus
        SubscriptionEnd
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            Reboots
            LastRebootReason
            LastPrivateRegister
            LastPublicRegister
            LastPrivateRegisterReason
            LastPublicRegisterReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      MikrotikClients {
        id
        DstAddress
        RemoteAddress
        Username
        Password
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      contractorsID
      Contractors {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      Owners {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      PingBuilding {
        id
        IpAddress
        Status
        MacAddress
        FirmwareVersion
        ErrorsCode
        PowerCycles
        ForceUpdateBFG
        ForceUpdateFirmware
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      buildingsAddressId
      buildingsBFGVersionId
      buildingsStripeManagementId
      buildingsMikrotikClientsId
      buildingsPingBuildingId
      __typename
    }
  }
`;
export const listBuildings = /* GraphQL */ `
  query ListBuildings(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBuildings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Phone
        SubAccountId
        RefreshRateInSec
        IsFetchingWeather
        ownersID
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BFGVersion {
          id
          Name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StripeManagement {
          id
          SubscriptionProductsQuantity
          UsedProductsQuantity
          TotalPricePerMonth
          PaymentStatus
          SubscriptionEnd
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        MikrotikClients {
          id
          DstAddress
          RemoteAddress
          Username
          Password
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        contractorsID
        Contractors {
          id
          Name
          Email
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          ContractorFirstResponder {
            items {
              id
              Number
              Priority
              contractorsID
              Name
              Availability
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contractorsAddressId
          __typename
        }
        Owners {
          id
          BusinessName
          Email
          BillingEmail
          PhoneNumber
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          ownersAddressId
          __typename
        }
        PingBuilding {
          id
          IpAddress
          Status
          MacAddress
          FirmwareVersion
          ErrorsCode
          PowerCycles
          ForceUpdateBFG
          ForceUpdateFirmware
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        buildingsAddressId
        buildingsBFGVersionId
        buildingsStripeManagementId
        buildingsMikrotikClientsId
        buildingsPingBuildingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncBuildings = /* GraphQL */ `
  query SyncBuildings(
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncBuildings(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Phone
        SubAccountId
        RefreshRateInSec
        IsFetchingWeather
        ownersID
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BFGVersion {
          id
          Name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StripeManagement {
          id
          SubscriptionProductsQuantity
          UsedProductsQuantity
          TotalPricePerMonth
          PaymentStatus
          SubscriptionEnd
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        MikrotikClients {
          id
          DstAddress
          RemoteAddress
          Username
          Password
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        contractorsID
        Contractors {
          id
          Name
          Email
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          ContractorFirstResponder {
            items {
              id
              Number
              Priority
              contractorsID
              Name
              Availability
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contractorsAddressId
          __typename
        }
        Owners {
          id
          BusinessName
          Email
          BillingEmail
          PhoneNumber
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          ownersAddressId
          __typename
        }
        PingBuilding {
          id
          IpAddress
          Status
          MacAddress
          FirmwareVersion
          ErrorsCode
          PowerCycles
          ForceUpdateBFG
          ForceUpdateFirmware
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        buildingsAddressId
        buildingsBFGVersionId
        buildingsStripeManagementId
        buildingsMikrotikClientsId
        buildingsPingBuildingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const buildingsByOwnersID = /* GraphQL */ `
  query BuildingsByOwnersID(
    $ownersID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    buildingsByOwnersID(
      ownersID: $ownersID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Phone
        SubAccountId
        RefreshRateInSec
        IsFetchingWeather
        ownersID
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BFGVersion {
          id
          Name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StripeManagement {
          id
          SubscriptionProductsQuantity
          UsedProductsQuantity
          TotalPricePerMonth
          PaymentStatus
          SubscriptionEnd
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        MikrotikClients {
          id
          DstAddress
          RemoteAddress
          Username
          Password
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        contractorsID
        Contractors {
          id
          Name
          Email
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          ContractorFirstResponder {
            items {
              id
              Number
              Priority
              contractorsID
              Name
              Availability
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contractorsAddressId
          __typename
        }
        Owners {
          id
          BusinessName
          Email
          BillingEmail
          PhoneNumber
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          ownersAddressId
          __typename
        }
        PingBuilding {
          id
          IpAddress
          Status
          MacAddress
          FirmwareVersion
          ErrorsCode
          PowerCycles
          ForceUpdateBFG
          ForceUpdateFirmware
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        buildingsAddressId
        buildingsBFGVersionId
        buildingsStripeManagementId
        buildingsMikrotikClientsId
        buildingsPingBuildingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const buildingsByContractorsID = /* GraphQL */ `
  query BuildingsByContractorsID(
    $contractorsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBuildingsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    buildingsByContractorsID(
      contractorsID: $contractorsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Phone
        SubAccountId
        RefreshRateInSec
        IsFetchingWeather
        ownersID
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        BFGVersion {
          id
          Name
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StripeManagement {
          id
          SubscriptionProductsQuantity
          UsedProductsQuantity
          TotalPricePerMonth
          PaymentStatus
          SubscriptionEnd
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        MikrotikClients {
          id
          DstAddress
          RemoteAddress
          Username
          Password
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        contractorsID
        Contractors {
          id
          Name
          Email
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          ContractorFirstResponder {
            items {
              id
              Number
              Priority
              contractorsID
              Name
              Availability
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          contractorsAddressId
          __typename
        }
        Owners {
          id
          BusinessName
          Email
          BillingEmail
          PhoneNumber
          Buildings {
            items {
              id
              Name
              Phone
              SubAccountId
              RefreshRateInSec
              IsFetchingWeather
              ownersID
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              BFGVersion {
                id
                Name
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StripeManagement {
                id
                SubscriptionProductsQuantity
                UsedProductsQuantity
                TotalPricePerMonth
                PaymentStatus
                SubscriptionEnd
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              MikrotikClients {
                id
                DstAddress
                RemoteAddress
                Username
                Password
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              contractorsID
              Contractors {
                id
                Name
                Email
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                ContractorFirstResponder {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                contractorsAddressId
                __typename
              }
              Owners {
                id
                BusinessName
                Email
                BillingEmail
                PhoneNumber
                Buildings {
                  nextToken
                  startedAt
                  __typename
                }
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                ConfirmationStatus
                Status
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                ownersAddressId
                __typename
              }
              PingBuilding {
                id
                IpAddress
                Status
                MacAddress
                FirmwareVersion
                ErrorsCode
                PowerCycles
                ForceUpdateBFG
                ForceUpdateFirmware
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              buildingsAddressId
              buildingsBFGVersionId
              buildingsStripeManagementId
              buildingsMikrotikClientsId
              buildingsPingBuildingId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          ConfirmationStatus
          Status
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          ownersAddressId
          __typename
        }
        PingBuilding {
          id
          IpAddress
          Status
          MacAddress
          FirmwareVersion
          ErrorsCode
          PowerCycles
          ForceUpdateBFG
          ForceUpdateFirmware
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        buildingsAddressId
        buildingsBFGVersionId
        buildingsStripeManagementId
        buildingsMikrotikClientsId
        buildingsPingBuildingId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getOwners = /* GraphQL */ `
  query GetOwners($id: ID!) {
    getOwners(id: $id) {
      id
      BusinessName
      Email
      BillingEmail
      PhoneNumber
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                Reboots
                LastRebootReason
                LastPrivateRegister
                LastPublicRegister
                LastPrivateRegisterReason
                LastPublicRegisterReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      ownersAddressId
      __typename
    }
  }
`;
export const listOwners = /* GraphQL */ `
  query ListOwners(
    $filter: ModelOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOwners(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncOwners = /* GraphQL */ `
  query SyncOwners(
    $filter: ModelOwnersFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncOwners(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const ownersByEmailAndId = /* GraphQL */ `
  query OwnersByEmailAndId(
    $Email: AWSEmail!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelOwnersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    ownersByEmailAndId(
      Email: $Email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        BusinessName
        Email
        BillingEmail
        PhoneNumber
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        ownersAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getContractors = /* GraphQL */ `
  query GetContractors($id: ID!) {
    getContractors(id: $id) {
      id
      Name
      Email
      Buildings {
        items {
          id
          Name
          Phone
          SubAccountId
          RefreshRateInSec
          IsFetchingWeather
          ownersID
          Address {
            id
            Street1
            Street2
            City
            Country
            Province
            PostalCode
            Phone
            Fax
            ContactName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          BFGVersion {
            id
            Name
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StripeManagement {
            id
            SubscriptionProductsQuantity
            UsedProductsQuantity
            TotalPricePerMonth
            PaymentStatus
            SubscriptionEnd
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                Reboots
                LastRebootReason
                LastPrivateRegister
                LastPublicRegister
                LastPrivateRegisterReason
                LastPublicRegisterReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          MikrotikClients {
            id
            DstAddress
            RemoteAddress
            Username
            Password
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          contractorsID
          Contractors {
            id
            Name
            Email
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            ContractorFirstResponder {
              items {
                id
                Number
                Priority
                contractorsID
                Name
                Availability
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            contractorsAddressId
            __typename
          }
          Owners {
            id
            BusinessName
            Email
            BillingEmail
            PhoneNumber
            Buildings {
              items {
                id
                Name
                Phone
                SubAccountId
                RefreshRateInSec
                IsFetchingWeather
                ownersID
                Address {
                  id
                  Street1
                  Street2
                  City
                  Country
                  Province
                  PostalCode
                  Phone
                  Fax
                  ContactName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                BFGVersion {
                  id
                  Name
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StripeManagement {
                  id
                  SubscriptionProductsQuantity
                  UsedProductsQuantity
                  TotalPricePerMonth
                  PaymentStatus
                  SubscriptionEnd
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                MikrotikClients {
                  id
                  DstAddress
                  RemoteAddress
                  Username
                  Password
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                contractorsID
                Contractors {
                  id
                  Name
                  Email
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  contractorsAddressId
                  __typename
                }
                Owners {
                  id
                  BusinessName
                  Email
                  BillingEmail
                  PhoneNumber
                  ConfirmationStatus
                  Status
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  ownersAddressId
                  __typename
                }
                PingBuilding {
                  id
                  IpAddress
                  Status
                  MacAddress
                  FirmwareVersion
                  ErrorsCode
                  PowerCycles
                  ForceUpdateBFG
                  ForceUpdateFirmware
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                buildingsAddressId
                buildingsBFGVersionId
                buildingsStripeManagementId
                buildingsMikrotikClientsId
                buildingsPingBuildingId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            ConfirmationStatus
            Status
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            ownersAddressId
            __typename
          }
          PingBuilding {
            id
            IpAddress
            Status
            MacAddress
            FirmwareVersion
            ErrorsCode
            PowerCycles
            ForceUpdateBFG
            ForceUpdateFirmware
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          buildingsAddressId
          buildingsBFGVersionId
          buildingsStripeManagementId
          buildingsMikrotikClientsId
          buildingsPingBuildingId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      ContractorFirstResponder {
        items {
          id
          Number
          Priority
          contractorsID
          Name
          Availability
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      Address {
        id
        Street1
        Street2
        City
        Country
        Province
        PostalCode
        Phone
        Fax
        ContactName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      ConfirmationStatus
      Status
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      contractorsAddressId
      __typename
    }
  }
`;
export const listContractors = /* GraphQL */ `
  query ListContractors(
    $filter: ModelContractorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContractors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncContractors = /* GraphQL */ `
  query SyncContractors(
    $filter: ModelContractorsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncContractors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const contractorsByEmailAndId = /* GraphQL */ `
  query ContractorsByEmailAndId(
    $Email: AWSEmail!
    $id: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelContractorsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    contractorsByEmailAndId(
      Email: $Email
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Email
        Buildings {
          items {
            id
            Name
            Phone
            SubAccountId
            RefreshRateInSec
            IsFetchingWeather
            ownersID
            Address {
              id
              Street1
              Street2
              City
              Country
              Province
              PostalCode
              Phone
              Fax
              ContactName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            BFGVersion {
              id
              Name
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StripeManagement {
              id
              SubscriptionProductsQuantity
              UsedProductsQuantity
              TotalPricePerMonth
              PaymentStatus
              SubscriptionEnd
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            MikrotikClients {
              id
              DstAddress
              RemoteAddress
              Username
              Password
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            contractorsID
            Contractors {
              id
              Name
              Email
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              ContractorFirstResponder {
                items {
                  id
                  Number
                  Priority
                  contractorsID
                  Name
                  Availability
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              contractorsAddressId
              __typename
            }
            Owners {
              id
              BusinessName
              Email
              BillingEmail
              PhoneNumber
              Buildings {
                items {
                  id
                  Name
                  Phone
                  SubAccountId
                  RefreshRateInSec
                  IsFetchingWeather
                  ownersID
                  contractorsID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  buildingsAddressId
                  buildingsBFGVersionId
                  buildingsStripeManagementId
                  buildingsMikrotikClientsId
                  buildingsPingBuildingId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Address {
                id
                Street1
                Street2
                City
                Country
                Province
                PostalCode
                Phone
                Fax
                ContactName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              ConfirmationStatus
              Status
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              ownersAddressId
              __typename
            }
            PingBuilding {
              id
              IpAddress
              Status
              MacAddress
              FirmwareVersion
              ErrorsCode
              PowerCycles
              ForceUpdateBFG
              ForceUpdateFirmware
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            buildingsAddressId
            buildingsBFGVersionId
            buildingsStripeManagementId
            buildingsMikrotikClientsId
            buildingsPingBuildingId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        ContractorFirstResponder {
          items {
            id
            Number
            Priority
            contractorsID
            Name
            Availability
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Address {
          id
          Street1
          Street2
          City
          Country
          Province
          PostalCode
          Phone
          Fax
          ContactName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        ConfirmationStatus
        Status
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        contractorsAddressId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getModels = /* GraphQL */ `
  query GetModels($id: ID!) {
    getModels(id: $id) {
      id
      Name
      Description
      Resolution
      IsStreaming
      IsSipPhone
      IsSipPublic
      IsSipPrivate
      MaxMemorySizeInKB
      DeviceType
      Devices {
        items {
          id
          Name
          PhysicalAddress
          DoorNumber
          VciConfigName
          ViaConfigName
          DeviceInfoRuntime {
            id
            ErrorsCode
            Status
            PowerCycles
            IpAddress
            MacAddress
            FirmwareVersion
            Reboots
            LastRebootReason
            LastPrivateRegister
            LastPublicRegister
            LastPrivateRegisterReason
            LastPublicRegisterReason
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          SIPAccount {
            items {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          modelsID
          buildingsID
          Models {
            id
            Name
            Description
            Resolution
            IsStreaming
            IsSipPhone
            IsSipPublic
            IsSipPrivate
            MaxMemorySizeInKB
            DeviceType
            Devices {
              items {
                id
                Name
                PhysicalAddress
                DoorNumber
                VciConfigName
                ViaConfigName
                DeviceInfoRuntime {
                  id
                  ErrorsCode
                  Status
                  PowerCycles
                  IpAddress
                  MacAddress
                  FirmwareVersion
                  Reboots
                  LastRebootReason
                  LastPrivateRegister
                  LastPublicRegister
                  LastPrivateRegisterReason
                  LastPublicRegisterReason
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                SIPAccount {
                  nextToken
                  startedAt
                  __typename
                }
                modelsID
                buildingsID
                Models {
                  id
                  Name
                  Description
                  Resolution
                  IsStreaming
                  IsSipPhone
                  IsSipPublic
                  IsSipPrivate
                  MaxMemorySizeInKB
                  DeviceType
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamIpAddress
                RingGroup {
                  id
                  Name
                  Extension
                  Time
                  CountryCode
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                StreamPort
                GroupID
                ThemeFolderName
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                devicesDeviceInfoRuntimeId
                devicesRingGroupId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamIpAddress
          RingGroup {
            id
            Name
            Extension
            Callees {
              items {
                id
                Number
                LinkNumber
                LinkEmail
                Priority
                SIPAccount {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                RingGroupID
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                calleesSIPAccountId
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            Time
            CountryCode
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          StreamPort
          GroupID
          ThemeFolderName
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          devicesDeviceInfoRuntimeId
          devicesRingGroupId
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listModels = /* GraphQL */ `
  query ListModels(
    $filter: ModelModelsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Description
        Resolution
        IsStreaming
        IsSipPhone
        IsSipPublic
        IsSipPrivate
        MaxMemorySizeInKB
        DeviceType
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncModels = /* GraphQL */ `
  query SyncModels(
    $filter: ModelModelsFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncModels(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Description
        Resolution
        IsStreaming
        IsSipPhone
        IsSipPublic
        IsSipPrivate
        MaxMemorySizeInKB
        DeviceType
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDevices = /* GraphQL */ `
  query GetDevices($id: ID!) {
    getDevices(id: $id) {
      id
      Name
      PhysicalAddress
      DoorNumber
      VciConfigName
      ViaConfigName
      DeviceInfoRuntime {
        id
        ErrorsCode
        Status
        PowerCycles
        IpAddress
        MacAddress
        FirmwareVersion
        Reboots
        LastRebootReason
        LastPrivateRegister
        LastPublicRegister
        LastPrivateRegisterReason
        LastPublicRegisterReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      SIPAccount {
        items {
          id
          Name
          Type
          SIPOwner
          PrimaryAddress
          SecondaryAddress
          PbxUsername
          PbxPassword
          PbxExtension
          ClientPeerId
          StreamName
          PhoneNumber
          DeviceID
          Port
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        nextToken
        startedAt
        __typename
      }
      modelsID
      buildingsID
      Models {
        id
        Name
        Description
        Resolution
        IsStreaming
        IsSipPhone
        IsSipPublic
        IsSipPrivate
        MaxMemorySizeInKB
        DeviceType
        Devices {
          items {
            id
            Name
            PhysicalAddress
            DoorNumber
            VciConfigName
            ViaConfigName
            DeviceInfoRuntime {
              id
              ErrorsCode
              Status
              PowerCycles
              IpAddress
              MacAddress
              FirmwareVersion
              Reboots
              LastRebootReason
              LastPrivateRegister
              LastPublicRegister
              LastPrivateRegisterReason
              LastPublicRegisterReason
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            SIPAccount {
              items {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              nextToken
              startedAt
              __typename
            }
            modelsID
            buildingsID
            Models {
              id
              Name
              Description
              Resolution
              IsStreaming
              IsSipPhone
              IsSipPublic
              IsSipPrivate
              MaxMemorySizeInKB
              DeviceType
              Devices {
                items {
                  id
                  Name
                  PhysicalAddress
                  DoorNumber
                  VciConfigName
                  ViaConfigName
                  modelsID
                  buildingsID
                  StreamIpAddress
                  StreamPort
                  GroupID
                  ThemeFolderName
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  devicesDeviceInfoRuntimeId
                  devicesRingGroupId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamIpAddress
            RingGroup {
              id
              Name
              Extension
              Callees {
                items {
                  id
                  Number
                  LinkNumber
                  LinkEmail
                  Priority
                  RingGroupID
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  calleesSIPAccountId
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              Time
              CountryCode
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            StreamPort
            GroupID
            ThemeFolderName
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            devicesDeviceInfoRuntimeId
            devicesRingGroupId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamIpAddress
      RingGroup {
        id
        Name
        Extension
        Callees {
          items {
            id
            Number
            LinkNumber
            LinkEmail
            Priority
            SIPAccount {
              id
              Name
              Type
              SIPOwner
              PrimaryAddress
              SecondaryAddress
              PbxUsername
              PbxPassword
              PbxExtension
              ClientPeerId
              StreamName
              PhoneNumber
              DeviceID
              Port
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              __typename
            }
            RingGroupID
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            calleesSIPAccountId
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        Time
        CountryCode
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      StreamPort
      GroupID
      ThemeFolderName
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      devicesDeviceInfoRuntimeId
      devicesRingGroupId
      __typename
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          Reboots
          LastRebootReason
          LastPrivateRegister
          LastPublicRegister
          LastPrivateRegisterReason
          LastPublicRegisterReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                Reboots
                LastRebootReason
                LastPrivateRegister
                LastPublicRegister
                LastPrivateRegisterReason
                LastPublicRegisterReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDevices = /* GraphQL */ `
  query SyncDevices(
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDevices(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          Reboots
          LastRebootReason
          LastPrivateRegister
          LastPublicRegister
          LastPrivateRegisterReason
          LastPublicRegisterReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                Reboots
                LastRebootReason
                LastPrivateRegister
                LastPublicRegister
                LastPrivateRegisterReason
                LastPublicRegisterReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const devicesByModelsID = /* GraphQL */ `
  query DevicesByModelsID(
    $modelsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByModelsID(
      modelsID: $modelsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          Reboots
          LastRebootReason
          LastPrivateRegister
          LastPublicRegister
          LastPrivateRegisterReason
          LastPublicRegisterReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                Reboots
                LastRebootReason
                LastPrivateRegister
                LastPublicRegister
                LastPrivateRegisterReason
                LastPublicRegisterReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const devicesByBuildingsID = /* GraphQL */ `
  query DevicesByBuildingsID(
    $buildingsID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDevicesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    devicesByBuildingsID(
      buildingsID: $buildingsID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        PhysicalAddress
        DoorNumber
        VciConfigName
        ViaConfigName
        DeviceInfoRuntime {
          id
          ErrorsCode
          Status
          PowerCycles
          IpAddress
          MacAddress
          FirmwareVersion
          Reboots
          LastRebootReason
          LastPrivateRegister
          LastPublicRegister
          LastPrivateRegisterReason
          LastPublicRegisterReason
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        SIPAccount {
          items {
            id
            Name
            Type
            SIPOwner
            PrimaryAddress
            SecondaryAddress
            PbxUsername
            PbxPassword
            PbxExtension
            ClientPeerId
            StreamName
            PhoneNumber
            DeviceID
            Port
            createdAt
            updatedAt
            _version
            _deleted
            _lastChangedAt
            __typename
          }
          nextToken
          startedAt
          __typename
        }
        modelsID
        buildingsID
        Models {
          id
          Name
          Description
          Resolution
          IsStreaming
          IsSipPhone
          IsSipPublic
          IsSipPrivate
          MaxMemorySizeInKB
          DeviceType
          Devices {
            items {
              id
              Name
              PhysicalAddress
              DoorNumber
              VciConfigName
              ViaConfigName
              DeviceInfoRuntime {
                id
                ErrorsCode
                Status
                PowerCycles
                IpAddress
                MacAddress
                FirmwareVersion
                Reboots
                LastRebootReason
                LastPrivateRegister
                LastPublicRegister
                LastPrivateRegisterReason
                LastPublicRegisterReason
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              SIPAccount {
                items {
                  id
                  Name
                  Type
                  SIPOwner
                  PrimaryAddress
                  SecondaryAddress
                  PbxUsername
                  PbxPassword
                  PbxExtension
                  ClientPeerId
                  StreamName
                  PhoneNumber
                  DeviceID
                  Port
                  createdAt
                  updatedAt
                  _version
                  _deleted
                  _lastChangedAt
                  __typename
                }
                nextToken
                startedAt
                __typename
              }
              modelsID
              buildingsID
              Models {
                id
                Name
                Description
                Resolution
                IsStreaming
                IsSipPhone
                IsSipPublic
                IsSipPrivate
                MaxMemorySizeInKB
                DeviceType
                Devices {
                  nextToken
                  startedAt
                  __typename
                }
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamIpAddress
              RingGroup {
                id
                Name
                Extension
                Callees {
                  nextToken
                  startedAt
                  __typename
                }
                Time
                CountryCode
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              StreamPort
              GroupID
              ThemeFolderName
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              devicesDeviceInfoRuntimeId
              devicesRingGroupId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamIpAddress
        RingGroup {
          id
          Name
          Extension
          Callees {
            items {
              id
              Number
              LinkNumber
              LinkEmail
              Priority
              SIPAccount {
                id
                Name
                Type
                SIPOwner
                PrimaryAddress
                SecondaryAddress
                PbxUsername
                PbxPassword
                PbxExtension
                ClientPeerId
                StreamName
                PhoneNumber
                DeviceID
                Port
                createdAt
                updatedAt
                _version
                _deleted
                _lastChangedAt
                __typename
              }
              RingGroupID
              createdAt
              updatedAt
              _version
              _deleted
              _lastChangedAt
              calleesSIPAccountId
              __typename
            }
            nextToken
            startedAt
            __typename
          }
          Time
          CountryCode
          createdAt
          updatedAt
          _version
          _deleted
          _lastChangedAt
          __typename
        }
        StreamPort
        GroupID
        ThemeFolderName
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        devicesDeviceInfoRuntimeId
        devicesRingGroupId
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getSIPAccount = /* GraphQL */ `
  query GetSIPAccount($id: ID!) {
    getSIPAccount(id: $id) {
      id
      Name
      Type
      SIPOwner
      PrimaryAddress
      SecondaryAddress
      PbxUsername
      PbxPassword
      PbxExtension
      ClientPeerId
      StreamName
      PhoneNumber
      DeviceID
      Port
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listSIPAccounts = /* GraphQL */ `
  query ListSIPAccounts(
    $filter: ModelSIPAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSIPAccounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncSIPAccounts = /* GraphQL */ `
  query SyncSIPAccounts(
    $filter: ModelSIPAccountFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncSIPAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sIPAccountsByStreamName = /* GraphQL */ `
  query SIPAccountsByStreamName(
    $StreamName: String!
    $sortDirection: ModelSortDirection
    $filter: ModelSIPAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sIPAccountsByStreamName(
      StreamName: $StreamName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const sIPAccountsByDeviceID = /* GraphQL */ `
  query SIPAccountsByDeviceID(
    $DeviceID: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSIPAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sIPAccountsByDeviceID(
      DeviceID: $DeviceID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        Name
        Type
        SIPOwner
        PrimaryAddress
        SecondaryAddress
        PbxUsername
        PbxPassword
        PbxExtension
        ClientPeerId
        StreamName
        PhoneNumber
        DeviceID
        Port
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const getDeviceInfoRuntime = /* GraphQL */ `
  query GetDeviceInfoRuntime($id: ID!) {
    getDeviceInfoRuntime(id: $id) {
      id
      ErrorsCode
      Status
      PowerCycles
      IpAddress
      MacAddress
      FirmwareVersion
      Reboots
      LastRebootReason
      LastPrivateRegister
      LastPublicRegister
      LastPrivateRegisterReason
      LastPublicRegisterReason
      createdAt
      updatedAt
      _version
      _deleted
      _lastChangedAt
      __typename
    }
  }
`;
export const listDeviceInfoRuntimes = /* GraphQL */ `
  query ListDeviceInfoRuntimes(
    $filter: ModelDeviceInfoRuntimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDeviceInfoRuntimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        ErrorsCode
        Status
        PowerCycles
        IpAddress
        MacAddress
        FirmwareVersion
        Reboots
        LastRebootReason
        LastPrivateRegister
        LastPublicRegister
        LastPrivateRegisterReason
        LastPublicRegisterReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
export const syncDeviceInfoRuntimes = /* GraphQL */ `
  query SyncDeviceInfoRuntimes(
    $filter: ModelDeviceInfoRuntimeFilterInput
    $limit: Int
    $nextToken: String
    $lastSync: AWSTimestamp
  ) {
    syncDeviceInfoRuntimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      lastSync: $lastSync
    ) {
      items {
        id
        ErrorsCode
        Status
        PowerCycles
        IpAddress
        MacAddress
        FirmwareVersion
        Reboots
        LastRebootReason
        LastPrivateRegister
        LastPublicRegister
        LastPrivateRegisterReason
        LastPublicRegisterReason
        createdAt
        updatedAt
        _version
        _deleted
        _lastChangedAt
        __typename
      }
      nextToken
      startedAt
      __typename
    }
  }
`;
