import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import "./css/FirstResponderForm.css";
import { requiredValidator, fieldFormatValidator,maxLengthValidator, VALIDATOR_FORMATS, MAX_LENGTH } from "../../constants/RequiredValidator";
import InputGlobal from "../Form/InputGlobal";
import { FormContextGlobal } from "../Form/FormProvider";
import arrowIcon from '../../img/Arrow_Marine Blue.svg';
import plusIcon from '../../img/plus-icon.svg';
import deleteIcon from '../../img/delete-icon.svg';
import Button from '../Buttons/Button';
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";


const FirstResponderForm = ({ item, onSubmit, onDataChanged }) => {
  const { t } = useTranslation(["common", "firstResponders"]);
  const [submitButtonText, setSubmitButtonText] = useState(item.id ? t(TRANSLATION_KEYS.EDIT) : t(TRANSLATION_KEYS.CREATE));
  const { data, errors, registerInput } = useContext(FormContextGlobal);
  const [availabilitySlots, setAvailabilitySlots] = useState({});
  const [availabilityStates, setAvailabilityStates] = useState({});
  const [validators, setValidators] = useState({
    Name: [requiredValidator, (e) => maxLengthValidator(e, MAX_LENGTH.TEXT )],
    Number: [requiredValidator, (e) => fieldFormatValidator(e, VALIDATOR_FORMATS.NORTH_AMERICAN_PHONE)],
  });
  let availabilityItem = item ? item.Availability : {};

  const initializeStates = () => {
    const availability = availabilityItem;
    const slotsState = {};
    const states = {};

    Object.keys(availability).forEach((day) => {
      slotsState[day] = availability[day].map((slot) => ({...slot}));
      states[day] = availability[day].length > 0;
    });

    return {
      slotsState,
      states,
    };
  };

  useEffect(() => {
    if (item.Availability) {
      const { slotsState, states } = initializeStates();
      setAvailabilityStates(states);
      setAvailabilitySlots(slotsState);
      setValidators((prevValidators) => ({
        ...prevValidators,
          ...Object.keys(slotsState).reduce((acc, day) => {
            slotsState[day].forEach((slot, index) => {
              acc[`${day}_${index}_start`] = [requiredValidator, (e) => fieldFormatValidator(e, VALIDATOR_FORMATS.TIME)];
              acc[`${day}_${index}_end`] = [requiredValidator, (e) => fieldFormatValidator(e, VALIDATOR_FORMATS.TIME)];
            });
            return acc;
          }, {}),
      }));
    }
    item.Name ? setSubmitButtonText(t(TRANSLATION_KEYS.EDIT)) : setSubmitButtonText(t(TRANSLATION_KEYS.CREATE));
  }, [item]);

  const formatTime = (time) => {
    // Remove colons and any whitespace
    let cleanTime = time.replace(/:|\s/g, "");

    // Check if the input is less than 4 digits and pad with zeros if necessary
    if (cleanTime.length < 4 && cleanTime.length > 1) {
      cleanTime = cleanTime.padStart(4, "0");
    }

    // Extract hours and minutes
    const hours = cleanTime.slice(0, -2);
    const minutes = cleanTime.slice(-2);

    // Format the time
    return `${parseInt(hours, 10)}h${minutes.padStart(2, "0")}`;
  };

  const addTimeSlot = (day) => {
    const updatedData = {
      ...data,
      Availability: {
        ...data.Availability,
        [day]: [...data.Availability[day], { Start: "", Finish: "" }],
      },
    };
    onDataChanged(updatedData);
  };

  const removeTimeSlot = (day) => {
    const updatedData = {
      ...data,
    };
    delete updatedData.Availability[day].splice(1, 1);
    delete updatedData[`${day}_1_start`];
    delete updatedData[`${day}_1_end`];
    registerInput(`${day}_1_start`, validators[`${day}_1_start`])("8h00");
    registerInput(`${day}_1_end`, validators[`${day}_1_end`])("8h00");
    const updatedValidators = {...validators};
    delete updatedValidators[`${day}_1_start`];
    delete updatedValidators[`${day}_1_end`];
    setValidators(updatedValidators);
    onDataChanged(updatedData);
  };

  // Handle availability checkbox change
  const handleChangeNotAvailable = (day) => {
    if (!availabilityStates[day] && availabilitySlots[day].length === 0) {
      const updatedData = {
        ...data,
        Availability: {
          ...data.Availability,
          [day]: data.Availability[day] = [{ Start: "", Finish: "" }],
        },
      };
      onDataChanged(updatedData);
    }

    if (availabilityStates[day]) {
      const updatedData = {
        ...data,
        Availability: {
          ...data.Availability,
          [day]: data.Availability[day] = [],
        },
      };
      resetValidators(day);
      delete updatedData[`${day}_0_start`];
      delete updatedData[`${day}_0_end`];
      delete updatedData[`${day}_1_start`];
      delete updatedData[`${day}_1_end`];
      onDataChanged(updatedData);
    }
  };

  const resetValidators = (day) => {
    registerInput(`${day}_0_start`, validators[`${day}_0_start`])("8h00");
    registerInput(`${day}_0_end`, validators[`${day}_0_end`])("8h00");
    registerInput(`${day}_1_start`, validators[`${day}_1_start`])("8h00");
    registerInput(`${day}_1_end`, validators[`${day}_1_end`])("8h00");
    const updatedValidators = {...validators};
    delete updatedValidators[`${day}_0_start`];
    delete updatedValidators[`${day}_0_end`];
    delete updatedValidators[`${day}_1_start`];
    delete updatedValidators[`${day}_1_end`];
    setValidators(updatedValidators);
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    if (validateForm()) {
      const Name = data["Name"];
      const Number = data["Number"].replace(/\D/g, '')
      const availability = JSON.stringify(
        Object.entries(availabilitySlots).reduce((acc, [day, slots]) => {
          if (availabilityStates[day]) {
            acc[day] = slots.map((slot, index) => ({
              Start: formatTime(data[`${day}_${index}_start`]),
              Finish: formatTime(data[`${day}_${index}_end`]),
            }));
          } else {
            acc[day] = [];
          }
          return acc;
        }, {})
      );
  
      const responderDetails = {
        Number,
        Name,
        Priority: !!data.Priority ? parseInt(data["Priority"], 10) : 1,
        Availability: availability,
      };

      onSubmit(responderDetails);
    }

  };

  const validateForm = () => {
    let errorsBeforeSubmit = [];

    for (const validator in validators) {
      errorsBeforeSubmit.push(registerInput(validator, validators[validator])(data[validator]));
    }

    let isValid = true;
    for (const field in errorsBeforeSubmit) {
      if (errorsBeforeSubmit[field].length > 0) {
        isValid = false;
        break;
      }
    }

    return isValid;
  }

  const handleUpdateTime = (e) => {
    const { name, value } = e.target;
    const [day, index, time] = name.split("_");
    const updatedData = {
      ...data,
      [name]: value,
    };
    time === "start" ? updatedData.Availability[day][index].Start = value : updatedData.Availability[day][index].Finish = value;
    onDataChanged(updatedData);
  }

  const getStringRessourceFromKey = (key) => {
    //get the translation string resource from the key
    switch (key) {
      case "Monday": return t(TRANSLATION_KEYS.FIRST_RESPONDERS_MONDAY);
      case "Tuesday": return t(TRANSLATION_KEYS.FIRST_RESPONDERS_TUESDAY);
      case "Wednesday": return t(TRANSLATION_KEYS.FIRST_RESPONDERS_WEDNESDAY);
      case "Thursday": return t(TRANSLATION_KEYS.FIRST_RESPONDERS_THURSDAY);
      case "Friday": return t(TRANSLATION_KEYS.FIRST_RESPONDERS_FRIDAY);
      case "Saturday": return t(TRANSLATION_KEYS.FIRST_RESPONDERS_SATURDAY);
      case "Sunday": return t(TRANSLATION_KEYS.FIRST_RESPONDERS_SUNDAY);

      default: return ""
    }
  }

  return (
    <form noValidate id="responder-form" onSubmit={(event) => handleSubmit(event)}>
      <div className='form-header'>
        <InputGlobal
          type='text'
          name='Name'
          validators={validators.Name}
          required={true}
          placeholder={t(TRANSLATION_KEYS.FIRST_RESPONDERS_NAME_PLACEHOLDER)}
          label={t(TRANSLATION_KEYS.FIRST_RESPONDERS_NAME_LABEL)}
        />
        <InputGlobal
          type='tel'
          name='Number'
          validators={validators.Number}
          required={true}
          placeholder={t(TRANSLATION_KEYS.FIRST_RESPONDERS_PHONE_PLACEHOLDER)}
          label={t(TRANSLATION_KEYS.FIRST_RESPONDERS_PHONE_LABEL)}
        />
      </div>
      <div>
        <p className="form-title titreInput">{t(TRANSLATION_KEYS.FIRST_RESPONDERS_SET_SCHEDULE)}</p>
      </div>
      <div id="schedule">
        {Object.keys(availabilityStates).map((day) => (
          <div className="day" key={day}>
            <div className="availability">
              <p className="day-title titreInput">{getStringRessourceFromKey(day)}</p>
              <InputGlobal
                className={!availabilityStates[day] ? "checked" : ""}
                name={"availabilityState" + day}
                label={t(TRANSLATION_KEYS.FIRST_RESPONDERS_NOT_AVAILABLE)}
                type='checkbox'
                checked={!availabilityStates[day]}
                onChange={() => handleChangeNotAvailable(day)}
              />
            </div>
            <div>
              {availabilityStates[day] &&
                availabilitySlots[day].map((slot, index) => ((
                  <div key={index} className="time-selection">	
                      <InputGlobal
                        type='text'
                        name={`${day}_${index}_start`}
                        placeholder='8h00'
                        validators={validators[`${day}_${index}_start`]}
                        label={t(TRANSLATION_KEYS.FIRST_RESPONDERS_START)}
                        onChange={handleUpdateTime}
                      />
                    <img src={arrowIcon} alt="arrow" />
                      <InputGlobal
                        type='text'
                        name={`${day}_${index}_end`}
                        placeholder='17h00'
                        validators={validators[`${day}_${index}_end`]}
                        label={t(TRANSLATION_KEYS.FIRST_RESPONDERS_END)}
                        onChange={handleUpdateTime}
                      />
                    {index === 1 && availabilitySlots[day].length > 1 ?
                      <img src={deleteIcon} alt={"delete"} onClick={() => removeTimeSlot(day, slot.id)} /> :
                      availabilitySlots[day].length === 1 && <img src={plusIcon} alt={"plus"} onClick={() => addTimeSlot(day)} />
                    }
                  </div>))
                )}
            </div>
          </div>
        ))}
      </div>
      <Button text={submitButtonText} type="submit" />
    </form>

  );
};

export default FirstResponderForm;
