import React from 'react';
import { useTranslation } from "react-i18next";
import "./css/TravelInfoProperties.css";
import SelectGlobal from '../Form/SelectGlobal';
import { DEFAULT_TRAVEL_INFO_PATH, TRAVEL_INFO_ARROW_POSITION, TRAVEL_INFO_DEFAULT_DIGIT_WIDTH, TRAVEL_INFO_DEFAULT_HEIGHT } from '../../constants/DefaultValues';
import Tooltip from '../SharedComponents/Tooltip/Tooltip';
import FormInputGlobal from '../Form/InputGlobal';
import { TRAVEL_INFO_COLORS, TRAVEL_INFO_FONT_FAMILIES, TRAVEL_INFO_FONT_SIZES } from '../../constants/FormOptions';
import { FormProvider } from '../Form/FormProvider';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

const TravelInfoProperties = ({ selectedItem, onSelectedItemUpdated }) => {
    const { t } = useTranslation(['common', 'editVisual']);
    const selectedItemFontFamily = selectedItem.source.split("\\").pop().split("_")[0];
    const selectedItemFontSize = selectedItem.source.split("\\").pop().split("_")[1];
    const selectedItemColor = selectedItem.source.split("\\").pop().split("_")[2].replace(".sta", "");

    function handleValueChanged(e) {
        let value = e.target.value;
        let name = e.target.name;
        
        value = e.target.value.replace(/^0+/, ""); 
        value = e.target.value.length > 0 ? e.target.value : 0;
        value = parseInt(value);
        if (name === "animation_speed" || name === "transition_speed") {
            if (value > 100) {
                value = 100;
                e.target.value = 100;
            }
        }
        
        selectedItem[name] = value;
        onSelectedItemUpdated(selectedItem);
    }

    function handlePropertyChanged(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (name === "font-size" || name === "color" || name === "font-family") {
            updatedSelectedItemSourceAndData(name, value);
        } else {
            selectedItem[name] = value;
        }
        if (name === "arrow_position" || name === "font-size" || name === "digit") {
            const fontRatio = selectedItem.source
                .replace(DEFAULT_TRAVEL_INFO_PATH, "")
                .split("_")[1] / TRAVEL_INFO_DEFAULT_HEIGHT;
            selectedItem.width = selectedItem.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN
                ? (selectedItem.img.width * (selectedItem.digit * 1/3)) * fontRatio
                : (selectedItem.img.width * (selectedItem.digit * 1/3) + selectedItem.arrowImg.width ) * fontRatio;
            selectedItem.height = selectedItem.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN
                ? (selectedItem.img.height + selectedItem.arrowImg.height) * fontRatio
                : selectedItem.img.height * fontRatio;
        }
        onSelectedItemUpdated(selectedItem);
    }

    function updatedSelectedItemSourceAndData(name, value) {
        const fileName = selectedItem.source.split("\\").pop();
        const fileNameWithoutExtension = fileName.replace(".sta", "");
        const parts = fileNameWithoutExtension.split("_");
        let fontFamily = parts[0];
        let fontSize = parts[1];
        let color = parts[2];
        switch (name) {
            case "font-family":
                fontFamily = value;
                break;
            case "font-size":
                fontSize = value;
                const fontRatio = value / TRAVEL_INFO_DEFAULT_HEIGHT; // DOUBLON
                selectedItem.width = selectedItem.arrow_position === TRAVEL_INFO_ARROW_POSITION.DOWN
                    ? selectedItem.digit * TRAVEL_INFO_DEFAULT_DIGIT_WIDTH * fontRatio
                    : (selectedItem.digit + 1) * TRAVEL_INFO_DEFAULT_DIGIT_WIDTH * fontRatio;
                selectedItem.height = TRAVEL_INFO_DEFAULT_HEIGHT * fontRatio;
                break;
            case "color":
                color = value;
                break;
            default:
                break;
        }
        const newFileName = `${fontFamily}_${fontSize}_${color}.sta`;
        selectedItem.source = `${DEFAULT_TRAVEL_INFO_PATH}${fontSize}\\${color}\\${newFileName}`;
        selectedItem.data = `/img/travel_info/${color}.png`;
    }

    return (
        <FormProvider initialValues={selectedItem} onSubmit={(e) => { }}>
            <div id="panel-content" className="travel-info-properties">
                <div id="item-content">
                    <div className="panel-banner">
                        <h3 data-testid="properties-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_PROPERTIES, { category: t(TRANSLATION_KEYS.EDIT_VISUAL_POSITION_INDICATOR) }).toUpperCase()}</h3>
                    </div>
                    <div className="property-options">
                        <h4 className="property-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_CUSTOM_POSITION_INDICATOR)}</h4>
                        <SelectGlobal
                            name="digit"
                            label={t(TRANSLATION_KEYS.EDIT_VISUAL_DIGITS_LABEL)}
                            value={selectedItem.digit}
                            onChange={handlePropertyChanged}
                            options={["1", "2", "3"]}
                        />
                        <div className="form-group-input">
                            <label className='titreInput' htmlFor="font">{t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_FAMILY_LABEL)}</label>
                            <select id="font" value={selectedItemFontFamily} className='select-control' onChange={handlePropertyChanged}>
                                {TRAVEL_INFO_FONT_FAMILIES.map((font, index) => (
                                    <option key={index} value={font}>{font}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group-input">
                            <label className='titreInput' htmlFor="font-size">{t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_SIZE_LABEL)}</label>
                            <select
                                id="font-size"
                                value={selectedItemFontSize}
                                name={"font-size"}
                                className='select-control'
                                onChange={handlePropertyChanged}
                            >
                                {TRAVEL_INFO_FONT_SIZES.map((size, index) => (
                                    <option key={index} value={size}>{size}</option>
                                ))}
                            </select>
                        </div>
                        <div className="form-group-input">
                            <label className="titreInput" htmlFor="color">{t(TRANSLATION_KEYS.EDIT_VISUAL_COLOR_LABEL)}</label>
                            <select
                                id="color"
                                value={selectedItemColor}
                                name='color'
                                className='select-control'
                                onChange={handlePropertyChanged}
                            >
                                {TRAVEL_INFO_COLORS.map((option) => (
                                    <option key={option.value} value={option.value}>{t(option.text)}</option>
                                ))}
                            </select>
                        </div>
                        <SelectGlobal
                            name="arrow_position"
                            label={t(TRANSLATION_KEYS.EDIT_VISUAL_ARROW_POSITION)}
                            value={selectedItem.position}
                            onChange={handlePropertyChanged}
                            optionsWithValue={[
                                { value: TRAVEL_INFO_ARROW_POSITION.DOWN, text: t(TRANSLATION_KEYS.EDIT_VISUAL_DOWN) },
                                { value: TRAVEL_INFO_ARROW_POSITION.LEFT, text: t(TRANSLATION_KEYS.EDIT_VISUAL_LEFT) },
                                { value: TRAVEL_INFO_ARROW_POSITION.RIGHT, text: t(TRANSLATION_KEYS.EDIT_VISUAL_RIGHT) },
                            ]}
                        />
                        <h4 className="property-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_OPTIONS)}</h4>
                        <Tooltip text={t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_POSITION_INFO)} >
                            <h5>{t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_POSITION)}</h5>
                        </Tooltip>
                        <div className="property-group">
                            <FormInputGlobal
                                type="number"
                                name="x_value"
                                min={0}
                                max={1920}
                                validators={[]}
                                label={"X"}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                            <FormInputGlobal
                                type="number"
                                name="y_value"
                                validators={[]}
                                min={0}
                                max={1920}
                                label={"Y"}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

export default TravelInfoProperties;
