import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { API } from "aws-amplify";
import {
  getHLSStreamSessionURLStorm,
  getEmergencyInfoStorm,
} from "../../../graphql/queries";
import { MAX_LENGTH } from "../../../constants/DataValidation";
import { StatusCodes } from "../../../constants/StatusCodes";
//eslint-disable-next-line import/no-webpack-loader-syntax
import videojs from "!video.js";
import "video.js/dist/video-js.css";
import "./ChatRoom.css";
import Layout from "../../Layout/Layout";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../constants/Loader";
import Conversation from "./Conversation";
import BackButton from "../../../components/Buttons/BackButton";
import DetailsButton from "../../../components/Buttons/DetailsButton";
import arrow from "../../../img/arrow-next-green.svg";
import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";

const Chatroom = () => {
  const { t } = useTranslation(['common', 'chatroom']);
  const { channel } = useParams();
  const [socket, setSocket] = useState(null);
  const [message, setMessage] = useState("");
  const [hls_url, set_hls_url] = useState(null);
  const playerRef = useRef();
  const [videoInitialized, setVideoInitialized] = useState(false);
  const [showDetails, setShowDetails] = useState(true);
  const [chatroomInfo, setChatroomInfo] = useState();
  const [conversation, setConversation] = useState([]);
  const [displayedIndex, setDisplayedIndex] = useState(1);
  const [totalResponders, setTotalResponders] = useState(0);
  const [displayedResponders, setDisplayedResponders] = useState([]);
  const pingIntervalRef = useRef();

  const WSS_URL_ENV = process.env.REACT_APP_WSS_URL_ENV;

  const getChatroomInfo = async (channel) => {
    try {
      const result = await API.graphql({
        query: getEmergencyInfoStorm,
        variables: { streamName: channel },
        authMode: "API_KEY",
      });
      console.log(result);
      const info = JSON.parse(result.data.getEmergencyInfoStorm.body);
      if (Object.keys(info.Address).length > 0) {
        const address = info.Address;
        info.Address = `${address.Street1}, ${address.City}, ${address.Province} ${address.PostalCode}, ${address.Country}`;
      }
      setChatroomInfo(info);
      setTotalResponders(info.ContractorFirstResponder.length);
    } catch (error) {
      console.error("Error fetching chatroom information");
    }
  };

  useEffect(() => {
    if (chatroomInfo) {
      const numbersOfResp = chatroomInfo.ContractorFirstResponder.length;
      if (numbersOfResp < 3) {
        setDisplayedResponders(
          chatroomInfo.ContractorFirstResponder.map((_, index) => index)
        );
      } else {
        setDisplayedResponders([
          (displayedIndex - 1 + numbersOfResp) % numbersOfResp,
          displayedIndex,
          (displayedIndex + 1) % numbersOfResp,
        ]);
      }
    }
  }, [displayedIndex, chatroomInfo]);

  useEffect(() => {
    const newSocket = new WebSocket(WSS_URL_ENV);
    setSocket(newSocket);

    return () => {
      if (newSocket) {
        newSocket.close();
      }
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    if (!socket) return;

    socket.onopen = () => {
      console.log("WebSocket connection opened");
      sendMessage("ping");

      // Send "ping" every 5 minutes
      pingIntervalRef.current = setInterval(() => {
        sendMessage("ping");
      }, 5 * 60 * 1000);

      setMessage("");
    };

    socket.onmessage = (e) => {
      var body = JSON.parse(e.data);

      try {
        if (body.channel_name === channel) {
          setConversation((prevConversation) => [
            ...prevConversation,
            {
              sender: t(TRANSLATION_KEYS.CHATROOM_RESPONSE_OF_PASSENGER),
              message: body.text,
            },
          ]);
        }
      } catch (e) {
        console.error("WebSocket error: onmessage");
      }
    };

    socket.onerror = (error) => {
      console.error("WebSocket error");
    };

    socket.onclose = () => {
      console.log("WebSocket connection closed");
      if (pingIntervalRef.current) {
        clearInterval(pingIntervalRef.current);
      }
    };

    getChatroomInfo(channel);
    getHLSURL(channel);
  }, [channel, socket]);

  useEffect(() => {
    if (hls_url && !videoInitialized) {
      initializeVideoPlayer();
      setVideoInitialized(true);
    }
  }, [hls_url, videoInitialized]);

  const initializeVideoPlayer = () => {
    console.log(hls_url);
    const playerOptions = {
      playsinline: true,
      autoplay: true,
      muted: true,
    };
    const player = videojs(playerRef.current, playerOptions);

    player.src({
      type: "application/x-mpegURL",
      src: hls_url,
    });

    player.ready(() => {
      player.play();
    });

    return () => {
      player.dispose();
    };
  };

  const getHLSURL = async (streamName) => {
    try {
      const result = await API.graphql({
        query: getHLSStreamSessionURLStorm,
        variables: { StreamName: streamName },
        authMode: "API_KEY",
      });

      let data = result.data.getHLSStreamSessionURLStorm;

      if (data.statusCode === StatusCodes.OK) {
        let url = JSON.parse(result.data.getHLSStreamSessionURLStorm.body);
        set_hls_url(url.HLSStreamingSessionURL);
      }
    } catch (error) {
      console.error("Error fetching HLS stream URL");
    }
  };

  const sendMessage = (messageText) => {
    if (socket && socket.readyState === WebSocket.OPEN) {
      const messageSend = {
        type: "message",
        channel_name: channel,
        text: messageText,
      };
      socket.send(JSON.stringify(messageSend), channel);
      // setMessage((prevMessages) => [...prevMessages, messageSend]); // REMOVE
    }
  };

  const handleSendMessage = (event) => {
    event.preventDefault();
    if (message) {
      sendMessage(message);
      setMessage("");
      setConversation((prevConversation) => [
        ...prevConversation,
        {
          sender: t(TRANSLATION_KEYS.CHATROOM_TEXT_TO_PASSENGER),
          message: message,
        },
      ]);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      const isTextAreaFocused = document.activeElement.tagName === "TEXTAREA";
      if (event.key === "Enter" && isTextAreaFocused) {
        event.preventDefault();
        handleSendMessage(event);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, [handleSendMessage]);

  const handleChange = (event) => {
    if (event.target.value.length <= MAX_LENGTH.CHATROOM_MESSAGE) {
      setMessage(event.target.value);
    }
  };

  return (
    <Layout
      showFooter={true}
      elevatorID={channel}
    >
      <div id="chatroom-header">
        <BackButton text={t(TRANSLATION_KEYS.BACK)} />
      </div>
      <div id="chatroom-container">
        <div id="chatroom-left">
          <h1 className="chatroom-title">{t(TRANSLATION_KEYS.CHATROOM_TITLE)}</h1>
          {hls_url ? (
            <div id="video-window">
              <video-js ref={playerRef} className="video-js" />
            </div>
          ) : (
            <Loader />
          )}
        </div>
        <div id="chatroom-right">
          <div className="chatroom-title">
            <DetailsButton opened={true} onToggle={() => setShowDetails(!showDetails)} />
            <h1>{t(TRANSLATION_KEYS.CHATROOM_DETAILS)}</h1>
          </div>
          {showDetails &&
            (chatroomInfo ? (
              <div>
                <h2 className="chatroom-subtitle">{t(TRANSLATION_KEYS.CHATROOM_BUILDING)}</h2>
                <div className="details">
                  <div className="info">
                    <p>{t(TRANSLATION_KEYS.CHATROOM_BUILDING_NAME)}</p>
                    <p>{chatroomInfo.Building.Name}</p>
                  </div>
                  <div className="info">
                    <p>{t(TRANSLATION_KEYS.CHATROOM_BUILDING_ADDRESS)}</p>
                    <p id="address">
                      {Object.keys(chatroomInfo.Address).length > 0 &&
                        (chatroomInfo.Address.length >
                        MAX_LENGTH.CHATROOM_BUILDING_ADDRESS
                          ? `${chatroomInfo.Address.substring(
                              0,
                              MAX_LENGTH.CHATROOM_BUILDING_ADDRESS
                            )}...`
                          : chatroomInfo.Address)}
                    </p>
                  </div>
                </div>
                <h2 className="chatroom-subtitle">{t(TRANSLATION_KEYS.CHATROOM_ELEVATOR)}</h2>
                <div className="details">
                  <div className="info">
                    <p>{t(TRANSLATION_KEYS.CHATROOM_ELEVATOR_ID)}</p>
                    <p>{channel}</p>
                  </div>
                  <div className="info">
                    <p>{t(TRANSLATION_KEYS.CHATROOM_ELEVATOR_NAME)}</p>
                    <p>{chatroomInfo.Devices.Name}</p>
                  </div>
                </div>
                <h2 className="chatroom-subtitle">{t(TRANSLATION_KEYS.CHATROOM_FIRST_RESPONDERS)}</h2>
                <p>
                  {t(TRANSLATION_KEYS.CHATROOM_RESPONDERS_ON_DUTY, {count: totalResponders})}
                </p>
                <div id="first-responders-carousel">
                  {totalResponders > 3 && (
                    <button
                      onClick={() =>
                        setDisplayedIndex(
                          (displayedIndex - 1 + totalResponders) %
                            totalResponders
                        )
                      }
                      className="responders-button"
                    >
                      <img src={arrow} alt="previous-arrow" />
                    </button>
                  )}
                  {chatroomInfo &&
                    displayedResponders.map((index) => (
                      <div key={index} className="first-responder">
                        <p>
                          (
                          {
                            chatroomInfo.ContractorFirstResponder[index]
                              .Priority
                          }
                          ){" "}
                          {chatroomInfo.ContractorFirstResponder[index].Name
                            .length > MAX_LENGTH.CHATROOM_RESPONDER_NAME
                            ? `${chatroomInfo.ContractorFirstResponder[
                                index
                              ].Name.substring(
                                0,
                                MAX_LENGTH.CHATROOM_RESPONDER_NAME
                              )}...`
                            : chatroomInfo.ContractorFirstResponder[index].Name}
                        </p>
                        <p>
                          {chatroomInfo.ContractorFirstResponder[index].Number}
                        </p>
                      </div>
                    ))}
                  {totalResponders > 3 && (
                    <button
                      onClick={() =>
                        setDisplayedIndex(
                          (displayedIndex + 1) % totalResponders
                        )
                      }
                      className="responders-button"
                    >
                      <img src={arrow} alt="next-arrow" />
                    </button>
                  )}
                </div>
              </div>
            ) : (
              <Loader />
            ))}
          <h1 className="chatroom-title">
            {t(TRANSLATION_KEYS.CHATROOM_TYPE_TO_COMMUNICATE)}
          </h1>
          <div id="communication" className={!showDetails ? "wide" : ""}>
            <form onSubmit={handleSendMessage}>
              <textarea maxLength={MAX_LENGTH.CHATROOM_MESSAGE} rows={3} value={message} onChange={handleChange} />
              <Button type="submit" text={t(TRANSLATION_KEYS.SEND)} />
            </form>
            <Conversation conversation={conversation} />
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Chatroom;
