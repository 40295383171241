import React from 'react';
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import Button from '../Buttons/Button';
import './css/Footer.css';
import linkedinLogo from '../../img/linkedin-logo.svg';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

const Footer = ({logoutButton, elevatorID }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  console.log(elevatorID);

  const handleSignOut = async () => {
    try {
      await Auth.signOut();
      sessionStorage.removeItem("postLoginRedirect"); // Reset the flag on sign out
      navigate("/");
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  };

  return (
    <footer id='footer'>
        <div id='footer-left'>
          <img
            src={require("../../img/logo-vidatech-final-couleur-240pk-1@1x.png")}
            alt="Vidatech Logo"
          />
          <div id="about-info">
            <div>
              <h2>{t(TRANSLATION_KEYS.SIDEBAR_CONTACT)}</h2>
              <p>1 (418) 476-4802</p>
              <p>info@vidatech.ca</p>
              <div>
                <a href="https://vidatechelevator.com/" rel="noreferrer" target="_blank">
                  vidatechelevator.com
                </a>
              </div>
            </div>
            <a
              href="https://www.linkedin.com/company/vidatech/"
              rel="noreferrer"
              target="_blank"
            >
              <img
                src={linkedinLogo}
                alt="linkedin logo"
              />
            </a>
          </div>
          { elevatorID && 
          <div id='elevator-id'>
            <p>VIEW IN THE ELEVATOR ID {elevatorID}</p>
          </div>
        }
        </div>
        {!elevatorID &&
          <div id='footer-right'>
            <Button text={t(TRANSLATION_KEYS.SIDEBAR_LOGOUT)} onClick={handleSignOut}/>
          </div>
        }
    </footer>
  );
};

export default Footer;
