import { TRANSLATION_KEYS } from "../../../constants/TranslationKeys";
import { useTranslation } from "react-i18next";
import Button from "../../Buttons/Button";
import Modal from "../../Modal/Modal";
import "./ConfirmModal.css";

const ConfirmModal = ({ isOpen, onConfirm, onCancel} ) => {
    const { t } = useTranslation();

    return (
        isOpen && 
            <div className="confirm-modal">
                <Modal
                    isOpen={true}
                    onClose={onCancel}
                >
                    <>
                        <img
                            src={require("../../../img/icon-material-warning@1x.png")}
                            alt="modal"
                        />
                        <p>{t(TRANSLATION_KEYS.CLOSE_WITHOUT_SAVING)}</p>
                        <div className="confirm-modal-buttons">
                            <Button text={t(TRANSLATION_KEYS.YES)} onClick={onConfirm}>Yes</Button>
                            <Button text={t(TRANSLATION_KEYS.NO)} onClick={onCancel}>No</Button>
                        </div>
                    </>
                </Modal>
            </div>
    )
};

export default ConfirmModal;