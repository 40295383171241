// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const PaymentStatus = {
  "SUCCEEDED": "SUCCEEDED",
  "INCOMPLETE": "INCOMPLETE",
  "FAILED": "FAILED",
  "UNCAPTURED": "UNCAPTURED",
  "CANCELED": "CANCELED",
  "REFUNDED": "REFUNDED"
};

const ForceUpdateOption = {
  "BFG": "BFG",
  "FIRMWARE": "FIRMWARE"
};

const Type = {
  "PUBLIC": "PUBLIC",
  "PRIVATE": "PRIVATE"
};

const SipOwner = {
  "VIDATECH": "VIDATECH",
  "CUSTOM": "CUSTOM"
};

const StreamingStatus = {
  "RUNNING": "RUNNING",
  "PENDING": "PENDING",
  "WAITING": "WAITING"
};

const Status = {
  "ENABLED": "ENABLED",
  "DISABLED": "DISABLED",
  "BANNED": "BANNED"
};

const ConfirmationStatus = {
  "CONFIRMED": "CONFIRMED",
  "PENDING": "PENDING",
  "REJECTED": "REJECTED"
};

const Resolution = {
  "R_800X480": "R_800x480",
  "R_1024X600": "R_1024x600",
  "R_1024X768": "R_1024x768",
  "R_1280X800": "R_1280x800",
  "R_1920X1080": "R_1920x1080",
  "R_NONE": "R_None"
};

const ScreenOrientation = {
  "LANDSCAPE": "LANDSCAPE",
  "PORTRAIT": "PORTRAIT"
};

const RepeatEnum = {
  "NEVER": "NEVER",
  "EVERYDAY": "EVERYDAY",
  "EVERYWEEK": "EVERYWEEK",
  "EVERYMONTH": "EVERYMONTH",
  "EVERYYEAR": "EVERYYEAR"
};

const ModelSortDirection = {
  "ASC": "ASC",
  "DESC": "DESC"
};

const ModelAttributeTypes = {
  "BINARY": "binary",
  "BINARY_SET": "binarySet",
  "BOOL": "bool",
  "LIST": "list",
  "MAP": "map",
  "NUMBER": "number",
  "NUMBER_SET": "numberSet",
  "STRING": "string",
  "STRING_SET": "stringSet",
  "NULL": "_null"
};

const { Customer, CustomerProjects, Gstreamer, MikrotikClients, StripeManagement, BFGVersion, PingBuilding, Callees, RingGroup, Address, ContractorFirstResponder, Buildings, Owners, Contractors, Models, Devices, SIPAccount, DeviceInfoRuntime, ModelContractorFirstResponderConnection, ModelBuildingsConnection, ModelModelsConnection, ModelDevicesConnection, PingSupervisor, Body, Body_Key, PeerUsername_Key, ModelOwnersConnection, ModelContractorsConnection } = initSchema(schema);

export {
  Customer,
  CustomerProjects,
  Gstreamer,
  MikrotikClients,
  StripeManagement,
  BFGVersion,
  PingBuilding,
  Callees,
  RingGroup,
  Address,
  ContractorFirstResponder,
  Buildings,
  Owners,
  Contractors,
  Models,
  Devices,
  SIPAccount,
  DeviceInfoRuntime,
  PaymentStatus,
  ForceUpdateOption,
  Type,
  SipOwner,
  StreamingStatus,
  Status,
  ConfirmationStatus,
  Resolution,
  ScreenOrientation,
  RepeatEnum,
  ModelSortDirection,
  ModelAttributeTypes,
  ModelContractorFirstResponderConnection,
  ModelBuildingsConnection,
  ModelModelsConnection,
  ModelDevicesConnection,
  PingSupervisor,
  Body,
  Body_Key,
  PeerUsername_Key,
  ModelOwnersConnection,
  ModelContractorsConnection
};