import React from "react";
import { useTranslation } from "react-i18next";
import UserManagementMenuItem from "../../components/userManagement/UserManagementMenuItem";
import Loader from "../../constants/Loader";
import { useState, useEffect } from "react";
import { getUsersCognitoWithCustomAtt } from "../../graphql/queries";
import { API, graphqlOperation } from "aws-amplify";
import {
  deleteUserCognito,
  sendUserInvitation,
  updateUserCognito
} from "../../graphql/mutations";
import Modal from "../../components/Modal/Modal";
import UserFormProvider from "../../components/userManagement/UserFormProvider";
import Layout from "../Layout/Layout";
import ItemsTable from "../../components/Table/ItemsTable";
import { USER_HEADERS } from "../../constants/TableHeaders";
import imageSuccess from "../../img/image-success.svg";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const UserManagement = () => {
  const { t } = useTranslation(['common', 'users']);
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [inviteUserModalOpen, setInviteUserModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t(TRANSLATION_KEYS.ERROR));
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [confirmationModalMessage, setConfirmationModalMessage] = useState("");

  useEffect(() => {
    getUsers();
  }, []);
  
  async function getUsers() {
    setIsLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(getUsersCognitoWithCustomAtt)
      );

      if (response.data.getUsersCognitoWithCustomAtt.statusCode !== 200) {
        throw new Error("Error fetching users");
      }

      const users = JSON.parse(response.data.getUsersCognitoWithCustomAtt.body);
      const usersModels = users.map((user) => {
        return  {
          name: user.Name || "",
          email: user.Email,
          role: user.Groups[0]
        }
      });
      usersModels.sort((a, b) => a.name.localeCompare(b.name , undefined, { numeric: true, sensitivity: 'base' }));
      setUsers(usersModels);
    } catch (error) {
      setErrorModalOpen(true);
      setErrorMessage(t(TRANSLATION_KEYS.USERS_ERRORS_FETCH_USERS));
    }
    finally {
      setIsLoading(false);
    }
  }

  const handleCreate = async (user) => {
    try {
      setConfirmationModalOpen(true);
      setInviteUserModalOpen(false);
      setIsLoading(true)
      let result = await API.graphql(graphqlOperation(sendUserInvitation, { input: user }));
      if (result.data.sendUserInvitation.statusCode !== 200) {
        throw new Error("Error inviting user");
      }
      setConfirmationModalMessage(t(TRANSLATION_KEYS.USERS_CONFIRM_INVITATION))
    } catch (error) {
      setErrorModalOpen(true);
      setErrorMessage(t(TRANSLATION_KEYS.USERS_ERRORS_INVITE_USER));
    } finally {
      setIsLoading(false);
    }
  }

  const handleDelete = async (email) => {
    try {
      setIsLoading(true);
      let result = await API.graphql(graphqlOperation(deleteUserCognito, { email: email }));
      if (result.data.deleteUserCognito.statusCode !== 200) {
        throw new Error("User delete failed: ", result.data.deleteUserCognito.body);
      }
      getUsers();
    } catch (error) {
      setErrorModalOpen(true);
      setErrorMessage(t(TRANSLATION_KEYS.USERS_ERRORS_DELETE_USER));
    } finally {
      setIsLoading(false);
    }
  };

  const handleUpdate = async (user) => {
    try {
      setConfirmationModalOpen(true);
      setIsLoading(true);
      const res = await API.graphql(graphqlOperation(updateUserCognito, { input: user }));
      if (res.data.updateUserCognito.statusCode !== 200) {
        throw new Error("User update failed: " + res.data.updateUserCognito.body);
      }
      setConfirmationModalMessage(t(TRANSLATION_KEYS.USERS_UPDATE_CONFIRMATION));
      await getUsers();
    } catch (error) {
      setConfirmationModalOpen(false);
      setErrorMessage(t(TRANSLATION_KEYS.USERS_ERRORS_UPDATE_USER));
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSortBy = (header, sortOrder) => {
    const sortedUsers = [...users].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[USER_HEADERS[header]].localeCompare(b[USER_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' });
      } else {
        return b[USER_HEADERS[header]].localeCompare(a[USER_HEADERS[header]], undefined, { numeric: true, sensitivity: 'base' });
      }
    });
    setUsers(sortedUsers);
  };

  return (
    <Layout showSidebar={true}
            showFooter={false}
            headerText={t(TRANSLATION_KEYS.USERS_HEADER_TITLE)}
            headerButtonText={t(TRANSLATION_KEYS.USERS_INVITE_USER)}
            onHeaderButtonClick={() => setInviteUserModalOpen(true)}>
      {isLoading && !confirmationModalOpen ? <Loader /> :
        <ItemsTable
          headers={USER_HEADERS}
          onSortBy={handleSortBy}
        >
          {users &&
            users.map((item, i) => (
                <UserManagementMenuItem
                  key={item.email}
                  item={item}
                  i={i}
                  onDelete={handleDelete}
                  onUpdate={handleUpdate}
                ></UserManagementMenuItem>
              ))}
        </ItemsTable>
      }
      <div className="error-modal">
        <Modal isOpen={errorModalOpen}
          onClose={() => setErrorModalOpen(false)}>
            <>
              <img src={require("../../img/icon-material-warning@1x.png")} alt="modal" />
              <p data-testid="error-modal-message">{errorMessage}</p>
            </>
        </Modal>
      </div>
      <div className="user-form-modal">
        <Modal isOpen={inviteUserModalOpen}
          headerText={t(TRANSLATION_KEYS.USERS_INVITE_USER)}
          onClose={() => setInviteUserModalOpen(false)}>
            <UserFormProvider onSubmit={handleCreate} />
        </Modal>
      </div>
      <div className="confirmation-modal">
        <Modal
          isOpen={confirmationModalOpen}
          onClose={() => {setConfirmationModalOpen(false); setConfirmationModalMessage("")}}
        >
          {
            isLoading
              ? <Loader />
              : <div>
                <img src={imageSuccess}/>
                <p data-testid="confirmation-modal-message">{confirmationModalMessage}</p>
              </div>
          }
        </Modal>
      </div>
    </Layout>
  );
};
export default UserManagement;
