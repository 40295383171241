import React from "react";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../img/delete-icon.svg";
import "./css/ImageInfo.css";
import Tooltip from "../SharedComponents/Tooltip/Tooltip";
import errorImage from "../../img/error-image.svg";
import { DISPLAY_ITEM_CATEGORY, DISPLAY_XML_ATTRIBUTE } from "../../constants/Fields";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const ImageInfo = ({ image, onImageDeleted, onImageClicked, isSelected }) => {
    const { t } = useTranslation(['common', 'editVisual']);
    let nameComponentclass = "image-name";
    nameComponentclass += image?.unavailable ? " unavailable-item" : "";
    nameComponentclass += isSelected ? " selected-item" : "";

    let nameComponent = onImageClicked ?
        <button
            type="button"
            className={nameComponentclass}
            title={image.name}
        >
            {image.name}
        </button>
        : 
        <p title={image.name} className={nameComponentclass}>{image.name}</p>;

    if (image?.unavailable) {
        nameComponent = 
            <Tooltip warning={true} text={t(TRANSLATION_KEYS.EDIT_VISUAL_IMAGE_MISSING)} >
                {nameComponent}
            </Tooltip>;
    }

    return (
        <div className="image-info">
            <div
                className="image-details"
                onClick={onImageClicked && onImageClicked}
            >
                {onImageClicked &&
                    <div className='drag-icon'>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                }
                <div className="image-preview-container">
                    <img
                        src= {
                            image.unavailable
                            ? errorImage
                            : image.data
                        }
                        alt={`${image.name}`}
                    />
                </div>
                {nameComponent}
            </div>
            <div className="delete-icon-container">
                <button
                    title={t(TRANSLATION_KEYS.DELETE)}
                    type="button"
                    onClick={(e) => onImageDeleted(DISPLAY_ITEM_CATEGORY.IMAGE, image[DISPLAY_XML_ATTRIBUTE.ID])}
                >
                    <img src={deleteIcon} alt={t(TRANSLATION_KEYS.DELETE)}/>
                </button>
            </div>
        </div>
    );
};

export default ImageInfo;
