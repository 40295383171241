import React from "react";
import { useTranslation } from "react-i18next";
import "./css/PopupProductModelFileInfo.css";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const PopupProductsModelFileInfo = (props) => {
  const { t } = useTranslation();
  const item = props.item;

  return (
    <div>
      <div>
        <div className='contenerClass'>
          <div className='col1'>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                {t(TRANSLATION_KEYS.PRODUCTS_PHYSICAL_ADDRESS)}
              </div>  
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.PhysicalAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>{t(TRANSLATION_KEYS.PRODUCTS_IP_ADDRESS)}</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.IpAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                {t(TRANSLATION_KEYS.PRODUCTS_STREAM_IP_ADDRESS)}
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.StreamIpAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>{t(TRANSLATION_KEYS.PRODUCTS_POWER_CYCLES)}</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.PowerCycles}
                </div>
              </div>
            </div>
          </div>
          <div className='col2'>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>{t(TRANSLATION_KEYS.PRODUCTS_NAME)}</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>{item.Name}</div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>{t(TRANSLATION_KEYS.PRODUCTS_MAC_ADDRESS)}</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.MacAddress}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>{t(TRANSLATION_KEYS.PRODUCTS_STREAM_PORT)}</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.StreamPort}
                </div>
              </div>
            </div>
            {/* <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                {t(TRANSLATION_KEYS.PRODUCTS_SIP_ACCOUNT_LINKED)}
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>{}</div>
              </div>
            </div> */}
          </div>
          <div className='col3'>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                {t(TRANSLATION_KEYS.PRODUCTS_SCREEN_RESOLUTION)} 
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.ModelsResolution.slice(2)}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>
                {t(TRANSLATION_KEYS.PRODUCTS_SOFTWARE_VERSION)}
              </div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.FirmwareVersion}
                </div>
              </div>
            </div>
            <div className='product-info'>
              <div className='ip-address sous-titre--14pt'>{t(TRANSLATION_KEYS.PRODUCTS_GROUP_ID)}</div>
              <div className='overlap'>
                <div className='text-1 courant--12pt'>
                  {item.DeviceInfoRuntime.GroupID}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PopupProductsModelFileInfo;
