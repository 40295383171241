import React from "react";
import { FormProvider } from '../Form/FormProvider';
import VisualForm from './VisualForm';
import { DEFAULT_VISUAL_SCREEN_ORIENTATION } from "../../constants/DefaultValues";

const VisualFormProvider = ({ onSubmit }) => {
    const initialValues = {
        name: "",
        resolution: "",
        screenOrientation: DEFAULT_VISUAL_SCREEN_ORIENTATION
    };

    return (
        <FormProvider initialValues={initialValues} onSubmit={onSubmit}>
            <VisualForm onSubmit={onSubmit}/>
        </FormProvider>
    );
};

export default VisualFormProvider;
