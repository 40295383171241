import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { isEmpty } from "lodash";
import classNames from "classnames";
import InputContextForm from "./InputContextForm";
import "./css/FormTextInputFirst.css";
import { FormContextGlobal } from "./FormProvider";

const propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    label: PropTypes.string,
    style: PropTypes.object,
    errors: PropTypes.arrayOf(PropTypes.string),
    onChange: PropTypes.func,
    required: PropTypes.bool,
    validators: PropTypes.arrayOf(PropTypes.func),
    disabledOption: PropTypes.string,
    defaultValue: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.string),
    optionsWithValue: PropTypes.arrayOf(PropTypes.object)
};

const SelectGlobal = ({
    label,
    style,
    name,
    value,
    errors,
    onChange,
    required,
    validators,
    disabledOption,
    defaultValue,
    options,
    optionsWithValue,
    ...restProps
}) => {
    const { t } = useTranslation(['common']);
    const { data, setFieldValue } = useContext(FormContextGlobal)
    const hasError = !isEmpty(errors);

    const handleChange = (e) => {
        setFieldValue(name, e.target.value);
        onChange(e);
    };

    const renderErrors = () => {
        if (!hasError) return null;
        return (
            <ul className='error-messages'>
                {errors.map((errMsg, i) => (
                    <li key={`${name}-error-${i}`} className='error'>
                        {t(errMsg)}
                    </li>
                ))}
            </ul>
        );
    };

    const klass = classNames(
        "form-group-input",
        { "has-error": hasError }
    );

    return (
        <>
            <div className={klass}>
                {label && <label className="titreInput" htmlFor={name}>{label}</label>}
                <select
                    disabled={value === disabledOption ? true : false}
                    style={style}
                    name={name}
                    className="select-control"
                    value={data[name] || defaultValue}
                    onChange={handleChange}
                    {...restProps}>
                    {(disabledOption && value === disabledOption)
                        ? <option key={disabledOption} value={disabledOption} disabled hidden>{disabledOption}</option>
                        :   <>
                                <option value='' disabled hidden>{label}</option>
                                {options && options?.map((text, index) => (
                                    <option key={index} value={text}>{text}</option>
                                ))}
                                {optionsWithValue && optionsWithValue?.map((option) => (
                                    <option key={option.value} value={option.value}>{option.text}</option>
                                ))}
                            </>
                    }
                </select>
                {renderErrors()}
            </div>
        </>
    );
};

SelectGlobal.propTypes = propTypes;

const FormSelectGlobal = InputContextForm(SelectGlobal);

export { SelectGlobal as SelectGlobal };
export default FormSelectGlobal;
