import { COGNITO_GROUPS } from "./DefaultValues";
import { TRANSLATION_KEYS } from "./TranslationKeys";

export const ROLES = [
    {value: COGNITO_GROUPS.COLLABORATOR, text: TRANSLATION_KEYS.USERS_COLLABORATOR},
    {value: COGNITO_GROUPS.DESIGNER, text: TRANSLATION_KEYS.USERS_DESIGNER},
    {value: COGNITO_GROUPS.VIEWER, text: TRANSLATION_KEYS.USERS_VIEWER},
]


export const SCREEN_ORIENTATION = {
    PORTRAIT: "PORTRAIT",
    LANDSCAPE: "LANDSCAPE"
};

export const BUTTON_BEHAVIOURS = [
    {value: "stair_cop", text: "Normal"},
    {value: "stair_cop_rear", text: "Rear"},
];

export const TRAVEL_INFO_FONT_FAMILIES = [
    "Arial",
];

export const TEXT_FONT_FAMILIES = [
    "Arial",
    "Helvetica",
    "Roboto",
    "Times New Roman",
    "Lato-Bold"
];

export const TRAVEL_INFO_FONT_SIZES = [
    "112",
    "160",
    "216",
    "272",
    "328",
    "452",
]

export const TRAVEL_INFO_COLORS = [
    {value: "Amber", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_AMBER]},
    {value: "Black", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_BLACK]},
    {value: "Blue", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_BLUE]},
    {value: "Dark Gray", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_DARK_GRAY]},
    {value: "Green", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_GREEN]},
    {value: "Light Gray", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_LIGHT_GRAY]},
    {value: "Red", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_RED]},
    {value: "White", text: [TRANSLATION_KEYS.EDIT_VISUAL_TRAVEL_INFO_COLORS_WHITE]},
]
