import React, { useEffect, useContext, useState } from 'react'
import { useTranslation } from "react-i18next";
import { API, graphqlOperation } from "aws-amplify";
import { updateUserCognito } from "../../graphql/mutations";
import { getUsersCognitoWithCustomAtt } from "../../graphql/queries";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { Auth } from "@aws-amplify/auth";
import Layout from '../Layout/Layout'
import { FormProvider } from '../../components/Form/FormProvider'
import FormInputGlobal from '../../components/Form/InputGlobal'
import {
    MAX_LENGTH,
    VALIDATOR_FORMATS,
    fieldFormatValidator,
    passwordChangedValidator,
    passwordMatchValidator,
    requiredValidator,
  } from "../../constants/RequiredValidator";
import Button from '../../components/Buttons/Button';
import './Account.css'
import { ROLES } from '../../constants/FormOptions';
import FormSelectGlobal from '../../components/Form/SelectGlobal';
import { FormContextGlobal } from '../../components/Form/FormProvider';
import Loader from '../../constants/Loader';
import Modal from '../../components/Modal/Modal';
import imageSuccess from "../../img/image-success.svg";
import { COGNITO_GROUPS } from '../../constants/DefaultValues';
import Tooltip from '../../components/SharedComponents/Tooltip/Tooltip';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

export default function Account() {
    const { t } = useTranslation(['common', 'account']);
    const { user } = useAuthenticator((context) => [context.user]);
    const [initialValues, setInitialValues] = useState(null)
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
    const [confirmationModalMessage, setConfirmationModalMessage] = useState("");
    const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
    const authorizedGroups = [
        COGNITO_GROUPS.VIDATECH_ADMIN,
        COGNITO_GROUPS.ADMIN,
    ];
    const updateEnabled = groups?.some((group) => authorizedGroups.includes(group));
    
    useEffect(() => {
        if (updateEnabled) {
            fetchUser();
        } else {
            setInitialValues({
                name: '',
                role: '',
                email: '',
                currentPassword: '',
                newPassword: '',
                passwordConfirmation: ''
            })
        }
    }, []);

    async function fetchUser() {
        setIsLoading(true);
        try {
            const response = await API.graphql(
                graphqlOperation(getUsersCognitoWithCustomAtt)
              );

            if (response.data.getUsersCognitoWithCustomAtt.statusCode !== 200) {
                throw new Error("Error fetching users");
            }

            const users = JSON.parse(response.data.getUsersCognitoWithCustomAtt.body);
            const currentUser = users.find((vidatechUsers) => vidatechUsers.Email === user.attributes.email);

            setInitialValues({
                name: currentUser.Name,
                role: currentUser.Groups[0],
                email: currentUser.Email,
                currentPassword: '',
                newPassword: '',
                passwordConfirmation: ''
            })

        } catch (error) {
            setConfirmationModalOpen(true)
            setErrorMessage(t(TRANSLATION_KEYS.ACCOUNT_ERRORS_FETCH_USER));
        } finally {
            setIsLoading(false);
        }
    }

    async function handleSubmit(data) {
        if (!isEqualToInitialValue(data) && updateEnabled) {
            if (!data.currentPassword) {
                updateUser(data);
            } else {
                await updateUserAndPassword(data)
            }
        }

        if (!updateEnabled && data.currentPassword) {
            changePassword(data.currentPassword, data.newPassword);
        }
      };

    const isEqualToInitialValue = (data) => {
        return JSON.stringify(initialValues) === JSON.stringify(data);
    };

    async function updateUserAndPassword(data) {
        try {
            setIsLoading(true);
            setConfirmationModalOpen(true);
            const response = await API.graphql(graphqlOperation(updateUserCognito, { input: {
                name: data.name,
                email: data.email,
                role: data.role
            } }));
            
            if (response.data.updateUserCognito.statusCode !== 200) {
                throw new Error("User update failed: " + response.data.updateUserCognito.body);
            }
            await fetchUser();

            const user = await Auth.currentAuthenticatedUser();
            const authResponse = await Auth.changePassword(user, data.currentPassword, data.newPassword);
            setConfirmationModalMessage(t(TRANSLATION_KEYS.ACCOUNT_UPDATE_CONFIRMATION));
        } catch (err) {
            if (err.message === 'Incorrect username or password') {
                setErrorMessage(t(TRANSLATION_KEYS.ACCOUNT_ERRORS_PASSWORD_INCORRECT));
            } else {
                setErrorMessage(err.message);
            }
        } finally {
            setIsLoading(false);
        }
    }

    async function updateUser(data) {
        try {
            setIsLoading(true);
            setConfirmationModalOpen(true);
            setConfirmationModalMessage(t(TRANSLATION_KEYS.ACCOUNT_UPDATE_SUCCESSFUL));
            const response = await API.graphql(graphqlOperation(updateUserCognito, { input: {
                name: data.name,
                email: data.email,
                role: data.role
            } }));
            
            if (response.data.updateUserCognito.statusCode !== 200) {
                throw new Error("User update failed: " + response.data.updateUserCognito.body);
            }
            await fetchUser();
        } catch (err) {
            setErrorMessage(t(TRANSLATION_KEYS.ACCOUNT_ERRORS_UPDATE_USER));
        } finally {
            setIsLoading(false);
        }
    }

    async function changePassword(oldPassword, newPassword) {
        try {
            setIsLoading(true)
            setConfirmationModalOpen(true);
            const user = await Auth.currentAuthenticatedUser();
            const data = await Auth.changePassword(user, oldPassword, newPassword);
            setConfirmationModalMessage(t(TRANSLATION_KEYS.ACCOUNT_UPDATE_PASSWORD_CONFIRMATION));
        } catch (err) {
            if (err.message === 'Incorrect username or password') {
                setErrorMessage(t(TRANSLATION_KEYS.ACCOUNT_ERRORS_PASSWORD_INCORRECT));
            } else {
                setErrorMessage(err.message);
            }
        } finally {
            setIsLoading(false);
        }
      }

    return (
        <Layout
            headerText={t(TRANSLATION_KEYS.ACCOUNT_HEADER_TITLE)}
            showSidebar={true}
            showFooter={false}
        >
            { !initialValues || isLoading
                ? <Loader />
                : <FormProvider initialValues={initialValues} onSubmit={(event) => handleSubmit(event)}>
                    <AccountForm onSubmit={handleSubmit} updateEnabled={updateEnabled}/>
                  </FormProvider>
            }
            { confirmationModalOpen &&
                <div className="confirmation-modal">
                    <Modal
                        isOpen={confirmationModalOpen}
                        onClose={() => {setConfirmationModalOpen(false); setConfirmationModalMessage(""); setErrorMessage("")}}
                    >
                        {
                        isLoading
                            ? <Loader />
                            : <div>
                            <img src={errorMessage ? require("../../img/icon-material-warning@1x.png") : imageSuccess}/>
                            <p>{errorMessage ? errorMessage : confirmationModalMessage}</p>
                            </div>
                        }
                    </Modal>
                </div>
            }
        </Layout>
    )
}

function AccountForm({onSubmit, updateEnabled}) {
    const { t } = useTranslation(['common', 'account']);
    const { data, errors, registerInput } = useContext(FormContextGlobal);
    const [ validators, setValidators ] = useState({})

    useEffect(() => {
        if(updateEnabled) {
            if (data.newPassword || data.currentPassword || data.passwordConfirmation) {
                setValidators({
                    currentPassword: [requiredValidator],
                    newPassword: [
                        requiredValidator,
                        (val) => fieldFormatValidator(val, VALIDATOR_FORMATS.USER_PASSWORD),
                        (val) => passwordChangedValidator(val, data.currentPassword, t)
                    ],
                    passwordConfirmation: [requiredValidator, (val) => passwordMatchValidator(val, data.newPassword, t)]
                })
            } else {
                errors.currentPassword = []
                errors.newPassword = []
                errors.passwordConfirmation = []
                setValidators({
                    name: [requiredValidator],
                })
            }
        } else {
            setValidators({
                currentPassword: [requiredValidator],
                newPassword: [
                    requiredValidator,
                    (val) => fieldFormatValidator(val, VALIDATOR_FORMATS.USER_PASSWORD),
                    (val) => passwordChangedValidator(val, data.currentPassword, t)
                ],
                passwordConfirmation: [requiredValidator, (val) => passwordMatchValidator(val, data.newPassword, t)]
            })
        }
    }, [data])

    const handleSubmit = (e) => {
        e.preventDefault();
        let errorsBeforeSubmit = [];

        for (const validator in validators) {
          errorsBeforeSubmit.push(registerInput(validator, validators[validator])(data[validator]));
        }

        let isValid = true;
        for (const field in errorsBeforeSubmit) {
          if (errorsBeforeSubmit[field].length > 0) {
            isValid = false;
            break;
          }
        }

        if (isValid) {
          onSubmit(data);
        }
    };

    return (
        <form
            noValidate
            id="account-form"
            onSubmit={(event) => handleSubmit(event)}
            data-testid="account-form"
        >
            {
                updateEnabled &&
                <>
                    <h2 className='underlined-title'>{t(TRANSLATION_KEYS.ACCOUNT_INFORMATION)}</h2>
                    <FormInputGlobal
                        type="text"
                        name="name"
                        placeholder={t(TRANSLATION_KEYS.ACCOUNT_NAME_PLACEHOLDER)}
                        required={true}
                        errors={errors.name}
                        validators={validators.name}
                        maxLength={MAX_LENGTH.TEXT}
                        label={t(TRANSLATION_KEYS.ACCOUNT_NAME)}
                    />
                    <FormSelectGlobal
                        disabledOption={data.role === "Admin" ? "Admin" : data.role === "VidatechAdmin" ? "VidatechAdmin" : null}
                        label={t(TRANSLATION_KEYS.ACCOUNT_SELECT_ROLE)}
                        name="role"
                        errors={errors.role}
                        validators={validators.role}
                        optionsWithValues={ROLES}
                    />
                </>
            }
            <h2 className='underlined-title'>{t(TRANSLATION_KEYS.ACCOUNT_CHANGE_PASSWORD)}</h2>
            <FormInputGlobal
                type="password"
                name="currentPassword"
                placeholder={t(TRANSLATION_KEYS.ACCOUNT_CURRENT_PASSWORD_PLACEHOLDER)}
                required={true}
                errors={errors.currentPassword}
                validators={validators.currentPassword}
                maxLength={MAX_LENGTH.TEXT}
                label={t(TRANSLATION_KEYS.ACCOUNT_CURRENT_PASSWORD)}
            />
            <div style={{display: "flex", alignItems: "center"}}>
                <FormInputGlobal
                    type="password"
                    name="newPassword"
                    placeholder={t(TRANSLATION_KEYS.ACCOUNT_NEW_PASSWORD_PLACEHOLDER)}
                    required={true}
                    errors={errors.newPassword}
                    validators={validators.newPassword}
                    maxLength={MAX_LENGTH.TEXT}
                    label={t(TRANSLATION_KEYS.ACCOUNT_NEW_PASSWORD)}
                />
                <Tooltip
                    text={t(TRANSLATION_KEYS.ACCOUNT_PASSWORD_REQUIREMENTS)}
                    messages={[
                        t(TRANSLATION_KEYS.ACCOUNT_PASSWORD_REQUIREMENTS_LENGTH),
                        t(TRANSLATION_KEYS.ACCOUNT_PASSWORD_REQUIREMENTS_NUMBER),
                        t(TRANSLATION_KEYS.ACCOUNT_PASSWORD_REQUIREMENTS_SPECIAL),
                        t(TRANSLATION_KEYS.ACCOUNT_PASSWORD_REQUIREMENTS_UPPERCASE),
                        t(TRANSLATION_KEYS.ACCOUNT_PASSWORD_REQUIREMENTS_LOWERCASE),
                    ]}
                />
            </div>
            <FormInputGlobal
                type="password"
                name="passwordConfirmation"
                placeholder={t(TRANSLATION_KEYS.ACCOUNT_CONFIRM_PASSWORD_PLACEHOLDER)}
                required={true}
                errors={errors.passwordConfirmation}
                validators={validators.passwordConfirmation}
                maxLength={MAX_LENGTH.TEXT}
                label={t(TRANSLATION_KEYS.ACCOUNT_CONFIRM_PASSWORD)}
            />
            <Button onClick={handleSubmit} text={t(TRANSLATION_KEYS.SAVE)} type="submit" />
        </form>
    )
}