import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import "./css/BuildingMenuItem.css";
import { useNavigate } from "react-router-dom";
import Modal from "../Modal/Modal";
import Action from "../Action/Action";
import BuildingFormProvider from "./BuildingFormProvider";
import { API, graphqlOperation } from "aws-amplify";
import { FilesName } from "../../constants/FilesName";
import { getBuildingsBFGCustom, exportBIDFile } from "../../graphql/queries";
import { StatusCodes } from "../../constants/StatusCodes";
import Form from "../Form/FormContext";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const BuildingMenuItem = (props) => {
  const item = props.item;
  //TODO: flatten item and sort properties to map over them to display the columns
  const navigate = useNavigate();
  const [EditModalOpen, setEditModalOpen] = useState(false);
  const [EditContractorModalOpen, setEditContractorModalOpen] = useState(false);
  const [DeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [modelsID, setSelectedModelsId] = useState("");

  const formInitialValues = {
    modelsID: modelsID,
  };

  const handleModelChange = (event) => {
    setSelectedModelsId(event.target.value);
  };

  const handleItemClick = (event) => {
    if (
      !event.target.closest('.action-cell')
    ) {
      navigate(`/buildings/${item.id}/products`);
    }
  };

  const handleDelete = () => {
    props.handleDelete(item.id);
  };

  const handleUpdate = async (data) => {
    data.BuildingId = item.id;
    props.handleUpdate(data);
  };

  function downloadResponseAsJson(response, fileName) {
    const jsonStr = JSON.stringify(response, null, 4);
    download(jsonStr, fileName, "text/plain");
  }

  function download(content, fileName, contentType) {
    var a = document.createElement("a");
    var file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  function downloadFileFromUrl(url, fileName) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((error) =>
        console.error("Error downloading file from URL:", error)
      );
  }

  const handleDownloadBuildingIdentity = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(exportBIDFile, {
          id: item.id,
        })
      );
      const data = response.data?.exportBIDFile;
      if (data.statusCode === StatusCodes.OK) {
        const fileUrl = data.body;
        const fileName = FilesName.BUILDINGBID;
        downloadFileFromUrl(fileUrl, fileName);
      }
    } catch (error) {
      console.error("Error downloading bid file:", error);
    }
  };

  const handleDownloadConfiguration = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getBuildingsBFGCustom, {
          id: item.id,
        })
      );
      downloadResponseAsJson(response, FilesName.BUILDINGBFG);
    } catch (error) {
      console.error("Error download bfg file:", error);
    }
  };

  const handleEditContractor = (data) => {
    //TODO: implement edit contractor
  };

  //TODO: create component for edit contractor
  const editContractorComponent = (
    <Form
      className='editContractorForm'
      onSubmit={(data) => handleEditContractor(data)}
      initialValues={formInitialValues}
    >
      <div className='Contractor'>Contractor building *</div>
      <select
        className='selectContractor'
        value={modelsID}
        onChange={handleModelChange}
        requiredValidator
      >
        <option value=''>Select a contractor</option>
      </select>
      <div className='editContractorSubmit'>
        <button className='editContractorSubmitButton' type='submit'>
          Create
        </button>
      </div>
    </Form>
  );

  const actionOptions = [
    {
      text: TRANSLATION_KEYS.EDIT,
      img: require("../../img/picto-edit-1.png"),
      onClickOption: () => setEditModalOpen(true),
    },
    // {
    //   text: "Change contractor",
    //   img: require("../../img/picto-edit-1.png"),
    //   onClickOption: () => setEditContractorModalOpen(true),
    // },
    {
      text: TRANSLATION_KEYS.BUILDING_DOWNLOAD_BID,
      img: require("../../img/picto-download-1.png"),
      onClickOption: handleDownloadBuildingIdentity,
    },
    {
      text: TRANSLATION_KEYS.BUILDING_DOWNLOAD_BFG,
      img: require("../../img/picto-download-1.png"),
      onClickOption: handleDownloadConfiguration,
    }
  ];

  if (props.isDeleteCreateAuthorized) {
    actionOptions.push({
        text: TRANSLATION_KEYS.DELETE,
        img: require("../../img/picto-delete.png"),
        onClickOption: () => setDeleteModalOpen(true),
      })
  }

  return (
    <>
      <tr
        id={item.id}
        key={props.key}
        className='building-row'
        onClick={handleItemClick}
        data-testid={`building-row-${item.Name}`}
      >
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.Name}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.Address}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.Phone}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.City}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>{item.Country}</div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="cell-container">
          <div>
            <div className='table-cell'>
              {item[`Status`] === null ? (
                <div className='status-icon' />
              ) : item[`Status`] === 1 ? (
                <div className='status-icon green-status' />
              ) : (
                <div className='status-icon red-status' />
              )}
            </div>
            <span className="separator"></span>
          </div>
        </td>
        <td className="action-cell">
          <Action actionOptions={actionOptions} />
          <div className='edit-building-modal'>
            <Modal
              headerText={props.t(TRANSLATION_KEYS.BUILDINGS_EDIT_BUILDING, { name: item.Name })}
              isOpen={EditModalOpen}
              onClose={() => setEditModalOpen(false)}>
                <BuildingFormProvider item={item} onSubmit={handleUpdate} />
            </Modal>
          </div>
          <div data-testid="delete-building-modal" className='confirm-modal'>
            <Modal
              isOpen={DeleteModalOpen}
              onClose={() => setDeleteModalOpen(false)}
              buttonText={props.t(TRANSLATION_KEYS.DELETE)}
              buttonClick={handleDelete}>
                <>
                  <img
                    src={require("../../img/icon-material-warning@1x.png")}
                    alt='confirm delete'
                  />
                  <p>{props.t(TRANSLATION_KEYS.BUILDING_CONFIRM_DELETE)}</p>
                </>
            </Modal>
          </div>
          <div className='confirm-modal'>
            <Modal
              isOpen={EditContractorModalOpen}
              onClose={() => setEditContractorModalOpen(false)}>
                {editContractorComponent}
            </Modal>
          </div>
        </td>
      </tr>
    </>
  );
};

export default withTranslation(['buildings', 'common'])(BuildingMenuItem);
