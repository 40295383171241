import { useTheme, Heading } from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { translations } from "@aws-amplify/ui-react";
import { View } from "@aws-amplify/ui-react";
import IdConnect from "../components/IdConnect/IdConnect";
import { TRANSLATION_KEYS } from "./TranslationKeys";
I18n.putVocabularies(translations);

export const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="right" padding={tokens.space.xxl} >
          <IdConnect loginPage={true} />
        </View>
      );
    },
    SignIn: {
      Header() {
        const { t } = useTranslation();
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            {t(TRANSLATION_KEYS.SIGNIN_SIGNIN)}
          </Heading>
        );
      }
    },
    SignUp: {
      Header() {
        const { t } = useTranslation();
        const { tokens } = useTheme();
  
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            {t(TRANSLATION_KEYS.SIGNIN_CREATE_ACCOUNT)}
          </Heading>
        );
      },
    },
    ConfirmSignUp: {
      Header() {
        const { t } = useTranslation();
        const { tokens } = useTheme();
        return (
          <div className="verification-info">
            <Heading
              padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
              level={3}>
                {t(TRANSLATION_KEYS.SIGNIN_CONFIRM_ACCOUNT)}
            </Heading>
          </div>
        );
      },
    },
    SetupTOTP: {
      Header() {
        const { t } = useTranslation();
        const { tokens } = useTheme();
        return (
          <>
            <Heading
              level={3}
            >
              {t(TRANSLATION_KEYS.SIGNIN_MULTI_FACTOR_AUTHENTICATION)}
            </Heading>
            <p>{t(TRANSLATION_KEYS.SIGNIN_SCAN_QR_CODE)}</p>
          </>
        );
      },
    },
    ConfirmSignIn: {
      Header() {
        const { t } = useTranslation();
        const { tokens } = useTheme();
        return (
          <Heading
            level={3}
          >
            {t(TRANSLATION_KEYS.SIGNIN_MULTI_FACTOR_AUTHENTICATION)}
          </Heading>
        );
      },
    },
    ResetPassword: {
      Header() {
        const { t } = useTranslation();
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            {t(TRANSLATION_KEYS.SIGNIN_ENTER_INFO)}
          </Heading>
        );
      },
    },
    ConfirmResetPassword: {
      Header() {
        const { t } = useTranslation();
        const { tokens } = useTheme();
        return (
          <Heading
            padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
            level={3}
          >
            {t(TRANSLATION_KEYS.SIGNIN_ENTER_INFO)}
          </Heading>
        );
      },
    },
  };
  
export const useAuthenticatorFormFields = () => {
  const { t, i18n } = useTranslation();
  I18n.setLanguage(i18n.language);
  
  return {
    signIn: {
      username: {
        placeholder: t(TRANSLATION_KEYS.SIGNIN_EMAIL_PLACEHOLDER),
      },
      password: {
        label: t(TRANSLATION_KEYS.SIGNIN_PASSWORD),
        placeholder: t(TRANSLATION_KEYS.SIGNIN_PASSWORD_PLACEHOLDER),
      },
    },
    signUp: {
      password: {
        label: t(TRANSLATION_KEYS.SIGNIN_PASSWORD),
        placeholder: t(TRANSLATION_KEYS.SIGNIN_PASSWORD_PLACEHOLDER),
        isRequired: false,
        displayOrder: 2,
      },
      confirm_password: {
        label: t(TRANSLATION_KEYS.SIGNIN_CONFIRM_PASSWORD),
        placeholder: t(TRANSLATION_KEYS.SIGNIN_CONFIRM_PASSWORD_PLACEHOLDER),
        displayOrder: 3,
        isRequired: true
      },
    },
    forceNewPassword: {
      password: {
        placeholder: t(TRANSLATION_KEYS.SIGNIN_NEW_PASSWORD_PLACEHOLDER),
      },
    },
    resetPassword: {
      username: {
        placeholder: t(TRANSLATION_KEYS.SIGNIN_EMAIL_PLACEHOLDER),
      },
    },
    confirmResetPassword: {
      confirmation_code: {
        placeholder: t(TRANSLATION_KEYS.SIGNIN_CONFIRMATION_CODE_PLACEHOLDER),
        label: t(TRANSLATION_KEYS.SIGNIN_CONFIRMATION_CODE),
        isRequired: false,
      },
      confirm_password: {
        placeholder: t(TRANSLATION_KEYS.SIGNIN_CONFIRM_PASSWORD_PLACEHOLDER),
        isRequired: true,
      },
    },
    setupTOTP: {
      QR: {
        totpIssuer: "Vidatech",
        totpUsername: "Account",
      },
      confirmation_code: {
        label: t(TRANSLATION_KEYS.SIGNIN_TOTP_CONFIRMATION_CODE),
        placeholder: t(TRANSLATION_KEYS.SIGNIN_CONFIRMATION_CODE_PLACEHOLDER),
        isRequired: true,
      }
    },
    confirmSignIn: {
      confirmation_code: {
        label: t(TRANSLATION_KEYS.SIGNIN_TOTP_CONFIRMATION_CODE),
        placeholder: t(TRANSLATION_KEYS.SIGNIN_CONFIRMATION_CODE_PLACEHOLDER),
        isRequired: true,
      },
    }
  };
};

export const theme = {
    name: "custom-theme",
    tokens: {
      components: {
        Button: {
          primary: {
            backgroundColor: { value: "{colors.green.80}" },
            _hover: {
              backgroundColor: { value: "{colors.green.60}" },
            },
          },
          link: {
            color: { value: "{colors.green.80}" },
            _hover: {
              color: { value: "{colors.green.80}" },
              backgroundColor: { value: "{colors.green.10}" },
            },
          },
        },
        Tabs: {
          borderColor: { value: "{colors.green.80}" },
          item: {
            color: { value: "{colors.green.80}" },
            fontSize: { value: "{fontSizes.xl}" },
            fontWeight: { value: "{fontWeights.normal}" },
  
            _hover: {
              color: { value: "{colors.green.60}" },
            },
            _focus: {
              color: { value: "{colors.white}" },
            },
            _active: {
              color: { value: "{colors.white}" },
              borderColor: { value: "{colors.green.80}" },
              backgroundColor: { value: "{colors.green.80}" },
            },
            _disabled: {
              color: { value: "gray" },
              backgroundColor: { value: "transparent" },
            },
          },
        },
      },
    },
  };