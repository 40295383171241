import React from 'react';
import { useTranslation } from 'react-i18next';
import FormInputGlobal from '../Form/InputGlobal';
import './css/CalleeCardForm.css';
import FormSelectGlobal from '../Form/SelectGlobal';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

const CalleeCardForm = (props) => {
    const { t } = useTranslation();

    function handleChange(event) {
        const { name, value } = event.target;
        const updatedCallee = { ...props.callee, [name]: value };
        props.onUpdate(updatedCallee);
    };
    
    return (
        <div data-testid="callee-card" className='callee-card-form'>
            <div className='drag-icon'>
                <div></div>
                <div></div>
                <div></div>
            </div>
            <div className='priority'>
                <p>{props.priority}</p>
            </div>
            <div className='card-fields'>
                <div>
                    <div className='classInputs'>
                        <FormInputGlobal
                            type='text'
                            name='Number'
                            required={true}
                            maxLength="25"
                            placeholder={t(TRANSLATION_KEYS.PRODUCTS_PHONE_NUMBER_PLACEHOLDER)}
                            label={t(TRANSLATION_KEYS.PRODUCTS_PHONE_NUMBER)}
                            validators={props.validators.Number}
                            tooltipText={t(TRANSLATION_KEYS.PRODUCTS_PHONE_NUMBER_TOOLTIP)}
                            onChange={handleChange} />
                    </div>
                    <div className='classInputs'>
                        <FormInputGlobal
                            type='text'
                            name='LinkEmail'
                            placeholder={t(TRANSLATION_KEYS.PRODUCTS_LINK_EMAIL_PLACEHOLDER)}
                            label={t(TRANSLATION_KEYS.PRODUCTS_LINK_EMAIL)}
                            pattern="\S+@\S+\.\S+"
                            validators={props.validators.LinkEmail}
                            tooltipText={t(TRANSLATION_KEYS.PRODUCTS_LINK_EMAIL_TOOLTIP)}
                            onChange={handleChange} />
                    </div>
                        <div className='classInputs'>
                            <FormInputGlobal
                                type='tel'
                                name='LinkNumber'
                                placeholder={t(TRANSLATION_KEYS.PRODUCTS_PHONE_NUMBER_PLACEHOLDER)}
                                label={t(TRANSLATION_KEYS.PRODUCTS_LINK_NUMBER)}
                                pattern="^\([2-9][0-9]{2}\) [2-9](?!11)[0-9]{2}-\d{4}$"
                                validators={props.validators.LinkNumber}
                                tooltipText={t(TRANSLATION_KEYS.PRODUCTS_LINK_NUMBER_TOOLTIP)}
                                onChange={handleChange} />
                        </div>
                </div>
                <div className='classInputs'>
                    <FormSelectGlobal
                        name='calleesSIPAccountId'
                        label={t(TRANSLATION_KEYS.PRODUCTS_SIP_ACCOUNT)}
                        optionsWithValue={props.sipAccounts.map((sipAccount) => {
                            return {
                                value: sipAccount.id,
                                text: sipAccount.Name
                            };
                        })}
                        onChange={handleChange}
                    />
                </div>
            </div>
            <div title={t(TRANSLATION_KEYS.DELETE)} data-testid="delete-button" className='delete-card' onClick={() => props.onDelete(props.callee.id)}>
                <img src={require("../../img/picto-delete.png")} alt='delete' />
            </div>
        </div>
    );
};

export default CalleeCardForm;
