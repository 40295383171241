import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { API, graphqlOperation } from "aws-amplify";
import {
  calleesByRingGroupID,
  getRingGroup,
  listPageBuildingsByUser,
  listDevicesPageByBuildingsID,
  getSIPAccountStormByDeviceIDCustom
} from "../../graphql/queries";
import { manageCalleesStorm } from "../../graphql/mutations";
import { StatusCodes } from "../../constants/StatusCodes";
import Loader from "../../constants/Loader";
import CalleeForm from "../../components/Callees/CalleeForm";
import { v4 as uuidv4 } from "uuid";
import { useParams } from "react-router-dom";
import Modal from "../../components/Modal/Modal";
import ConfirmModal from "../../components/SharedComponents/ConfirmModal/ConfirmModal";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const Callees = ({deviceId, calleesModalOpen, onClose, headerText}) => {
  const { t } = useTranslation(['products', 'common']);
  const [isLoading, setIsLoading] = useState(true);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState(t(TRANSLATION_KEYS.ERROR));
  const { buildingId } = useParams();
  const [device, setDevice] = useState(null);
  const [sipAccounts, setSipAccounts] = useState([]); // [ { id: string, Name: string } ]
  const [isUnsaved, setIsUnsaved] = useState(false);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [initialValues, setInitialValues] = useState(
    {
      Callees: [
        {
          id: uuidv4(),
          Priority: '1',
          LinkEmail: '',
          LinkNumber: '',
          Number: '',
          RingGroupID: device?.devicesRingGroupId || '',
          calleesSIPAccountId: '',
        },
      ],
      RingGroup: {
        Time: '60',
      }
    }
  );

  useEffect(() => {
    getCalleesAndRingGroupInfo();
  }, []);

  const getCalleesAndRingGroupInfo = async () => {
    setIsLoading(true);
    let editedDevice = device;
    try {
      await getSIPAccounts();
      setIsLoading(true);
      if (!editedDevice || !editedDevice.devicesRingGroupId) {
        editedDevice = await getDevice();
        setDevice(editedDevice);
      }
      if (editedDevice && editedDevice.devicesRingGroupId) {
        const callees = await getCallees(editedDevice.devicesRingGroupId);
        const ringGroup = await getRingGroupInfo(editedDevice.devicesRingGroupId);
        const values = {
          Callees: callees,
          RingGroup: ringGroup,
        }
        if (callees && ringGroup) {
          setInitialValues(values);
        }
      }
    } catch (error) {
      if (!errorModalOpen) {
        setErrorMessage(t(TRANSLATION_KEYS.PRODUCTS_ERRORS_CALLEES_INIT));
        setErrorModalOpen(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const getDevice = async () => {
    try {
      const deviceResponse = await API.graphql(
        graphqlOperation(listDevicesPageByBuildingsID, {
          buildingsID: buildingId,
        })
      );
      const devices = deviceResponse.data.devicesByBuildingsID.items;
      return devices.find(device => device.id === deviceId);
    } catch (error) {
      setErrorMessage(t(TRANSLATION_KEYS.PRODUCTS_ERRORS_FETCH_PRODUCTS));
      setErrorModalOpen(true);
    }
  };

  const getCallees = async (devicesRingGroupId) => {
    try {
      const calleesResponse = await API.graphql(
        graphqlOperation(calleesByRingGroupID, {
          RingGroupID: devicesRingGroupId
        })
      );
      let callees = calleesResponse.data.calleesByRingGroupID.items;
      const calleesModel = callees
        .sort((a, b) => a.Priority - b.Priority)
        .map(callee => {
          return {
            id: callee.id,
            Number: callee.Number,
            LinkNumber: callee.LinkNumber || "",
            LinkEmail: callee.LinkEmail || "",
            Priority: callee.Priority,
            RingGroupID: callee.RingGroupID,
            calleesSIPAccountId: callee.calleesSIPAccountId || "",
          }
        });

      return calleesModel;

    } catch (error) {
      setErrorMessage("Error fetching callees.");
      setErrorModalOpen(true);
    }
  }

  const getRingGroupInfo = async (devicesRingGroupId) => {
    try {
      const RingGroupResponse = await API.graphql(
        graphqlOperation(getRingGroup, {
          id: devicesRingGroupId
        })
      );
      const ringGroup = RingGroupResponse.data.getRingGroup;
      return {
        id: ringGroup.id,
        Name: ringGroup.Name,
        Time: ringGroup.Time,
      }
    } catch (error) {
      setErrorMessage(t(TRANSLATION_KEYS.PRODUCTS_ERRORS_FETCH_RING_GROUP));
      setErrorModalOpen(true);
    }
  };

  const getSIPAccounts = async () => {
    setIsLoading(true);
    try {
      const response = await API.graphql(
        graphqlOperation(getSIPAccountStormByDeviceIDCustom, {
          device_id: deviceId,
        })
      );
      if (response.data.getSIPAccountStormByDeviceID.statusCode !== 200) {
        throw new Error(response.data.getSIPAccountStormByDeviceID.body);
      }
      const sipAccounts = JSON.parse(response.data.getSIPAccountStormByDeviceID.body);
      if (sipAccounts.length > 0) {
        const sipAccountsModels = sipAccounts.map((sipAccount) => {
          return {
            Name: sipAccount.Name,
            id: sipAccount.id,
            SIPOwner: sipAccount.SIPOwner
          };
        });
        sipAccountsModels.unshift({ Name: t(TRANSLATION_KEYS.NONE), id: "" });
        setSipAccounts(sipAccountsModels);
      }
    } catch (error) {
      setErrorMessage(t(TRANSLATION_KEYS.PRODUCTS_ERRORS_FETCH_SIP_ACCOUNTS));
      setErrorModalOpen(true);
    } finally {
      setIsLoading(false);
    }
  };

  const handleHeaderButtonClicked = () => {
    if (initialValues.Callees.length < 4) {
      const newCallee = {
        id: uuidv4(),
        Number: "",
        LinkNumber: "",
        LinkEmail: "",
        Priority: (initialValues.Callees.length + 1).toString(),
      };
      setInitialValues(prevInitialValues => {
        return {
          ...prevInitialValues,
          Callees: [...prevInitialValues.Callees, newCallee],
        };
      });
      setIsUnsaved(true);
    }
  };

  const handleSubmit = async (data) => {
    try {
      setIsLoading(true);
      data.DeviceID = deviceId;
      if (!device.devicesRingGroupId) {
        data.RingGroup.Name = await getBuildingName();
      }
      const res = await API.graphql(
        graphqlOperation(manageCalleesStorm, { input: data })
      );
      if (res.data.manageCalleesStorm.statusCode !== StatusCodes.OK) {
        throw new Error();
      }
      setIsUnsaved(false);
    } catch (error) {
      setErrorMessage(t(TRANSLATION_KEYS.PRODUCTS_ERRORS_UPDATE_CALLEES));
      setErrorModalOpen(true);
      await getCalleesAndRingGroupInfo();
    } finally {
      setIsLoading(false);
    }
  };

  const getBuildingName = async () => {
    try {
      const values = await API.graphql(
        graphqlOperation(listPageBuildingsByUser)
      );
      const buildings = values.data.listBuildingsByUser.items;
      const buildingName = buildings.find((building) => building.id === buildingId).Name;
      return buildingName;
    } catch (error) {
      setErrorMessage(t(TRANSLATION_KEYS.PRODUCTS_ERRORS_FETCH_BUILDING_NAME));
      setErrorModalOpen(true);
    }
  };

  const handleUpdateCalleesInfo = (calleesInfo) => {
    setIsUnsaved(true);
    setInitialValues(calleesInfo);
  }

  const handleClose = () => {
    if (isUnsaved) {
      setConfirmModalOpen(true)
    } else {
      onClose()
    }
  };

  return (
    <div id="callees-modal">
      <Modal
        headerText={headerText}
        isOpen={calleesModalOpen}
        onClose={handleClose}
      >
        <div id="callees-modal-content">
          {isLoading
            ? <Loader></Loader>
            : <CalleeForm
              calleesInfo={initialValues}
              onSubmit={handleSubmit}
              onUpdate={handleUpdateCalleesInfo}
              sipAccounts={sipAccounts}
              onAddCalleeClicked={handleHeaderButtonClicked}
              saveButtonDisabled={!isUnsaved}
            />
          }
          <div className="error-modal">
            <Modal
              isOpen={errorModalOpen}
              onClose={() => setErrorModalOpen(false)}
            >
              <>
                <img
                  src={require("../../img/icon-material-warning@1x.png")}
                  alt="modal"
                />
                <p>{errorMessage}</p>
              </>
            </Modal>
          </div>
          <ConfirmModal
            isOpen={confirmModalOpen}
            onConfirm={onClose}
            onCancel={() => setConfirmModalOpen(false)}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Callees;
