import React, { useState } from 'react';
import { useTranslation} from 'react-i18next';
import Modal from '../Modal/Modal';
import './css/AddPositionIndicatorForm.css';
import { TRAVEL_INFO_COLORS, TRAVEL_INFO_FONT_FAMILIES, TRAVEL_INFO_FONT_SIZES } from '../../constants/FormOptions';
import { DEFAULT_TRAVEL_INFO_PATH } from '../../constants/DefaultValues';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

function AddPositionIndicatorForm({item, onClick, onClose }) {
    const { t } = useTranslation(['common', 'editVisual']);
    const [positionIndicator, setPositionIndicator] = useState(item);
    const [digit, setDigit] = useState("");
    const [fontFamily, setFontFamily] = useState("");
    const [fontSize, setFontSize] = useState("");
    const [fontColor, setFontColor] = useState("");

    function handleChange(e) {
        const value = e.target.value;
        const property = e.target.id;
        setDigit(value);
        setPositionIndicator({ ...positionIndicator, [property]: e.target.value });
    }

    function handleButtonClicked() {
        const staFileName = `${fontFamily}_${fontSize}_${fontColor}.sta`;
        positionIndicator.source = `${DEFAULT_TRAVEL_INFO_PATH}${fontSize}\\${fontColor}\\${staFileName}`;
        onClick(positionIndicator);
    }

    return (
        <div id="add-position-indicator-form">
            <Modal
                headerText={t(TRANSLATION_KEYS.EDIT_VISUAL_CREATE_POSITION_INDICATOR)}
                isOpen={true}
                onClose={onClose}
                buttonDisabled={!digit || !fontFamily || !fontSize || !fontColor}
                buttonText={t(TRANSLATION_KEYS.CREATE)}
                buttonClick={handleButtonClicked}
            >
                <div className="form-group-input">
                    <label className='titreInput' htmlFor="digit">{t(TRANSLATION_KEYS.EDIT_VISUAL_DIGITS_LABEL)}</label>
                    <select id="digit" className='form-control' onChange={handleChange}>
                        <option value="">{t(TRANSLATION_KEYS.EDIT_VISUAL_DIGITS_PLACEHOLDER)}</option>
                        <option value="1">1</option>
                        <option value="2">2</option>
                        <option value="3">3</option>
                    </select>
                </div>
                <div className="form-group-input">
                    <label className='titreInput' htmlFor="font">{t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_FAMILY_LABEL)}</label>
                    <select id="font" className='form-control' onChange={(e) => setFontFamily(e.target.value)}>
                        <option value="">{t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_FAMILY_PLACEHOLDER)}</option>
                        {TRAVEL_INFO_FONT_FAMILIES.map((font, index) => (
                            <option key={index} value={font}>{font}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group-input">
                    <label className='titreInput' htmlFor="font-size">{t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_SIZE_LABEL)}</label>
                    <select id="font-size" className='form-control' onChange={(e) => setFontSize(e.target.value)}>
                        <option value="">{t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_SIZE_PLACEHOLDER)}</option>
                        {TRAVEL_INFO_FONT_SIZES.map((size, index) => (
                            <option key={index} value={size}>{size}</option>
                        ))}
                    </select>
                </div>
                <div className="form-group-input">
                    <label className="titreInput" htmlFor="color">{t(TRANSLATION_KEYS.EDIT_VISUAL_COLOR_LABEL)}</label>
                    <select id="color" className='form-control' onChange={(e) => setFontColor(e.target.value)}>
                        <option value="">{t(TRANSLATION_KEYS.EDIT_VISUAL_COLOR_PLACEHOLDER)}</option>
                        {TRAVEL_INFO_COLORS.map((option) => (
                            <option key={option.value} value={option.value}>{t(option.text)}</option>
                        ))}
                    </select>
                </div>
            </Modal>
        </div>
    );
};

export default AddPositionIndicatorForm;
