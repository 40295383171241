import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import SidebarHeader from "./SidebarHeader";
import "./css/Sidebar.css";
import InfoModal from "../Modal/InfoModal";
import Button from "../Buttons/Button";
import { Link, useNavigate } from "react-router-dom";
import { useLocation, useParams } from "react-router-dom";
import { Auth } from "@aws-amplify/auth";
import { CustomAttributes } from "../../constants/CustomAttributes";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { RenderUserSpecificMenu } from "../RenderUser/RenderUserSpecificMenu";
import IdConnect from "../IdConnect/IdConnect";
import { API, graphqlOperation } from "aws-amplify";
import { getBuildingsBFGCustom, exportBIDFile } from "../../graphql/queries";
import { FilesName } from "../../constants/FilesName";
import { StatusCodes } from "../../constants/StatusCodes";
import { DOWNLOAD_CONFIGURATION_OPTIONS, DOWNLOAD_CONFIGURATION_VALUE_BFG, DOWNLOAD_CONFIGURATION_VALUE_BID } from "../../constants/DefaultValues";
import LanguageToggle from "./LanguageToggle";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const Sidebar = (props) => {
  const { t } = useTranslation('sidebar');
  const [showProfileModal, setShowProfileModal] = useState(false);
  const [showAboutModal, setShowAboutModal] = useState(false);
  const [showOptions, setShowOptions] = useState(false);
  const location = useLocation();
  const buildingPage = location.pathname.includes("buildings/");
  const buildingId = useParams().buildingId;
  const currentPage = useParams()["*"];
  const navigate = useNavigate();
  const { user } = useAuthenticator((context) => [context.user]);
  let userName = "";
  let role = "";
  

  const hasAccessContractor = RenderUserSpecificMenu(
    user,
    CustomAttributes.CONTRACTOR
  );
  
  const groups = user.signInUserSession.accessToken.payload["cognito:groups"];
  if (groups && groups.length > 0) {
    role = groups[0];
    userName = user.attributes.email;
  }

  function handleClickProfile(e) {
    e.stopPropagation();
    setShowProfileModal(!showProfileModal);
  }

  function handleClickAbout(e) {
    e.stopPropagation();
    setShowAboutModal(!showAboutModal);
  }

  async function handleLogOut() {
    try {
      await Auth.signOut();
      sessionStorage.removeItem("postLoginRedirect"); // Reset the flag on sign out
      navigate("/");
      window.location.reload();
    } catch (error) {
      console.error("Error signing out: ", error);
    }
  }

  function handleDownloadOptionClicked(option) {
    switch(option) {
      case DOWNLOAD_CONFIGURATION_VALUE_BFG:
        handleDownloadConfiguration();
        break;
        case DOWNLOAD_CONFIGURATION_VALUE_BID:
        handleDownloadBuildingIdentity();
        break;
      default:
        break;
    }
  }

  const handleDownloadBuildingIdentity = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(exportBIDFile, {
          id: buildingId,
        })
      );
      const data = response.data?.exportBIDFile;
      if (data.statusCode === StatusCodes.OK) {
        const fileUrl = data.body;
        const fileName = FilesName.BUILDINGBID;
        downloadFileFromUrl(fileUrl, fileName);
      }
    } catch (error) {
      console.error("Error downloading bid file:", error);
    }
  };

  const handleDownloadConfiguration = async () => {
    try {
      const response = await API.graphql(
        graphqlOperation(getBuildingsBFGCustom, {
          id: buildingId,
        })
      );
      downloadResponseAsJson(response, FilesName.BUILDINGBFG);
    } catch (error) {
      console.error("Error download bfg file:", error);
    }
  };

  function downloadResponseAsJson(response, fileName) {
    const jsonStr = JSON.stringify(response, null, 4);
    download(jsonStr, fileName, "text/plain");
  }

  function download(content, fileName, contentType) {
    var a = document.createElement("a");
    var file = new Blob([content], { type: contentType });
    a.href = URL.createObjectURL(file);
    a.download = fileName;
    a.click();
  }

  function downloadFileFromUrl(url, fileName) {
    fetch(url)
      .then((response) => response.blob())
      .then((blob) => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        link.click();

        URL.revokeObjectURL(link.href);
      })
      .catch((error) =>
        console.error("Error downloading file from URL:", error)
      );
  }

  return (
    <div id="sidebar">
      {buildingPage &&
        <>
          <SidebarHeader onBuildingOptionsClicked={() => setShowOptions(!showOptions)} />
          { showOptions &&
              <div id="download-options-select">
                  {DOWNLOAD_CONFIGURATION_OPTIONS.map((option) =>
                      <div
                        data-testid={`dowload-option-${option.value}`}
                        key={option.value} class="dowload-option"
                        onClick={() => handleDownloadOptionClicked(option.value)}
                      >
                        <p>{t(option.label)}</p>
                        <img src={require("../../img/picto-download-white.png")} alt="arrow down" />
                      </div>
                  )}
              </div>
          }
        </>
      }
      <div id="navigation-panel">
        {buildingPage ? (
          <>
            <ul>
              <li className={`menu-item ${currentPage === "products" && "selected"}`}>
                <Link data-testid="products-link" to={`/buildings/${buildingId}/products`}>
                  <img
                    src={require("../../img/soustraction-6-1@1x.png")}
                    alt="product menu icon"
                  />
                  <span>{t(TRANSLATION_KEYS.SIDEBAR_PRODUCTS)}</span>
                </Link>
              </li>
              <li className={`menu-item ${currentPage === "visuals" && "selected"}`}>
                <Link data-testid="visual-link" to={`/buildings/${buildingId}/visuals`}>
                  <img
                    src={require("../../img/picto-theme-4@1x.png")}
                    alt="visual menu icon"
                  />
                  <span>{t(TRANSLATION_KEYS.SIDEBAR_VISUALS)}</span>
                </Link>
              </li>
              <li className={`menu-item ${currentPage === "library" && "selected"}`}>
                <Link data-testid="library-link" to={`/buildings/${buildingId}/library`}>
                  <img
                    src={require("../../img/picto-library.png")}
                    alt="visual menu icon"
                  />
                  <span>{t(TRANSLATION_KEYS.SIDEBAR_LIBRARY)}</span>
                </Link>
              </li>
              {/* <li className={`menu-item ${currentPage === "log" && "selected"}`}>
                <Link to={`/buildings/${buildingId}/${currentPage}`}> //buildings/${buildingId}/log`
                  <img
                    src={require("../../img/groupe-518-1@1x.png")}
                    alt="log menu icon"
                  />
                  <span>LOG</span>
                </Link>
              </li> */}
            </ul>
          </>
        ) : (
          <ul>
            <li className={`menu-item ${currentPage === "buildings" && "selected"}`}>
              <Link to={`/buildings`}>
                <img
                  src={require("../../img/trac-414-11.png")}
                  alt="buildings menu icon"
                />
                <span>{t(TRANSLATION_KEYS.SIDEBAR_BUILDINGS)}</span>
              </Link>
            </li>
            {hasAccessContractor && (
              <li className={`menu-item ${currentPage === "firstRespondermanagement" && "selected"}`}>
                <Link to={`/firstRespondermanagement`}>
                  <img
                    src={require("../../img/soustraction-38-7.png")}
                    alt="mechanics menu icon"
                  />
                  <span>{t(TRANSLATION_KEYS.SIDEBAR_MECHANICS)}</span>
                </Link>
              </li>
            )}
            { groups && groups.length > 0
              && (groups.includes("VidatechAdmin") || groups.includes("Admin")) &&
                <li className={`menu-item ${currentPage === "usermanagement" && "selected"}`}>
                <Link to={`/usermanagement`}>
                  <img
                    src={require("../../img/picto-user.png")}
                    alt="user menu icon"
                  />
                  <span>{t(TRANSLATION_KEYS.SIDEBAR_USERS)}</span>
                </Link>
              </li>
            }
            {groups && groups.length > 0 && groups.includes("VidatechAdmin") && 
              <li className={`menu-item ${currentPage === "vidatechadmin" && "selected"}`}>
                <Link to={`/vidatechadmin`}>
                  <img
                    src={require("../../img/soustraction-38-7.png")}
                    alt="vidatech admin menu icon"
                  />
                  <span>{t(TRANSLATION_KEYS.SIDEBAR_VIDATECH_ADMIN)}</span>
                </Link>
              </li>
            }
          </ul>
        )}
      </div>
      <div className="sidebar-footer">
        <IdConnect />
        <div id="profile">
          <img
            src={require("../../img/soustraction-36-17.png")}
            alt="profile"
          />
          <div id="greetings">
            <p>{t(TRANSLATION_KEYS.SIDEBAR_GREETINGS)}</p>
            <p title={userName} id="greetings-user-name">{userName}</p>
          </div>
          <Link title={t(TRANSLATION_KEYS.SIDEBAR_GO_TO_ACCOUNT)} to={`/account`} id="profile-button">
            <div>■</div>
            <div>■</div>
            <div>■</div>
          </Link>
          <button title={t(TRANSLATION_KEYS.SIDEBAR_LOGOUT)} onClick={handleLogOut} id="logout-button">
            <img
              src={require("../../img/picto-logout.png")}
              alt="logout icon"
            />
          </button>
        </div>
        {showAboutModal && (
          <div id="about-modal">
            <InfoModal
              onClose={() => {
                setShowAboutModal(false);
              }}
            >
              <div>
                <h2>{t(TRANSLATION_KEYS.SIDEBAR_CONTACT)}</h2>
                <p>1 (418) 476-4802</p>
                <p>info@vidatech.ca</p>
                <a href="https://vidatechelevator.com/" target="_blank">
                  vidatechelevator.com
                </a>
              </div>
              <a
                href="https://www.linkedin.com/company/vidatech/"
                target="_blank"
                id="linked-in-link"
              >
                <img
                  src={require("../../img/icon-linkedin-1@1x.png")}
                  alt="linkedin logo"
                />
              </a>
            </InfoModal>
          </div>
        )}
        <div id="tools">
          <div id="about-button" onClick={handleClickAbout}>
            <span title={t(TRANSLATION_KEYS.SIDEBAR_ABOUT)}>?</span>
          </div>
          <div title={t(TRANSLATION_KEYS.SIDEBAR_CHANGE_LANGUAGE)} id="language-togggle">
            <LanguageToggle />
          </div>
          {/* <div
            id="language-button"
            onClick={() => alert("language button clicked")}
          >
            <p>EN /</p>
            <img src="/src/assets/language-arrow.png" alt="language arrow" />
            <p>FR</p>
          </div> */}
        </div>
        <div id="logo">
          <img
            src={require("../../img/logo-vidatech-final-couleur-240pk-1-1x-png@1x.png")}
            alt="logo"
          />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
