import React, { useState } from 'react';
import { useTranslation } from "react-i18next";
import "./css/ButtonProperties.css";
import FormInputGlobal from '../Form/InputGlobal';
import { DISPLAY_XML_ATTRIBUTE, DISPLAY_XML_PROPERTY } from '../../constants/Fields';
import Modal from '../Modal/Modal';
import FileExplorer from '../FileExplorer/FileExplorer';
import { useSharedData } from "../../context/SharedProvider";
import errorImage from "../../img/error-image.svg";
import { FormProvider } from '../Form/FormProvider';
import Tooltip from '../SharedComponents/Tooltip/Tooltip';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

const ButtonProperties = ({ selectedItem, onSelectedItemUpdated, onItemPropertyUpdated, buttons }) => {
    const { t } = useTranslation(['common', 'editVisual']);
    const [pressedImageFileExporerModal, setPressedImageFileExporerModal] = useState(false);
    const [unpressedImageFileExporerModal, setUnpressedImageFileExporerModal] = useState(false);
    const { downloadImage } = useSharedData();

    function handleValueChanged(e) {
        let value = e.target.value;
        let name = e.target.name;
        if (!isNaN(value)) {
            value = e.target.value.replace(/^0+/, "");
            value = e.target.value.length > 0 ? e.target.value : 0;
            value = parseInt(value);
            onItemPropertyUpdated(name, value);
        }
    }

    async function changeButtonSource(image, sourceProperty) {
        selectedItem[sourceProperty] = image.path.split("/").join("\\");
        try {
            if (sourceProperty === DISPLAY_XML_PROPERTY.UNPRESSED_IMAGE_SOURCE) {
                selectedItem.unpressedData = image.data ? image.data : await downloadImage(image.path);
            } else {
                selectedItem.pressedData = image.data ? image.data : await downloadImage(image.path);
            }
            if (sourceProperty === DISPLAY_XML_PROPERTY.UNPRESSED_IMAGE_SOURCE) {
                selectedItem.unpressedUnavailable = false;
            } else {
                selectedItem.pressedUnavailable = false;
            }
            onSelectedItemUpdated(selectedItem);
            setPressedImageFileExporerModal(false);
            setUnpressedImageFileExporerModal(false);
        } catch (error) {
            console.error(error);
        }
    }

    function handleNameChanged(e) {
        let value = e.target.value;
        let name = e.target.name;

        if (value.length > 0) {
            onItemPropertyUpdated(name, value);
        } else {
            e.target.value = selectedItem.name;
        }
    }

    function handleButtonPressed() {
        if (!selectedItem.isPressed) {
            const item = { ...selectedItem };
            item.isPressed = true;
            onSelectedItemUpdated(item);
        }
    }

    function handleButtonUnpressed() {
        if (selectedItem.isPressed) {
            const item = { ...selectedItem };
            item.isPressed = false;
            onSelectedItemUpdated(item);
        }
    }

    function handleFloorAddressChanged(e) {
        let floorAddress = parseInt(e.target.value);
        const selectedButton = buttons.find(button => button[DISPLAY_XML_ATTRIBUTE.ID] === selectedItem[DISPLAY_XML_ATTRIBUTE.ID]);
        selectedButton.behavior.value = floorAddress;
        // check all buttons to see if some have the same floor adress. If so, add error
        checkButtonFloorAddresses();
        selectedItem.behavior.value = floorAddress;
        selectedItem.errors = selectedButton.errors;
        onSelectedItemUpdated(selectedItem);
    }

    function checkButtonFloorAddresses() {
        buttons.forEach(button =>
            button.errors = button.errors.filter(e =>
                e !== t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS) && e !== t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP)));
        buttons.sort((a, b) => a.behavior.value - b.behavior.value);
        buttons.forEach((button, index) => {
            const buttonsWithSameFloorAddress =
                buttons.filter(b => b.behavior.value === button.behavior.value && b[DISPLAY_XML_ATTRIBUTE.ID] !== button[DISPLAY_XML_ATTRIBUTE.ID]);
            if (buttonsWithSameFloorAddress.length > 0) {
                button.errors.push(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS));
            } else {
                button.errors = button.errors.filter(e => e !== t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS));
            }
            if (index + 1 < buttons.length && button.behavior.value + 1 !== buttons[index + 1].behavior.value) {
                buttons[index + 1].errors.push(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP));
            }
        });
        if (buttons[0].behavior.value !== 1) {
            buttons[0].errors.push(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP));
        }
    }

    return (
        <FormProvider initialValues={selectedItem} onSubmit={(e) => { }}>
            <div id="panel-content" className='button-properties full-panel-properties'>
                <div id="item-content">
                    <div className="panel-banner">
                        <h3 data-testid="properties-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_PROPERTIES, { category: t(TRANSLATION_KEYS.EDIT_VISUAL_BUTTON) }).toUpperCase()}</h3>
                    </div>
                    <div className="property-options">
                        <h4 className="property-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_OPTIONS)}</h4>
                        <Tooltip text={t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_POSITION_INFO)} >
                            <h5>{t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_POSITION)}</h5>
                        </Tooltip>
                        <div className="property-group small-input">
                            <FormInputGlobal
                                type="number"
                                name="x_value"
                                validators={[]}
                                label={"X"}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                            <FormInputGlobal
                                type="number"
                                name="y_value"
                                validators={[]}
                                label={"Y"}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                            {selectedItem?.errors?.includes(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_OVERLAPPING))
                                && <Tooltip
                                    warning={true}
                                    text={t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_OVERLAPPING)}
                                ></Tooltip>}

                        </div>
                        <h4 className="property-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_BUTTON_OPTIONS)}</h4>
                        <div id="button-name-field" className="property-group">
                            <FormInputGlobal
                                type="text"
                                name="name"
                                label={t(TRANSLATION_KEYS.EDIT_VISUAL_BUTTON_NAME)}
                                validators={[]}
                                onChange={handleNameChanged}
                                errors={[]}
                            />
                        </div>
                        <div className="property-group small-input">
                            <FormProvider
                                initialValues={selectedItem?.behavior}
                            >
                                <FormInputGlobal
                                    type="number"
                                    name="value"
                                    label={t(TRANSLATION_KEYS.EDIT_VISUAL_FLOOR_ADDRESS)}
                                    validators={[]}
                                    min={1}
                                    onChange={handleFloorAddressChanged}
                                    errors={[]}
                                />
                                {
                                    selectedItem && selectedItem.errors && (
                                        <>
                                            {selectedItem.errors.includes(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS)) &&
                                                selectedItem.errors.includes(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP)) ? (
                                                <Tooltip
                                                    warning={true}
                                                    messages={[t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS), t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP)]}
                                                />
                                            ) : selectedItem.errors.includes(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS)) ? (
                                                <Tooltip warning={true} text={t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_DUPLICATE_FLOOR_ADDRESS)} />
                                            ) : selectedItem.errors.includes(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP)) ? (
                                                <Tooltip warning={true} text={t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_BUTTON_FLOOR_ADDRESS_GAP)} />
                                            ) : null}
                                        </>
                                    )
                                }
                            </FormProvider>
                        </div>
                        <div id='custom-button'>
                            <p className='titreInput'>{t(TRANSLATION_KEYS.EDIT_VISUAL_CUSTOM_BUTTON)}</p>
                            <div className='import-custom-button'>
                                <img
                                    src={selectedItem?.unpressedUnavailable ? errorImage : selectedItem?.unpressedData}
                                    alt="pressed button"
                                    onClick={handleButtonUnpressed}
                                />
                                <p>{t(TRANSLATION_KEYS.EDIT_VISUAL_CHANGE_UNPRESSED)}</p>
                                <button onClick={() => setUnpressedImageFileExporerModal(true)}>
                                    <img src={require("../../img/import-button.png")} alt="import file" />
                                </button>
                                {unpressedImageFileExporerModal &&
                                    <Modal
                                        isOpen={unpressedImageFileExporerModal}
                                        onClose={() => setUnpressedImageFileExporerModal(false)}
                                        headerText={t(TRANSLATION_KEYS.EDIT_VISUAL_SELECT_IMAGE)}
                                    >
                                        <FileExplorer
                                            importButton={true}
                                            onItemDoubleClicked={(image) => changeButtonSource(image, DISPLAY_XML_PROPERTY.UNPRESSED_IMAGE_SOURCE)}
                                        />
                                    </Modal>
                                }
                            </div>
                            <div className='import-custom-button'>
                                <img
                                    src={selectedItem?.pressedUnavailable ? errorImage : selectedItem?.pressedData}
                                    alt="unpressed button"
                                    onClick={handleButtonPressed}
                                />
                                <p>{t(TRANSLATION_KEYS.EDIT_VISUAL_CHANGE_PRESSED)}</p>
                                <button onClick={() => setPressedImageFileExporerModal(true)}>
                                    <img src={require("../../img/import-button.png")} alt="import file" />
                                </button>
                                {pressedImageFileExporerModal &&
                                    <Modal
                                        isOpen={pressedImageFileExporerModal}
                                        onClose={() => setPressedImageFileExporerModal(false)}
                                        headerText={t(TRANSLATION_KEYS.EDIT_VISUAL_SELECT_IMAGE)}
                                    >
                                        <FileExplorer
                                            importButton={true}
                                            onItemDoubleClicked={(image) => changeButtonSource(image, DISPLAY_XML_PROPERTY.PRESSED_IMAGE_SOURCE)}
                                        />
                                    </Modal>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

export default ButtonProperties;
