import { TRANSLATION_KEYS } from "./TranslationKeys";

export const BUILDING_HEADERS = {
    [TRANSLATION_KEYS.BUILDING_NAME]: "Name",
    [TRANSLATION_KEYS.BUILDING_ADDRESS]: "Address",
    // "Contractor": "Contractor",
    [TRANSLATION_KEYS.BUILDING_PHONE]: "Phone",
    [TRANSLATION_KEYS.BUILDING_CITY]: "City",
    [TRANSLATION_KEYS.BUILDING_COUNTRY]: "Country",
    [TRANSLATION_KEYS.BUILDING_STATUS]: "Status",
    [TRANSLATION_KEYS.BUILDING_ACTION]: "Action",
};

export const BUILDING_WITH_IP_HEADERS = {
    "Building name": "Name",
    "Address": "Address",
    "Phone": "Phone",
    "Dst. address": "DstAddress",
    "Password": "Password",
    "Remote address": "RemoteAddress",
    "Username": "Username"
};

// export const USER_HEADERS = ["Name", "Email", "Organization", "Role", "Action"];

export const USER_HEADERS = {
    [TRANSLATION_KEYS.USERS_NAME]: "name",
    [TRANSLATION_KEYS.USERS_EMAIL]: "email",
    // "Organization": "Organization",
    [TRANSLATION_KEYS.USERS_ROLE]: "role",
    [TRANSLATION_KEYS.USERS_ACTION]: "Action",
};

export const PRODUCT_HEADERS = {
    [TRANSLATION_KEYS.PRODUCTS_ID]: "PhysicalAddress",
    [TRANSLATION_KEYS.PRODUCTS_NAME]: "Name",
    [TRANSLATION_KEYS.PRODUCTS_MODEL]: "Models",
    [TRANSLATION_KEYS.PRODUCTS_THEME_LINK]: "ThemeFolderName",
    [TRANSLATION_KEYS.PRODUCTS_STATUS]: "Status",
    [TRANSLATION_KEYS.PRODUCTS_ACTION]: "Action",
};

export const FIRST_RESPONDER_HEADERS = {
    [TRANSLATION_KEYS.FIRST_RESPONDERS_NAME]: "Name",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_PHONE]: "Number",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_SUNDAY]: "Sunday",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_MONDAY]: "Monday",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_TUESDAY]: "Tuesday",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_WEDNESDAY]: "Wednesday",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_THURSDAY]: "Thursday",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_FRIDAY]: "Friday",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_SATURDAY]: "Saturday",
    [TRANSLATION_KEYS.FIRST_RESPONDERS_ACTIONS]: "Actions",
};

export const VISUAL_HEADERS = {
    [TRANSLATION_KEYS.VISUALS_NAME]: "name",
    [TRANSLATION_KEYS.VISUALS_RESOLUTION]: "resolution",
    [TRANSLATION_KEYS.VISUALS_ACTIONS]: "Actions",
};