import { TRANSLATION_KEYS } from "./TranslationKeys";

export const CONTRACTOR_ID_FIELD = "custom:ContractorID";
export const OWNER_ID_FIELD = "custom:OwnerID";
export const DISPLAY_XML_TYPE = {
    IMAGE: "icon",
    BACKGROUND: "background",
    BUTTON_BEHAVIOR: "stair_cop",
    TRAVEL_INFO: "stair"
}
export const DISPLAY_ITEM_CATEGORY = {
    IMAGE: "Image",
    BACKGROUND: "Background",
    PLAYLIST: "Playlist",
    PLAYLIST_IMAGE: "PlaylistImage",
    BUTTON: "Button",
    PAGE: "Page",
    TRAVEL_INFO: "Travel_Info",
    WIDGET_HOUR: "Widget_Hour",
    WIDGET_METEO: "Widget_Meteo",
    TEXT: "Text",
}

export const DISPLAY_XML_ATTRIBUTE = {
    ID: "@_id",
    PAGE_NAME: "@_name",
    ROTATION: "@_rotation",
    Y_RESOLUTION: "@_y_resolution",
    X_RESOLUTION: "@_x_resolution",
    SPECIAL_MESSAGE_ID: "@_specialmessageid",
}

export const DISPLAY_XML_PROPERTY = {
    PRESSED_IMAGE_SOURCE: "pressed_image_source",
    UNPRESSED_IMAGE_SOURCE: "unpressed_image_source",
    X_VALUE: "x_value",
    Y_VALUE: "y_value",
}

export const CATEGORY_DISPLAY_NAMES = {
    [DISPLAY_ITEM_CATEGORY.IMAGE]: TRANSLATION_KEYS.EDIT_VISUAL_IMAGE,
    [DISPLAY_ITEM_CATEGORY.BACKGROUND]: TRANSLATION_KEYS.EDIT_VISUAL_BACKGROUND,
    [DISPLAY_ITEM_CATEGORY.PLAYLIST]: TRANSLATION_KEYS.EDIT_VISUAL_PLAYLIST,
    [DISPLAY_ITEM_CATEGORY.BUTTON]: TRANSLATION_KEYS.EDIT_VISUAL_BUTTON,
    [DISPLAY_ITEM_CATEGORY.PAGE]: TRANSLATION_KEYS.EDIT_VISUAL_PAGE,
    [DISPLAY_ITEM_CATEGORY.TRAVEL_INFO]: TRANSLATION_KEYS.EDIT_VISUAL_POSITION_INDICATOR,
    [DISPLAY_ITEM_CATEGORY.WIDGET_HOUR]: TRANSLATION_KEYS.EDIT_VISUAL_WIDGET_HOUR,
    [DISPLAY_ITEM_CATEGORY.WIDGET_METEO]: TRANSLATION_KEYS.EDIT_VISUAL_WIDGET_WEATHER,
    [DISPLAY_ITEM_CATEGORY.TEXT]: TRANSLATION_KEYS.EDIT_VISUAL_TEXT,
};