import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import "./css/LanguageToggle.css";


export default function LanguageToggle() {
    const { t, i18n } = useTranslation('address');
    const [isFrench, setIsFrench] = useState(i18n.language.includes("fr"));

    const toggleLanguage = () => {
        setIsFrench(!isFrench);
        if (isFrench) {
            i18n.changeLanguage("en")
        } else {
            i18n.changeLanguage("fr")
        }
    };

    return (
        <div className="language-slider-container" onClick={toggleLanguage}>
            <div
                className={`language-slider ${isFrench ? "slider-french" : "slider-english"}`}
            >
                <div className="thumb">
                    <img
                        src={require("../../img/planet.png")}
                        alt="Planet"
                        className="thumb-image"
                    />
                </div>
                <div className="language-label">
                    {isFrench ? "FR" : "EN"}
                </div>
            </div>
        </div>
    )
}