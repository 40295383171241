import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSharedData } from "../../context/SharedProvider";
import "./css/FileExplorer.css";
import FileExplorerItem from "./FileExplorerItem";
import Loader from "../../constants/Loader";
import folder from "../../img/folder.svg";
import { LIBRARY_ALLOWED_FILE_TYPES } from "../../constants/AllowedFileTypes";
import { FILE_STORAGE_LIMIT_O } from "../../constants/DefaultValues";
import Button from "../Buttons/Button";
import VidatechLoader from "../EditVisual/VidatechLoader";
import FileExplorerItemDetails from "./FileExplorerItemDetails";
import Modal from "../Modal/Modal";
import { MAXIMUM_FILE_SIZE, MAX_LENGTH, RESTRICTED_FOLDER_NAMES } from "../../constants/DataValidation";
import errorImage from "../../img/error-image.svg";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const FileExplorer = ({ onItemDoubleClicked, onCurrentFolderChanged, importButton }) => {
    const { 
        fileStructure,
        createFolder, 
        deleteItem, 
        saveImage, 
        isBusy, 
        downloadImage, 
        getVisualsUsingRessource
    } = useSharedData();
    const { t } = useTranslation(['common', 'library']);
    const [currentFolder, setCurrentFolder] = useState(null);
    const [navigationHistory, setNavigationHistory] = useState([]);
    const [isCreatingFolder, setIsCreatingFolder] = useState(false);
    const [folderError, setFolderError] = useState(false);
    const [folderErrorMessage, setFolderErrorMessage] = useState("");
    const [selectedItem, setSelectedItem] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [warningModalOpen, setWarningModalOpen] = useState(false);
    const [fileToDelete, setFileToDelete] = useState(null);
    const [searchValue, setSearchValue] = useState("");
    const [errorModalOpen, setErrorModalOpen] = useState(false);
    const [errorMessage, setErrorMessage] = useState(t(TRANSLATION_KEYS.ERROR));

    useEffect(() => {
        if (fileStructure) {
            if (currentFolder) {
                if (currentFolder.type !== "searchResults") {
                    let newCurrentFolder;
                    if (currentFolder.path === fileStructure.path) {
                        newCurrentFolder = fileStructure;
                    } else {
                        const pathParts = currentFolder.path.split("/").filter(Boolean);
                        pathParts.shift();
                        newCurrentFolder = fileStructure;
                        pathParts.forEach((part) => {
                            newCurrentFolder = newCurrentFolder.content.find((item) => item.name === part);
                        });
                    }
                    setCurrentFolder(newCurrentFolder);
                    onCurrentFolderChanged?.(newCurrentFolder);
                } else {
                    setCurrentFolder(currentFolder);
                }
            }
            else {
                setCurrentFolder(fileStructure);
            }
        }
    }, [fileStructure, currentFolder, onCurrentFolderChanged]);

    const handleItemClicked = async (item) => {
        if (selectedItem?.path !== item.path) {
            setSelectedItem(item);
            if (item.type !== "folder" && item.type !== "ttf" && !item.data) {
                const imagePath = item.type === "widget"
                    ? item.content.find(file => file.name.endsWith(".png"))?.path
                    : item.path;
                try {
                    item.data = imagePath
                        ? await downloadImage(imagePath)
                        : errorImage;
                } catch (error) {
                    setErrorMessage(t(TRANSLATION_KEYS.LIBRARY_ERRORS_DOWNLOAD_IMAGE));
                    setErrorModalOpen(true);
                    item.data = errorImage;
                } finally {
                    setSelectedItem(item );
                }
            }
        }
    }

    const OpenFolder = (folder) => {
        let newHistory;
        if (currentFolder.type === "searchResults") {
            const folderPath = folder.path;
            const parts = folderPath.split("/").filter(Boolean);
            parts.shift();
            let currentFolder = fileStructure;
            newHistory = [currentFolder];
            parts.forEach((part) => {
                currentFolder = currentFolder.content.find((item) => item.name === part);
                newHistory.push(currentFolder);
            });
            newHistory.pop();
        } else {
            newHistory = [...navigationHistory, currentFolder];
        }
        setNavigationHistory(newHistory);
        setCurrentFolder(folder);
        onCurrentFolderChanged?.(folder);
        setSelectedItem(null);
        setSearchValue("");
    }

    const handleBackButtonClicked = () => {
        const newHistory = [...navigationHistory];
        const previousFolder = newHistory.pop();
        if (previousFolder) {
            setNavigationHistory(newHistory);
            setCurrentFolder(previousFolder);
            onCurrentFolderChanged?.(previousFolder);
        } else {
            setCurrentFolder(fileStructure);
            onCurrentFolderChanged?.(fileStructure);
        }
        setSelectedItem(null);
        setSearchValue("");
    }

    const handleCreateFolder = async (e) => {
        const folderName = e.target.value.trim();
        if (folderName && !folderError) {
            const currentFolderPath = currentFolder.path === "/library/" ? "" : currentFolder.path;
            let folderPath = currentFolderPath + folderName + "/";
            if (currentFolder.path !== "/library/") {
                folderPath = folderPath.substring("/library/".length); // TODO : check if backend shouldn't accept /library/ in the path
            }
            //updates the current folder so user can see the new folder immediately
            //A backend error will fetch again and the folder will disappear
            const newFolder = { name: folderName, path: "/library/" + folderPath, type: "folder", content: [], size: 0 };
            currentFolder.content.push(newFolder);
            setIsCreatingFolder(false);
            setSelectedItem(newFolder);
            await createFolder(folderPath);
            setFolderError(false);
            setFolderErrorMessage("");
        }
        else {
            setFolderError(false);
            setErrorMessage("");
            setIsCreatingFolder(false);
        }
    }

    const handleItemDeleted = async (deletedItem) => {
        setIsLoading(true);
        if (deletedItem.type === "folder" && deletedItem.size === 0) {
            deleteFile(deletedItem);
        } else {
            const visualsUsingImage = await getVisualsUsingRessource(deletedItem);
            if (visualsUsingImage) {
                deletedItem.visualsUsingImage = visualsUsingImage;
                setFileToDelete(deletedItem);
                setWarningModalOpen(true);
    
            } else {
                deleteFile(deletedItem);
            }
        }
        setIsLoading(false);
    }

    const deleteFile = async (deletedItem) => {
        currentFolder.content = currentFolder.content.filter((item) => item.path !== deletedItem.path);
        let parentFolder = currentFolder;

        currentFolder.size -= deletedItem.size;
        if (currentFolder.path !== fileStructure.path) {
            fileStructure.size -= deletedItem.size;
        }
        const updateParentFolderSize = (folder) => {
            const pathParts = folder.path.split("/").filter(Boolean);
            const parentPath = "/" + pathParts.slice(0, pathParts.length - 1).join("/") + "/";
            if (parentPath) {
                const parentFolder = fileStructure.content.find((item) => item.path === parentPath);
                if (parentFolder) {
                    parentFolder.size -= deletedItem.size;
                    updateParentFolderSize(parentFolder);
                }
            }
        }
        
        updateParentFolderSize(parentFolder);
        setSelectedItem(null);
        setWarningModalOpen(false);
        setFileToDelete(null);
        const deletedItemPath = deletedItem.path.substring("/library/".length); // TODO : check if backend shouldn't accept /library/ in the path
        await deleteItem(deletedItemPath);
    }

    const handleChange = (e) => {
        e.target.value = e.target.value
            .replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '')
            .replace(".", "")
            .replace(/[^a-zA-Z0-9-_ \p{L}]/gu, "");
        const folderName = e.target.value
        const folderExists = currentFolder.content.find((item) => item.name === folderName.trim());
        const isFolderNameValid = RESTRICTED_FOLDER_NAMES.every((word) => folderName.toLowerCase() !== word);
        setFolderError(folderExists || !isFolderNameValid);
        setFolderErrorMessage(folderExists
            ? t(TRANSLATION_KEYS.LIBRARY_ERRORS_FOLDER_EXISTS)
            : !isFolderNameValid
                ? t(TRANSLATION_KEYS.LIBRARY_ERRORS_INVALID_NAME)
                : ""
        );
    }

    function dragOverHandler(e) {
        e.preventDefault();
        if(currentFolder.type === "searchResults" || (currentFolder.path).startsWith("/library/vidatech/")) {
            e.dataTransfer.dropEffect = "none";
        }
    }

    async function dropHandler(e) {
        e.preventDefault();
        if(currentFolder.type !== "searchResults") {
            try {
                setIsLoading(true);
                let filesToSave = [];
                if (e.dataTransfer.items) {
                    filesToSave = [...e.dataTransfer.items]
                        .filter((item) => item.kind === "file" && LIBRARY_ALLOWED_FILE_TYPES.includes(item.type))
                        .map ((item) => item.getAsFile());
                } else {
                    filesToSave = [...e.dataTransfer.files];
                }

                const filesToSaveSize = filesToSave.reduce((acc, file) => acc + file.size, 0);

                if (fileStructure.size + filesToSaveSize >= FILE_STORAGE_LIMIT_O) {
                    setErrorMessage(t(TRANSLATION_KEYS.FILE_STORAGE_LIMIT_TEXT));
                    setErrorModalOpen(true);
                } else {
                    for (const file of filesToSave) {
                        if (file.size < MAXIMUM_FILE_SIZE ) {
                            await saveFile(file);
                        } else {
                            setErrorMessage(t(TRANSLATION_KEYS.LIBRARY_ERRORS_FILE_SIZE_EXCEEDED));
                            setErrorModalOpen(true);
                        }
                    }
                }
            } catch (error) {
                setErrorMessage(t(TRANSLATION_KEYS.LIBRARY_ERRORS_PROCESSING_FILES));
                setErrorModalOpen(true);
            } finally {
                setIsLoading(false);
            }
        }
    }

    const saveFile = async (file) => {
        setIsLoading(true);
        let fileName = file.name;
        let imageExists = currentFolder.content.find((item) => item.name === fileName);
        let counter = 2;
        const findImageByName = (item) => item.name === fileName;
        while (imageExists) {
            const parts = file.name.split(".");
            fileName = parts[0] + ` (${counter++}).` + parts[1];
            imageExists = currentFolder.content.find(findImageByName);
        }
        const base64String = await convertToBase64(file);
        const extension = fileName.split(".").pop();
        let path = currentFolder.path + fileName;
        if (path.startsWith("/library/")) {
            path = path.substring("/library/".length);
        }

        const image = {
            path: path,
            name: fileName,
            data: await convertToObjectUrl(file),
            base64: base64String,
            type: extension,
            size: file.size,
            fullPath: fileStructure.path + path
        };
        await saveImage(image);
        delete image.base64;
        image.path = image.fullPath; //TODO: check if backend shouldn't accept /library/ in the path
        currentFolder.content.push(image);
        currentFolder.size += file.size;
        if (currentFolder.path !== fileStructure.path) {
            fileStructure.size += file.size;
        }

        const updateParentFolderSize = (folder) => {
            const pathParts = folder.path.split("/").filter(Boolean);
            const parentPath = "/" + pathParts.slice(0, pathParts.length - 1).join("/") + "/";
            if (parentPath) {
                const parentFolder = fileStructure.content.find((item) => item.path === parentPath);
                if (parentFolder) {
                    parentFolder.size += file.size;
                    updateParentFolderSize(parentFolder);
                }
            }
        }

        updateParentFolderSize(currentFolder);
        setCurrentFolder({ ...currentFolder });

        setIsLoading(false);
    }

    async function convertToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result.split(",")[1]);
            reader.onerror = (error) => reject(error);
        });
    }

    async function convertToObjectUrl(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = () => {
                const objectUrl = URL.createObjectURL(file);
                resolve(objectUrl);
            };
            reader.onerror = error => {
                reject(error);
            };
            reader.readAsArrayBuffer(file);
        });
    }

    const handleKeyDown = (e) => {
        if (isCreatingFolder) {
            if (e.key === "Enter") {
                e.preventDefault();
                handleCreateFolder(e);
            }
            else if (e.key === "Escape") {
                setIsCreatingFolder(false);
                setFolderError(false);
                setFolderErrorMessage("");
            }
        } else if (selectedItem) {
            const item = currentFolder.content.find((item) => item.path === selectedItem.path);

            if (e.key === "Enter" && item.type === "folder") {
                OpenFolder(item);
            } else if (e.key === "Escape") {
                setSelectedItem(null);
            }
        }
    }

    const handleAddFolderButtonClicked = () => {
        setSelectedItem(null);
        setIsCreatingFolder(true);
    }

    const searchItems = (e) => {
        const value = e.target.value.toLowerCase();
        if (value.length > 0) {
            const searchResults = {
                name: t(TRANSLATION_KEYS.LIBRARY_SEARCH_RESULTS_NAME),
                path: t(TRANSLATION_KEYS.LIBRARY_SEARCH_RESULTS_PATH),
                type: "searchResults",
                content: []
            }
            const search = (folder) => {
                folder.content.forEach((item) => {
                    if (item.name.toLowerCase().includes(value)) {
                        searchResults.content.push(item);
                    }
                    if (item.type === "folder") {
                        search(item);
                    }
                });
            }
            search(fileStructure);
            if (currentFolder.type !== "searchResults") {
                OpenFolder(searchResults);
                onCurrentFolderChanged?.(searchResults);
            } else {
                setCurrentFolder(searchResults);
            }
        } else {
            setCurrentFolder(navigationHistory.pop());
            onCurrentFolderChanged?.(fileStructure);
        }
        setSearchValue(value);
    }

    const handleImportFiles = () => {
        const input = document.createElement("input");
        input.type = "file";
        input.multiple = true;
        input.accept = LIBRARY_ALLOWED_FILE_TYPES.join(",");
        input.onchange = async (e) => {
            const filesToSave = [...e.target.files]
            const filesToSaveSize = filesToSave.reduce((acc, file) => acc + file.size, 0);
            
            if (fileStructure.size + filesToSaveSize >= FILE_STORAGE_LIMIT_O) {
                setErrorMessage(t(TRANSLATION_KEYS.FILE_STORAGE_LIMIT_TEXT));
                setErrorModalOpen(true);
            } else {
                for (let i = 0; i < filesToSave.length; i++) {
                    if (filesToSave[i].size < MAXIMUM_FILE_SIZE) {
                        await saveFile(filesToSave[i]);
                    } else {
                        setErrorMessage(t(TRANSLATION_KEYS.LIBRARY_ERRORS_FILE_SIZE_EXCEEDED));
                        setErrorModalOpen(true);
                    }
                }
            }
        }
        input.click();
    }

    const formattedSize = (size) => {
        if (typeof size === "number") {
            if (size > 1024 * 1024) {
                return t(TRANSLATION_KEYS.LIBRARY_SIZE_MB, { size: (size / (1024 * 1024)).toFixed(2) });
            } else if (size > 1024) {
                return t(TRANSLATION_KEYS.LIBRARY_SIZE_KB, { size: (size / 1024).toFixed(2) });
            } else {
                return t(TRANSLATION_KEYS.LIBRARY_SIZE_BYTE, { size });
            }
        } else {
            return t(TRANSLATION_KEYS.LIBRARY_SIZE_NA);
        }
    }

    const handleItemDoubleClicked = async (item) => {
        if (item.type === "folder") {
            OpenFolder(item);
        } else if (onItemDoubleClicked) {
            setIsLoading(true);
            await onItemDoubleClicked(item);
            setIsLoading(false);
        }
    }

    return (
        !fileStructure ? <Loader /> :
            <div data-testid="file-explorer" id="file-explorer" >
                <div id="file-explorer-header">
                    <div id="address-bar">
                        <button id="back-button" onClick={handleBackButtonClicked}>
                            <img src={require("../../img/navigate-back.png")} alt="back arrow" />
                        </button>
                        <div id="path-name">
                            { navigationHistory.map((item) => `${item.name} > `)}
                            {currentFolder && currentFolder.name}
                        </div>
                        <div id="address-bar-buttons">
                            {importButton &&
                                <div id="import-button">
                                    <Button
                                        disabled={currentFolder && (currentFolder.type === "searchResults" || (currentFolder.path).startsWith("/library/vidatech/"))}
                                        text={t(TRANSLATION_KEYS.LIBRARY_IMPORT_FILE)}
                                        onClick={handleImportFiles}
                                    />
                                </div>
                            }
                            <div id="add-folder">
                                <button
                                    title={t(TRANSLATION_KEYS.LIBRARY_CREATE_FOLDER)}
                                    disabled={currentFolder && (currentFolder.type === "searchResults" || (currentFolder.path).startsWith("/library/vidatech/"))}
                                    onClick={handleAddFolderButtonClicked}
                                >
                                    <img src={require("../../img/picto-create-folder.png")} alt="folder icone" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div id="search-bar">
                        <img id="search-icon" src={require("../../img/picto-loupe.png")} alt="magnifying glass"/>
                        <input onChange={searchItems} value={searchValue} type="text" placeholder={t(TRANSLATION_KEYS.LIBRARY_SEARCH)} />
                    </div>
                </div>
                <div id="file-explorer-content">
                    <div
                        onDrop={dropHandler}
                        onDragOver={dragOverHandler}
                        onClick={() => { setSelectedItem(null) }}
                    >
                        <div data-testid="file-explorer-content-view" id="content-view" >
                            {
                                currentFolder && currentFolder.content?.map((item) =>
                                    <FileExplorerItem
                                        item={item}
                                        key={item.path}
                                        isSelected={selectedItem?.path === item.path}
                                        onClick={handleItemClicked}
                                        onDoubleClick={handleItemDoubleClicked}
                                        onKeyDown={handleKeyDown}
                                        onItemDeleted={() => handleItemDeleted(item)}
                                    />
                                )
                            }
                            {isCreatingFolder &&
                                <div id="create-folder" className={"icon selected"}>
                                    <button className="icon-info">
                                        <img src={folder} alt="folder" />
                                        <textarea
                                            className="file-name"
                                            autoFocus={true}
                                            onChange={handleChange}
                                            onBlur={handleCreateFolder}
                                            onKeyDown={handleKeyDown}
                                            type="text"
                                            maxLength={MAX_LENGTH.DEFAULT_INPUT}
                                            placeholder={t(TRANSLATION_KEYS.LIBRARY_FOLDER_NAME_PLACEHOLDER)}
                                        />
                                        {folderError && <span id="folder-error" >{folderErrorMessage}</span>}
                                    </button>
                                </div>
                            }
                        </div>
                        <div id="content-view-footer">
                            <p>{t(TRANSLATION_KEYS.LIBRARY_STORAGE)}</p>
                                <div id="progress-bar">
                                    <div
                                        id="progress-bar-fill"
                                        style={{ 
                                            width: `${(fileStructure.size / FILE_STORAGE_LIMIT_O) * 100}%`,
                                            backgroundColor: `${((fileStructure.size / FILE_STORAGE_LIMIT_O) * 100) > 90
                                                ? "red"
                                                : "#73B74E"}`
                                        }}
                                    >
                                    </div>
                                </div>
                            <p>{fileStructure && t(TRANSLATION_KEYS.LIBRARY_FILE_STORAGE_LIMIT, { formattedSize: formattedSize(fileStructure.size) })}</p>
                        </div>
                    </div>
                    <FileExplorerItemDetails selectedItem={selectedItem} />
                </div>
                {(isBusy || isLoading) && <VidatechLoader />}
                <Modal
                    isOpen={warningModalOpen}
                    onClose={() => setWarningModalOpen(false)}
                    buttonText={"Delete"}
                    buttonClick={() => deleteFile(fileToDelete)}
                >
                    <>
                    <img
                        src={require("../../img/icon-material-warning@1x.png")}
                        alt="warning sign"
                    />
                        {
                            fileToDelete?.type === "folder" ?
                                <p>{t(TRANSLATION_KEYS.LIBRARY_FOLDER_USED_IN_VISUAL)}</p>
                                :
                                <p>{t(TRANSLATION_KEYS.LIBRARY_FILE_USED_IN_VISUAL)}</p>
                        }
                        {
                            fileToDelete && 
                                <ul>
                                    {fileToDelete.visualsUsingImage.map((visual) => <li key={visual}>{visual}</li>)}
                                </ul>
                        }
                    </>
                </Modal>
                <div className="error-modal">
                    <Modal
                        isOpen={errorModalOpen}
                        onClose={() => setErrorModalOpen(false)}
                    >
                        <>
                            <img
                                src={require("../../img/icon-material-warning@1x.png")}
                                alt="warning sign"
                            />
                            <p>{errorMessage}</p>
                        </>
                    </Modal>
                </div>
            </div>
    );
};

export default FileExplorer;
