import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormProvider } from '../Form/FormProvider';
import { SelectGlobal } from '../Form/SelectGlobal';
import { TEXT_FONT_DEFAULT_PATH } from '../../constants/DefaultValues';
import Tooltip from '../SharedComponents/Tooltip/Tooltip';
import FormInputGlobal from '../Form/InputGlobal';
import { useSharedData } from "../../context/SharedProvider";
import { WARNING_MESSAGES } from '../../constants/Messages';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

const TextProperties = ({ selectedItem, onSelectedItemUpdated }) => {
    const { t } = useTranslation(['common', 'editVisual']);
    const { getFontNames, downloadFont } = useSharedData();
    const selectedItemFontFamily = selectedItem.font_source.replace(TEXT_FONT_DEFAULT_PATH, "").replace(".ttf", "");
    const [fontsInVidatechFolder, setFontsInVidatechFolder] = useState([]);

    useEffect(() => {
        setFontsInVidatechFolder(getFontNames());
    }, []);


    async function handleValueChanged(e) {
        let value = e.target.value;
        let name = e.target.name;
        
        if (name === "x_value" || name === "y_value") {
            value = e.target.value.replace(/^0+/, "");
            value = e.target.value.length > 0 ? e.target.value : 0;
            value = parseInt(value);
        }

        if (name === "font-family") {
            value = TEXT_FONT_DEFAULT_PATH + value + ".ttf";
            selectedItem.font_source = value;
            try {
                const fontUrl = await downloadFont(value.replace(/\\/g, "/"));
                const fontName = e.target.value;
                selectedItem.fontFace = new FontFace(fontName, `url(${fontUrl})`);
                await selectedItem.fontFace.load();
                document.fonts.add(selectedItem.fontFace);
                selectedItem.errors = selectedItem.errors.filter(error => error !== t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_FONT_MISSING));
            } catch (error) {
                selectedItem.errors.push(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_FONT_MISSING));
            }
            onSelectedItemUpdated(selectedItem);
        } else {
            selectedItem[name] = value;
            onSelectedItemUpdated(selectedItem);
        }
    }

    return (
        <FormProvider initialValues={selectedItem} onSubmit={(e) => { }}>
            <div id="panel-content" className='full-panel-properties'>
                <div id="item-content">
                    <div className="panel-banner">
                        <h3>{t(TRANSLATION_KEYS.EDIT_VISUAL_PROPERTIES, { category: t(TRANSLATION_KEYS.EDIT_VISUAL_TEXT) }).toUpperCase()}</h3>
                    </div>
                    <div className="property-options">
                        <h4 className="property-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_CUSTOM_TEXT)}</h4>
                        <div className='property-group'>
                            <FormInputGlobal
                                type="text"
                                name="content"
                                validators={[]}
                                label={t(TRANSLATION_KEYS.EDIT_VISUAL_CONTENT_PLACEHOLDER)}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                        </div>
                        <div className="form-group-input">
                            <div className="property-group">
                                <label className='titreInput' htmlFor="font">{t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_FAMILY_LABEL)}</label>
                                <select id="font" value={selectedItemFontFamily} name='font-family' className='select-control' onChange={handleValueChanged}>
                                    {fontsInVidatechFolder.map((font, index) => (
                                        <option key={index} value={font}>{font}</option>
                                    ))}
                                </select>
                                {
                                    selectedItem.errors.includes(t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_FONT_MISSING)) &&
                                        <Tooltip text={t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_FONT_MISSING)} warning={true} />
                                }
                            </div>
                        </div>
                        <div className="property-group small-input">
                            <SelectGlobal
                                name={"font_size"}
                                label={t(TRANSLATION_KEYS.EDIT_VISUAL_FONT_SIZE_LABEL)}
                                value={`${selectedItem.font_size}`}
                                onChange={handleValueChanged}
                                options={["8", "10", "12", "16", "20", "24", "28", "36", "48", "72"]}
                            />
                        </div>
                        <div className="form-group-input color-property">
                        <div className="property-group">
                            <FormInputGlobal
                                name="color"
                                type="color"
                                onChange={handleValueChanged}
                                validators={[]}
                            />
                            <p>{selectedItem.color}</p>
                        </div>
                        </div>
                        <h4 className="property-title">{t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_POSITION)}</h4>
                        <div className="property-group">
                            <FormInputGlobal
                                type="text"
                                name="name"
                                validators={[]}
                                label={"Name"}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                        </div>
                        <Tooltip text={t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_POSITION_INFO)} >
                            <h5>{t(TRANSLATION_KEYS.EDIT_VISUAL_ZONE_POSITION_INFO)}</h5>
                        </Tooltip>
                        <div className="property-group small-input">
                            <FormInputGlobal
                                type="number"
                                name="x_value"
                                min={0}
                                max={1920}
                                validators={[]}
                                label={"X"}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                            <FormInputGlobal
                                type="number"
                                name="y_value"
                                validators={[]}
                                min={0}
                                max={1920}
                                label={"Y"}
                                onChange={handleValueChanged}
                                errors={[]}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </FormProvider>
    );
};

export default TextProperties;
