import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import FormInputGlobal from "../Form/InputGlobal";
import { FormContextGlobal } from "../Form/FormProvider";
import Button from "../Buttons/Button";
import FormSelectGlobal from "../Form/SelectGlobal";
import { RadioGlobal } from "../Form/RadioGlobal";
import {MAX_LENGTH, requiredValidator } from "../../constants/RequiredValidator";
import { SCREEN_ORIENTATION } from "../../constants/FormOptions";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";
import { Resolution } from "../../models";

const VisualForm = (props) => {
    const { t } = useTranslation(['common', 'visuals']);
    const { data, registerInput } = useContext(FormContextGlobal);
    const [validators] = useState({
        name: [requiredValidator],
        resolution: [requiredValidator],
    });

    const handleToggleValue = (event) => {
        const { name, value } = event.target;
        data[name] = value;      
    };

    const handleSubmit = (event) => {
        event.preventDefault();
    
        if (validateForm()) {
          props.onSubmit(data);
        }
      };

    const validateForm = () => {
        let errorsBeforeSubmit = [];
    
        for (const validator in validators) {
          errorsBeforeSubmit.push(registerInput(validator, validators[validator])(data[validator]));
        }
    
        let isValid = true;
        for (const field in errorsBeforeSubmit) {
          if (errorsBeforeSubmit[field].length > 0) {
            isValid = false;
            break;
          }
        }
    
        return isValid;
    };

    return (
        <form
            noValidate
            id="visual-form"
            onSubmit={(event) => handleSubmit(event)}
        >
            <FormInputGlobal
                type="text"
                name="name"
                autoFocus={true}
                maxLength={MAX_LENGTH.TEXT}
                placeholder={t(TRANSLATION_KEYS.VISUALS_NAME_PLACEHOLDER)}
                validators={validators.name}
                label={t(TRANSLATION_KEYS.VISUALS_NAME_LABEL)}
                data-testid="visual-name-input"
            />
            <FormSelectGlobal
                label={t(TRANSLATION_KEYS.VISUALS_SELECT_MODEL)}
                name="resolution"
                validators={validators.resolution}
                optionsWithValue={[
                    {
                        text: t(TRANSLATION_KEYS.VISUALS_SELECT_MODEL),
                        value: "",
                    },
                    {
                        text: t(TRANSLATION_KEYS.VISUALS_800x480),
                        value: Resolution.R_800X480,
                    },
                    {
                        text: t(TRANSLATION_KEYS.VISUALS_1024x600),
                        value: Resolution.R_1024X600,
                    },
                    {
                        text: t(TRANSLATION_KEYS.VISUALS_1280x800),
                        value: Resolution.R_1280X800,
                    },
                    {
                        text: t(TRANSLATION_KEYS.VISUALS_1024x768),
                        value: Resolution.R_1024X768,
                    },
                    {
                        text: t(TRANSLATION_KEYS.VISUALS_1920x1080),
                        value: Resolution.R_1920X1080,
                    }
                ]}
            />
            <RadioGlobal
                name="screenOrientation"
                label={t(TRANSLATION_KEYS.VISUALS_SCREEN_ORIENTATION)}
                value={data.screenOrientation}
                dataTestid="rotation-input"
                onChange={(event) => handleToggleValue(event)}
                options={[
                    {id: "portait", value: SCREEN_ORIENTATION.PORTRAIT, text: t(TRANSLATION_KEYS.VISUALS_PORTRAIT)},
                    {id: "landscape", value: SCREEN_ORIENTATION.LANDSCAPE, text: t(TRANSLATION_KEYS.VISUALS_LANDSCAPE)},
                ]}
            />

            <Button dataTestid="create-visual-button" text={t(TRANSLATION_KEYS.CREATE)} type="submit" />
        </form>
    );
};

export default VisualForm;
