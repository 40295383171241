import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import folder from "../../img/folder.svg";
import imagePreview from "../../img/image-preview.svg";
import widgetPreview from "../../img/widget-preview.svg";
import "./css/FileExplorerItem.css";
import Modal from "../Modal/Modal";
import actionWheel from "../../img/green-wheel.svg";
import { MAX_LENGTH } from "../../constants/DataValidation";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const FileExplorerItem = (props) => {
    const { t } = useTranslation(['common']);
    const { item, onClick, onDoubleClick, isSelected, onKeyDown } = props;
    const [actionsModalOpen, setActionsModalOpen] = useState(false);
    const [deleteModalOpen, setDeleteModalOpen] = useState(false);
    let itemIcon;

    if (item.type === "folder") {
        itemIcon = folder;
    } else if (item.type === "widget") {
        itemIcon = widgetPreview;
    }
    else if (item.type === "ttf") {
        itemIcon = require("../../img/font-file-preview.png");
    } else if (item.data) {
        itemIcon = item.data;
    } else {
        itemIcon = imagePreview;
    }

    useEffect(() => {
        if (!isSelected) {
            setActionsModalOpen(false);
        }
    }, [isSelected]);

    const handleItemClicked = (e) => {
        e.stopPropagation();
        onClick(item);
    }

    const handleItemDoubleClicked = () => {
        if (onDoubleClick) {
            onDoubleClick(item);
        }
    }

    const handleOptionClicked = (e) => {
        if (item.type === "folder" && item.content.length > 0) {
            setDeleteModalOpen(true);
            setActionsModalOpen(false);
        }
        else {
            props.onItemDeleted(item.path);
        }
    }

    const handleKeyDown = (e) => {
        if (e.key === "Delete" && !(item.path).startsWith("/library/vidatech/")) {
            if (item.type === "folder" && item.content.length > 0) {
                setDeleteModalOpen(true);
            }
            else {
                props.onItemDeleted(item.path);
            }
        }
        else {
            onKeyDown(e);
        }
        e.stopPropagation();
    }

    const handleActionButtonClicked = (e) => {
        e.stopPropagation();
        onClick(item);
        setActionsModalOpen(!actionsModalOpen);
    }

    return (
        <>
            <div data-testid={`file-explorer-item-${item.name}`} className={`icon${isSelected ? " selected" : ""}`}>
                <button
                    data-testid="file-explorer-icon-button"
                    className="icon-info"
                    draggable={true}
                    onClick={(e) => handleItemClicked(e)}
                    onKeyDown={handleKeyDown}
                    onDoubleClick={handleItemDoubleClicked}
                >
                    <div className="image-container">
                        <img draggable={false} src={itemIcon} alt="file-icon" />
                    </div>
                    <p className="file-name">
                        {item.name.length > MAX_LENGTH.FILE_EXPLORER_ITEM_NAME.ICON
                            ? item.name.substring(0, MAX_LENGTH.FILE_EXPLORER_ITEM_NAME.ICON - 3) + "..."
                            : item.name
                        }
                    
                    </p>
                </button>
                { item && !(item.path).startsWith("/library/vidatech/") &&
                    <>
                        <button data-testid="item-action-button" onClick={handleActionButtonClicked} className="item-action-button">
                            <img src={actionWheel} alt="action-wheel"/>
                        </button>
                        {actionsModalOpen &&
                            <div className="item-action-window">
                                <button data-testid="item-action-option-delete" className="item-action-option" onClick={handleOptionClicked}>
                                    <img src={require("../../img/picto-delete.png")} alt="delete-icon" />
                                    {t(TRANSLATION_KEYS.DELETE)}
                                </button>
                            </div>
                        }
                    </>

                }
            </div>
            <div className='confirm-modal'>
                <Modal
                    isOpen={deleteModalOpen}
                    onClose={() => setDeleteModalOpen(false)}
                    buttonText={t(TRANSLATION_KEYS.DELETE)}
                    buttonClick={() => { 
                        props.onItemDeleted(item.path);
                        setDeleteModalOpen(false);
                    }}
                >
                    <>
                        <img
                            src={require("../../img/icon-material-warning@1x.png")}
                            alt='confirm delete'
                        />
                        <p>{t(TRANSLATION_KEYS.LIBRARY_FOLDER_NOT_EMPTY)}</p>
                    </>
                </Modal>
            </div>
        </>
    );
};

export default FileExplorerItem;
