import React from "react";
import { FormProvider } from "../Form/FormProvider";
import "./css/UserForm.css";
import UserForm from "./UserForm";
import { COGNITO_GROUPS } from "../../constants/DefaultValues";

const UserFormProvider = ({item, onSubmit}) => {
  const initialValues = item
    ? {
      name: item.name || "",
      email: item.email || "",
      role: item.role || COGNITO_GROUPS.COLLABORATOR,
    }
    : {
      name: "",
      email: "",
      role: COGNITO_GROUPS.COLLABORATOR,
    };

  const handleSubmit = (data) => {
    if (!isEqualToInitialValue(data)) {
      onSubmit(data);
    }
    
  };

  const isEqualToInitialValue = (data) => {
    return JSON.stringify(initialValues) === JSON.stringify(data);
  };

  return (
    <FormProvider initialValues={initialValues} onSubmit={(event) => handleSubmit(event)}>
      <UserForm item={item} onSubmit={handleSubmit}/>
    </FormProvider>
  );
};

export default UserFormProvider;
