import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import deleteIcon from "../../img/delete-icon.svg";
import Tooltip from "../SharedComponents/Tooltip/Tooltip";
import { TOOLTIP_MESSAGES } from "../../constants/Messages";
import { CATEGORY_DISPLAY_NAMES, DISPLAY_ITEM_CATEGORY, DISPLAY_XML_ATTRIBUTE } from "../../constants/Fields";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";


const LeftPanelItem = ({
    items,
    onPanelItemClicked,
    selectedItem,
    selectedItemCategory,
    category,
    onItemDeleted,
    onCategoryDeleted
}) => {
    const { t } = useTranslation(['common', 'editVisual']);
    const [showItems, setShowItems] = useState(false);
    
    useEffect(() => {
        if (selectedItemCategory && category) {
            setShowItems(selectedItemCategory === category);
        }
    }, [selectedItemCategory]);

    function handleCategoryClicked(e) {
        setShowItems(!showItems);
    }

    function handleItemClicked(itemId) {
        const clickedItemCategory = category === DISPLAY_ITEM_CATEGORY.BACKGROUND ? DISPLAY_ITEM_CATEGORY.IMAGE : category;
        onPanelItemClicked(clickedItemCategory, itemId);
    }

    function handleDeleteCategory(e) {
        e.stopPropagation();
        onCategoryDeleted(category);
    }

    function handleDeleteItem(e, itemId) {
        e.stopPropagation();
        const deletedItemCategory = category === DISPLAY_ITEM_CATEGORY.BACKGROUND ? DISPLAY_ITEM_CATEGORY.IMAGE : category;
        onItemDeleted(deletedItemCategory, itemId);
    }

    return (
        items && (
            <li data-testid={`left-panel-item-${category}`} className="content-category" >
                <div onClick={handleCategoryClicked}>
                    <p className="category-name">{t(CATEGORY_DISPLAY_NAMES[category])}</p>
                    <div className="category-actions">
                        <button 
                            data-testid="category-details-button"
                            className="category-details-button"
                        >
                            {showItems ? "-" : "+"}
                        </button>
                        <div className="delete-icon-container">
                            <button
                                title={t(TRANSLATION_KEYS.DELETE)}
                                type="button"
                                onClick={handleDeleteCategory}
                            >
                                <img src={deleteIcon} alt={t(TRANSLATION_KEYS.DELETE)} />
                            </button>
                        </div>
                    </div>
                </div>      
                {showItems && (
                    <ul>
                        {items.map(item => (
                            <li
                                key={item[DISPLAY_XML_ATTRIBUTE.ID]}
                                className={
                                    `category-item ${
                                        selectedItem?.[DISPLAY_XML_ATTRIBUTE.ID] === item?.[DISPLAY_XML_ATTRIBUTE.ID]
                                            ? "selected-item"
                                            : ""}
                                        ${item?.unavailable || item?.errors?.length > 0
                                            ? "unavailable-item"
                                            : ""}`}
                                onClick={() => handleItemClicked(item[DISPLAY_XML_ATTRIBUTE.ID])}
                                data-testid={`left-panel-item-${category}-${item.name}`}
                            >
                                {
                                    item.unavailable
                                        ?   <Tooltip
                                                warning={true}
                                                text={category === DISPLAY_ITEM_CATEGORY.PLAYLIST
                                                        ? t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_PLAYLIST_IMAGE_MISSING)
                                                        : t(TRANSLATION_KEYS.EDIT_VISUAL_WARNINGS_ITEM_MISSING)}
                                            >
                                                <p>{item.name}</p>
                                            </Tooltip>
                                        : item.errors?.length > 0
                                            ?   <Tooltip
                                                    warning={true}
                                                    text={t(TRANSLATION_KEYS.EDIT_VISUAL_ITEM_ISSUES)}
                                                >
                                                <p>{item.name}</p>
                                            </Tooltip>
                                        : <p>{item.name}</p>
                                }
                                <div className="delete-icon-container">
                                    <button
                                        title={t(TRANSLATION_KEYS.DELETE)}
                                        type="button"
                                        onClick={(e) => handleDeleteItem(e, item[DISPLAY_XML_ATTRIBUTE.ID])}
                                    >
                                            <img src={deleteIcon} alt={t(TRANSLATION_KEYS.DELETE)}/>
                                    </button>
                                </div>
                            </li>
                        ))}
                    </ul>
                )}
                
            </li>
        )
    );
};

export default LeftPanelItem;
