import { TRANSLATION_KEYS } from "./TranslationKeys";

export const MAX_LENGTH = {
  TEXT: 100,
  POSTAL_CODE_CA: 7,
  POSTAL_CODE_US: 10,
  PHONE_NUMBER: 10,
  DEVICE_NAME: 32,
};

export const MIN_LENGTH = {
  DEVICE_NAME: 2,
  BUILDING_NAME: 2,
  SIP_ACCOUNT_NAME: 2,
};

export const MAX_VALUE = {
  CABIN_ID: 99,
  GATEWAY_ADDRESS: 15,
  RING_TIME: 999,
  DOOR_NUMBER: 1,
};

export const MIN_VALUE = {
  PHYSICAL_ADDRESS: 0,
  DOOR_NUMBER: 0,
  REFRESH_RATE: 60,
};

export const VALIDATOR_FORMATS = {
  EMAIL: {
    regex: /\S+@\S+\.\S+/,
    message: TRANSLATION_KEYS.VALIDATION_EMAIL,
  },
  NORTH_AMERICAN_PHONE: {
    regex:
      /^\([2-9][0-9]{2}\) [2-9](?!11)[0-9]{2}-\d{4}$|^[2-9][0-9]{2}[2-9](?!11)[0-9]{2}\d{4}$/,
    message: TRANSLATION_KEYS.VALIDATION_PHONE_FORMAT,
  },
  POSTAL_CODE_CA: {
    regex: /^[A-CEGHJ-NPRSTVXY]\d[A-CEGHJ-NPRSTV-Z] ?\d[A-CEGHJ-NPRSTV-Z]\d$/i,
    message: TRANSLATION_KEYS.VALIDATION_POSTAL_CODE_CA,
  },
  POSTAL_CODE_US: {
    regex: /^\d{5}(?:[-\s]\d{4})?$/,
    message: TRANSLATION_KEYS.VALIDATION_POSTAL_CODE_US,
  },
  TIME: {
    regex: /^(?:[0-9]|1[0-9]|2[0-3]|0[0-9])[:h]([0-5][0-9])$/,
    message: "* Invalid time format",
  },
  EXTENSION: {
    regex:
      /^[0-9]{1,10}$|^[2-9][0-9]{2}[2-9](?!11)[0-9]{2}\d{4}$|\([2-9][0-9]{2}\)[ ]?[2-9](?!11)[0-9]{2}-\d{4}$/,
    message: TRANSLATION_KEYS.VALIDATION_INVALID_FORMAT,
  },
  PBX_EXTENSION: {
    regex: /^[0-9]{1,10}$/,
    message: TRANSLATION_KEYS.VALIDATION_INVALID_FORMAT,
  },
  RING_TIME: {
    regex: /^[0-9]{1,3}$/,
    message: TRANSLATION_KEYS.VALIDATION_INVALID_FORMAT,
  },
  PLAYLIST_IMAGE_DURATION: {
    regex: /^(?!00:00$)[0-5]?[0-9]:[0-5][0-9]$/,
    message: "* Please use a valid format (max: 59:59 min: 00:01)",
  },
  PORT: {
    regex: /^([1-9]|[1-9][0-9]{1,3}|[1-5][0-9]{4}|6[0-4][0-9]{3}|65[0-4][0-9]{2}|655[0-2][0-9]|6553[0-5])$/,
    message: TRANSLATION_KEYS.VALIDATION_INVALID_FORMAT,
  },
  USER_PASSWORD: {
    regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>/?])(?!.*\s).{8,}$/,
    message: TRANSLATION_KEYS.VALIDATION_INVALID_FORMAT,
  },
};

/**
 * Validates if a value is required.
 * @param {any} val - The value to be validated.
 * @returns {string} - An error message if the value is empty, otherwise an empty string.
 */
export const requiredValidator = (val) =>
  val === undefined || val === null || val === ""
    ? TRANSLATION_KEYS.VALIDATION_REQUIRED
    : "";

/**
 * Validates if the value is a number.
 *
 * @param {any} val - The value to be validated.
 * @returns {string} - An error message if the value is not a number, otherwise an empty string.
 */
export const onlyNumbersValidator = (val) =>
  !val || !isNaN(val) ? "" : "* This field must be a number";

/**
 * Validates the maximum length of a value.
 * @param {string} val - The value to be validated.
 * @param {number} maxLength - The maximum length allowed.
 * @returns {string} - An error message if the value exceeds the maximum length, otherwise an empty string.
 */
export const maxLengthValidator = (val, maxLength, t) =>
  !val || val.length <= maxLength
    ? ""
    : t(TRANSLATION_KEYS.VALIDATION_MAX_LENGTH, { maxLength });
  
export const minLengthValidator = (val, minLength, t) =>
  !val || val.length >= minLength
    ? ""
    : t(TRANSLATION_KEYS.VALIDATION_MIN_LENGTH, { minLength });

/**
 * Validates the format of a field value using a regular expression.
 * @param {string} val - The value to be validated.
 * @param {object} formatValidator - The format validator object containing the regular expression and error message.
 * @returns {string} - An empty string if the value matches the regular expression, otherwise returns the error message.
 */
export const fieldFormatValidator = (val, 
  formatValidator) => {
  if (!formatValidator || !val) return "";

  const { regex, message } = formatValidator;
  if (!regex || !message) return "";

  return regex.test(val) ? "" : message;
};

export const rangeValidator = (val, min, max, t) => {
  if (!val) return "";
  return val >= min && val <= max
    ? ""
    : t(TRANSLATION_KEYS.VALIDATION_RANGE, { min, max });
};

export const minValueValidator = (val, min, t) => {
  if (!val) return "";
  return val >= min ? "" : t(TRANSLATION_KEYS.VALIDATION_MIN, { min });
};

export const passwordMatchValidator = (val, passwordToMatch, t) => {
  if (!val) return "";
  return val === passwordToMatch ? "": t(TRANSLATION_KEYS.VALIDATION_PASSWORD_CONFIRMATION)
}

export const passwordChangedValidator = (val, oldPassword, t) => {
  if (!val) return "";
  return val !== oldPassword ? "": t(TRANSLATION_KEYS.VALIDATION_PASSWORD_DIFFERENT)
}