import React, { useEffect, useState } from "react";
import "./css/ItemsTable.css";
import Pagination from "../SharedComponents/Pagination/Pagination";
import { FormProvider } from "../Form/FormProvider";
import { InputGlobal } from "../Form/InputGlobal";
import { useTranslation } from "react-i18next";

export default function ItemsTable(props) {
  const { t } = useTranslation("buildings");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = props.itemsPerPage || 10;
  const displayedItems = props.children.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );
  const [sortOrder, setSortOrder] = useState("asc");
  const [sortBy, setSortBy] = useState(
    props.headers && Object.keys(props.headers)[0]
  );

    // Reset currentPage when searchQuery changes
    useEffect(() => {
      setCurrentPage(1);
    }, [props.searchQuery]);

  const toggleSortBy = (header) => {
    let order = sortOrder === "asc" ? "desc" : "asc";
    if (sortBy !== header) {
      order = "asc";
    }
    props.onSortBy(header, order);
    setSortOrder(order);
    setSortBy(header);
  };

  const handleSelectAll = () => {
    props.onSelectAll(displayedItems.map(item => item.key));
  };

  const handlePageChanged = (pageNumber) => {
    setCurrentPage(pageNumber);
    if (props.onSelectAll) {
      props.onSelectAll([]);
    }
  }

  return (
    <div className="items-table-container">
      <table className="items-table">
        {props.headers && (
          <thead>
            <tr>
              {props.onSelectAll && (
                <th>
                  <div className="table-cell checkbox-cell">
                    <FormProvider
                      initialValues={{ isSelected: props.allSelected }}
                    >
                      <InputGlobal
                        className={props.allSelected ? "checked" : ""}
                        name={"isSelected"}
                        type="checkbox"
                        checked={true}
                        onChange={handleSelectAll}
                      />
                    </FormProvider>
                  </div>
                </th>
              )}
              {!Array.isArray(props.headers)
                ? Object.keys(props.headers).map((header) => {
                  const headerText = t(header);

                  return (
                    // last key does not sort
                    header === Object.keys(props.headers)[Object.keys(props.headers).length - 1]
                      && !props.lastItemSortable
                        ? (
                            <th key={header}>
                              <div className="table-cell">{headerText}</div>
                            </th>
                          )
                        : (
                          <th key={header}>
                            <div
                              className={`table-cell sort-by ${
                                sortBy === header ? `active ${sortOrder}` : ""
                              }`}
                              onClick={() => toggleSortBy(header)}
                            >
                              {headerText}
                            </div>
                          </th>
                        )
                  )
                }
                  )
                : props.headers?.map((header) => (
                    <th key={header} onClick={toggleSortBy}>
                      <div className="table-cell">
                        {header}
                        <span onClick={() => toggleSortBy(header)}>^</span>
                      </div>
                    </th>
                  ))}
            </tr>
          </thead>
        )}
        <tbody>{displayedItems}</tbody>
      </table>
      <Pagination
        currentPage={currentPage}
        totalCount={props.children.length}
        pageSize={itemsPerPage}
        onPageChange={handlePageChanged}
      />
    </div>
  );
}
