import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../constants/Loader";
import { API, graphqlOperation } from "aws-amplify";
import { useParams } from "react-router-dom";
import ItemsTable from "../Table/ItemsTable";
import LinkVisualMenuItem from "./LinkVisualMenuItem";
import { listVisualsStorm } from "../../graphql/queries";
import { StatusCodes } from "../../constants/StatusCodes";
import Button from "../Buttons/Button";
import "./css/LinkVisual.css";
import { TRANSLATION_KEYS } from "../../constants/TranslationKeys";

const LinkVisual = (props) => {
  const { t } = useTranslation();
  const deviceId = props.item
    ? props.item.id
    : props.items && props.items.length === 1
    ? props.items[0].id
    : null;
  const { buildingId: buildingId } = useParams();
  const [visuals, setVisuals] = useState([]);
  const [errorModalOpen, setErrorModalOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState("An error occured");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedVisual, setSelectedVisual] = useState(null);

  useEffect(() => {
    async function getVisuals() {
      try {
        setIsLoading(true);
        const values = await API.graphql(
          graphqlOperation(listVisualsStorm, {
            buildingID: buildingId,
          })
        );

        if (values.data.listVisualsStorm.statusCode !== StatusCodes.OK) {
          if (values.data.listVisualsStorm.body) {
            throw new Error(
              "Error getting visuals. " + values.data.listVisualsStorm.body
            );
          } else {
            throw new Error("Error getting visuals");
          }
        }
        const visualPaths = JSON.parse(values.data.listVisualsStorm.body);

        const visualModels = [];
        const resolutionPattern = /_R_(\d+x\d+)$/;
        for (const visual of visualPaths) {
          const visualName = visual.path.split("/", 1)[0];
          const match = visualName.match(resolutionPattern);
          if (
            !visualModels.some(
              (model) =>
                model.name === visualName.replace(resolutionPattern, "")
            )
          ) {
            visualModels.push({
              name: match
                ? visualName.replace(resolutionPattern, "")
                : visualName,
              resolution: match ? match[1] : "",
              // screenOrientation: 'Portrait',
              // positionIndicator: 'Inactive',
              // visualLinked: 'Inactive',
              // scheduleLinked: 'None'
            });
          }
        }
        if (props.item?.ThemeFolderName) {
          setSelectedVisual(
            visualModels.find((visual) =>
              visual.resolution
                ? `${visual.name}_R_${visual.resolution}` ===
                  props.item.ThemeFolderName
                : visual.name === props.item.ThemeFolderName
            )
          );
        }
        setVisuals(visualModels);
      } catch (e) {
        if (e.message) {
          setErrorMessage(e.message);
        }
        setErrorModalOpen(true);
      } finally {
        setIsLoading(false);
      }
    }
    getVisuals();
  }, []);

  function handleSelectVisual(visual) {
    if (selectedVisual === visual) {
      setSelectedVisual(null);
    } else {
      setSelectedVisual(visual);
    }
  }

  function handleLinkVisualButtonClicked() {
    const visualName = `${selectedVisual.name}${
      selectedVisual.resolution ? `_R_${selectedVisual.resolution}` : ""
    }`;
    if (deviceId) {
      props.onLinkVisual(visualName, deviceId);
    } else if (
      selectedVisual.resolution !== "" &&
      props.items.some(
        (item) =>
          item.ModelsResolution.substr(2) !== selectedVisual.resolution
      )
    ) {
      setErrorMessage(t(TRANSLATION_KEYS.PRODUCTS_ERRORS_RESOLUTIONS_DONT_MATCH));
      setErrorModalOpen(true);
    } else {
      props.onLinkVisual(visualName);
    }
  }

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : errorModalOpen ? (
        <div className="error-message">
          <>
            <img
              src={require("../../img/icon-material-warning@1x.png")}
              alt="warning sign"
            />
            <p>{errorMessage}</p>
          </>
        </div>
      ) : (
        <div id="link-visual-list">
          <ItemsTable itemsPerPage={5}>
            {visuals &&
              visuals.map((visual) => (
                <LinkVisualMenuItem
                  key={visual.name}
                  item={visual}
                  onSelectVisual={handleSelectVisual}
                  isSelected={selectedVisual === visual}
                />
              ))}
          </ItemsTable>
          <Button
            disabled={!selectedVisual}
            text={t(TRANSLATION_KEYS.PRODUCTS_LINK)}
            onClick={handleLinkVisualButtonClicked}
          />
        </div>
      )}
    </>
  );
};

export default LinkVisual;
