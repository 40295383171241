import React, { useState } from 'react';
import { useTranslation} from 'react-i18next';
import Modal from '../Modal/Modal';
import './css/AddPageForm.css';
import { TRANSLATION_KEYS } from '../../constants/TranslationKeys';

function AddPageForm({ onClick, onClose }) {
    const { t } = useTranslation(['common', 'editVisual']);
    const [pageName, setPageName] = useState("");

    function handleChange(e) {
        if (e.target.value.length <= 50)
        {
            setPageName(e.target.value);
        }
    }

    function handleButtonClicked() {
        onClick(pageName.trim());
    }

    function handleKeyDown(e) {
        if (e.key === 'Enter' && pageName.trim().length > 0) {
            handleButtonClicked();
        }
    }

    return (
        <div id="add-page-form">
            <Modal headerText={t(TRANSLATION_KEYS.EDIT_VISUAL_CREATE_PAGE)} isOpen={true} onClose={onClose} buttonDisabled={pageName.trim().length === 0} buttonText={t(TRANSLATION_KEYS.CREATE)} buttonClick={handleButtonClicked}>
                <div className="form-group-input">
                    <label
                        className="titreInput"
                        htmlFor="page-name"
                    >
                        {t(TRANSLATION_KEYS.EDIT_VISUAL_PAGE_NAME)}
                    </label>
                    <input
                        className="form-control"
                        type="text"
                        autoFocus={true}
                        id="page-name"
                        name="page-name"
                        value={pageName}
                        onChange={handleChange}
                        onKeyDown={handleKeyDown}
                    />
                </div>
            </Modal>
        </div>
    );
}

export default AddPageForm;
